import React, { useState } from 'react';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from '../../config/firebase';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./Modal.css"

// INICIA O BANCO DE DADOS DO FIREBASE
const db = getFirestore(app);

function ModalApp(props) {

    // VARIÁVEL DE AJUSTE DAS PROPRIEDADES DO MODAL
    var classeForm = ""
    if(props.tipo == "formEditOpcoes"){
        classeForm = "modalForm"
    }

    const handleClose = () => {
        if(props.tipo == "logOut"){ 
            props.logOut(true);
            props.change(false);
        }else if(props.tipo == "userUpdate"){
            const docRef = doc(db, 'users', props.userUid)
            const baseUsuario = getDoc(docRef).then((base)=>{;
                props.alteraUsuario(base.data())
            })
        }else{
            props.change(false);
        }
    }

    const modalClose = () => {
        if(props.tipo == "userUpdate"){
            const docRef = doc(db, 'users', props.userUid)
            const baseUsuario = getDoc(docRef).then((base)=>{;
                props.alteraUsuario(base.data())
            })
        }else{
            props.change(false);
        }
    }

    return (
        <>
        <Modal show={props.show} onHide={modalClose} className={classeForm}>
            <Modal.Header closeButton>
            <Modal.Title style={{color:"rgb(120,120,120)"}}>{props.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.corpo}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Entendi
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default ModalApp