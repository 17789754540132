import '../Cadastro/Cadastro.css';
import '../LogIn/login.css';
import '../../index.css';
import './Perfil.css'
import CampoForm from '../../componentes/CampoForm';
import validaItem from '../../componentes/CampoForm/validaForm.js';
import ModalApp from '../../componentes/Modal';
import PreLoader from '../../componentes/PreLoader';
import { useState, useRef, useEffect } from 'react';
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { app } from '../../config/firebase';
import { auth } from '../../config/firebase';
import { signOut } from 'firebase/auth';

// INICIA O BANCO DE DADOS DO FIREBASE
const db = getFirestore(app);

const Perfil = (props) => { 

    var baseUsuario = props.user;

    // HOOK DE ESTADO DAS VARIÁVEIS
    const [nome, setNome] = useState (baseUsuario.nome)
    const [sobrenome, setSobrenome] = useState (baseUsuario.sobrenome)
    const [cpf, setCpf] = useState (baseUsuario.cpf)
    const [nascimento, setNascimento] = useState (baseUsuario.nascimento)
    const [email, setEmail] = useState (baseUsuario.email)
    const [celular, setCelular] = useState (baseUsuario.celular)
    const [whatsapp, setWhatsapp] = useState (baseUsuario.whatsapp)
    const [cep, setCep] = useState (baseUsuario.cep)
    const [logradouro, setLogradouro] = useState (baseUsuario.logradouro)
    const [endComplemento, setEndComplemento] = useState (baseUsuario.enderecoComplemento)
    const [bairro, setBairro] = useState (baseUsuario.bairro)
    const [cidade, setCidade] = useState (baseUsuario.cidade)
    const [estado, setEstado] = useState (baseUsuario.estado)
    const [senha, setSenha] = useState (baseUsuario.senha)

    const [modalShow, setModalShow] = useState(false)
    const [modalTitulo, setModalTitulo] = useState("")
    const [modalCorpo, setModalCorpo] = useState("")
    const [modalTipo, setModalTipo] = useState("")
    const [logOut, setLogOut] = useState("")

    // FUNÇÃO UTILIZADA PARA CHAMADA DE MODAL
    function callModal(props){
        setModalTitulo(titulo);
        setModalCorpo(corpo);
        setModalTipo(props);
        setModalShow(true);
    }

    const [loading, setLoading] = useState(false);

    var corpo = ""
    var titulo = ""

    useEffect(() => {
    },[])

    // ADICIONA AO BOTÃO DO FORMULÁRIO AS FUNÇÕES DE VALIDAÇÃO E UPLOAD NO FIREBASE
    const submitCadastro = (evento) => {
        evento.preventDefault()
        setLoading(true);
        const form = evento.target.form
        var listaInputFormulario = form.querySelectorAll("input")
        var dadosCadastrais = {}

        // LOOP NOS CAMPOS DO FORMULÁRIO
        for (let contador = 0; contador < listaInputFormulario.length; contador++) {
            var input = listaInputFormulario[contador]

            // VALIDA OS CAMPOS E RETORNA DE ACORDO
            if (validaItem(input) == "required false"){
                titulo = "Formulário Incompleto!"
                corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> não foi preenchido, favor revisar</p>
                        <p>Os mesmos estão indicados por um (*)</p>
                    </div>
                setLoading(false);
                callModal("")
                return false

            // RETORNO DOS CASOS DE CAMPOS COM TIPOS DIFERENTES DO DEVIDO
            }else if(validaItem(input) == "type false"){
                titulo = "Valor incoerente!"
                corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> ossui um padrão de preenchimento que não foi obedecido, favor revisar</p>
                        <p>Ex.: E-mail que precisa conter @</p>
                    </div>
                setLoading(false);
                callModal("")
                return false

            }else{            
                // SE O CAMPO ESTIVER TOTALMENTE OK, É ENTÃO ADICIONADO NA BASE FINAL A SER CARREGADA NO BACKEND
                var campo = input.dataset.campo
                var valor = input.value
                dadosCadastrais[campo] = valor

            }

        }

        // SOBE O CADASTRO COMPLETO NO FIRESTORE 
        const docRef = doc(db, 'users', props.userUid)
        const cadastro = updateDoc(docRef, dadosCadastrais).then((cadastro) => {
            // MENSAGEM DE QUE O CADASTRO FOI EFETUADO COM SUCESSO
            titulo = "Seu cadatro foi atualizado com sucesso!"
            corpo = 
            <div className="">
                <p>Obrigado por nos ajudar a manter seus dados atualizados!</p>
            </div>
            setLoading(false);
            callModal("userUpdate")

        }).catch((error) => {
            // MENSAGEM DE ERRO
            const errorCode = error.code;
            const errorMessage = error.message;
            titulo = "Erro no processo de atualização"
            corpo = 
            <div className="">
                <p>Um erro foi indentificado</p>
                <p>{errorMessage}</p>
                <p>Caso não consiga solucioná-lo entre em contato com seu ponto focal pessoal.</p>
            </div>
            setLoading(false);
            callModal("")
        })

    }

    //ADICIONA A FUNÇÃO DE ABERTURA DO MODAL NO BOTÃO DE LOG-OUT
    const modalLogOut = () => {
        titulo = "Log-Out"
        corpo = 
        <div className="">
            <p>Tem certeza que deseja sair da sua conta?</p>
            <p>Caso sim, confirme no botão "Entendi"</p>
        </div>
        setLoading(false);
        callModal("logOut")
    }

    //FUNÇÃO DE LOG-OUT
    if(logOut===true){
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }

    // RETORNA O HTML DEVIDO DO FORMULÁRIO
    return(
        <div>
            {loading ? (<PreLoader/>) : (
            <main>
                <section className="cadastro container-md">
                    <ModalApp userUid={props.userUid} alteraUsuario={props.alteraUsuario} logOut={setLogOut} tipo ={modalTipo} show={modalShow} change={setModalShow} titulo={modalTitulo} corpo ={modalCorpo}/>
                    <div className="cadastro-bemvindo-login">
                        <p><strong>Olá {baseUsuario.nome}</strong></p>
                        <p>Abaixo encontram-se os seus dados cadastrais para revisão</p>
                    </div>
                    <form id="form-cadastro-profile" data-form="CadastroPerfil">
                        <h1><span className="material-symbols-outlined icon-title">person</span>Dados pessoais</h1>
                        <div className="row">
                            <CampoForm 
                                valor = {nome} 
                                aoAlterado = {valor => setNome(valor)} 
                                nomeCampo= "Nome *" 
                                campoId="cadastro-nome" 
                                tipo="text" 
                                dataCampo="nome" 
                                placeholder="Digite seu nome" 
                                dataMask="" 
                                required= {true}  
                                largura= "4"
                            />
                            <CampoForm
                                valor = {sobrenome} 
                                aoAlterado = {valor => setSobrenome(valor)}
                                nomeCampo= "Sobrenome *" 
                                campoId="cadastro-sobrenome" 
                                tipo="text" dataCampo="sobrenome" 
                                placeholder="Digite seu sobrenome" 
                                dataMask="" 
                                required= {true} 
                                largura= "8" 
                            />
                            <CampoForm
                                valor = {cpf}
                                aoAlterado = {valor => setCpf(valor)}
                                nomeCampo= "CPF *" 
                                campoId="cadastro-cpf" 
                                tipo="text" 
                                dataCampo="cpf" 
                                placeholder="999.999.999-99" 
                                dataMask="'mask': '999.999.999-99'" 
                                required= {true}  
                                largura= "6"
                            />
                            <CampoForm 
                                valor = {nascimento}
                                aoAlterado = {valor => setNascimento(valor)}
                                nomeCampo= "Data de Nascimento *" 
                                campoId="cadastro-nascimento" 
                                tipo="date" 
                                dataCampo="nascimento" 
                                placeholder="" 
                                dataMask="" 
                                required= {true}  
                                largura= "6"
                            />
                            <CampoForm 
                                valor = {celular}
                                aoAlterado = {valor => setCelular(valor)} 
                                nomeCampo= "Celular" 
                                campoId="cadastro-celular" 
                                tipo="text" 
                                dataCampo="celular" 
                                placeholder="(99) 9 9999-9999" 
                                dataMask="'mask': '(99) 9 9999-9999'" 
                                required= {false} 
                                largura= "6" />
                            <CampoForm 
                                valor = {whatsapp}
                                aoAlterado = {valor => setWhatsapp(valor)} 
                                nomeCampo= "Whatsapp" 
                                campoId="cadastro-wpp" 
                                tipo="text" 
                                dataCampo="whatsapp" 
                                placeholder="(99) 9 9999-9999" 
                                dataMask="'mask': '(99) 9 9999-9999'" 
                                required= {false} 
                                largura= "6" 
                            />
                        </div>
                        <h1><span className="material-symbols-outlined icon-title">home</span>Endereço</h1>
                        <div className="row">
                            <CampoForm 
                                valor = {cep}
                                aoAlterado = {valor => setCep(valor)} 
                                nomeCampo= "Cep *" 
                                campoId="cadastro-cep" 
                                tipo="text" 
                                dataCampo="cep" 
                                placeholder="99999999" 
                                dataMask="'mask': '99999999'" 
                                required= {true} 
                                largura="4" 
                            />
                            <CampoForm
                                valor = {logradouro}
                                aoAlterado = {valor => setLogradouro(valor)}  
                                nomeCampo= "Logradouro *" 
                                campoId="cadastro-logradouro" 
                                tipo="text" 
                                dataCampo="logradouro" 
                                placeholder="Rua exemplo" 
                                dataMask="" 
                                required= {true} 
                                largura="4"  
                            />
                            <CampoForm
                                valor = {endComplemento}
                                aoAlterado = {valor => setEndComplemento(valor)}  
                                nomeCampo= "Complemento" 
                                campoId="cadastro-complemento" 
                                tipo="text" 
                                dataCampo="enderecoComplemento" 
                                placeholder="Casa 1 apt 01"
                                dataMask="" 
                                required= {false} 
                                largura="4" 
                            />
                            <CampoForm 
                                valor = {bairro}
                                aoAlterado = {valor => setBairro(valor)}
                                nomeCampo= "Bairro *" 
                                campoId="cadastro-bairro" 
                                tipo="text" 
                                dataCampo="bairro" 
                                placeholder="Bairro"
                                dataMask="" 
                                required= {true} 
                                largura="6" 
                            />
                            <CampoForm 
                                valor = {cidade}
                                aoAlterado = {valor => setCidade(valor)}
                                nomeCampo= "Cidade *" 
                                campoId="cadastro-cidade" 
                                tipo="text" 
                                dataCampo="cidade" 
                                placeholder="Cidade" 
                                dataMask="" 
                                required= {true} 
                                largura="4" 
                            />
                            <CampoForm 
                                valor = {estado}
                                aoAlterado = {valor => setEstado(valor)}
                                nomeCampo= "Estado *" 
                                campoId="cadastro-estado" 
                                tipo="text" 
                                dataCampo="estado" 
                                placeholder="Estado" 
                                dataMask="" 
                                required= {true} 
                                largura="2" 
                            />
                        </div>
                        <div className="row">
                            <div className="col-12 btn-cadastro-box">
                                <button type="submit" className="btn btn-primary btn-cadastro" id="btn-cadastro" data-campo="submitForm" onClick={submitCadastro}>Atualizar</button>
                            </div>
                            <div className="col-12 btn-cadastro-box margin-top">
                                <div className="login-esqueceu-senha btn-cadastro">
                                    <p>Deseja Sair? <span onClick={modalLogOut} className="esqueceu-senha-clique">Log-Out</span></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
            )}
        </div>
    )
}

export default Perfil;