import CanvasJSReact from "../../canvasjs/canvasjs.react";
import { useEffect, useRef} from "react";
    
var CanvasJS = CanvasJSReact.CanvasJS;

const GraficoLimpo = (props) => {

    // VARIÁVEIS DE REFERÊNCIA PARA OS CANVAS
    const graficoRef = useRef()
    let observerRefValue = null
    let chart = null

    // RENDERIZA E ELINA O GRÁFICO NA MONTAGEM E DESMONTAGEM DO ELEMENTO
    useEffect(() => {
    
        // AO TERMINAR DE CARREGAR A PÁGINA
        if(graficoRef.current){
            // IMPRIME O PRIMEIRO GRÁFICO APÓS A PÁGINA ESTAR CARREGADA
            chart = new CanvasJS.Chart(graficoRef.current.id, props.options);
            chart.render();
            observerRefValue = chart
        }

        // ESCONDE LINKS DO CANVAS
        var canvaCreditList = document.querySelectorAll(".canvasjs-chart-credit")
    
        for(let i = 0; i < canvaCreditList.length; i++){

            const canvaCredit = canvaCreditList[i];
            canvaCredit.classList.add("hide")
        }

        // ANTES DO COMPONENTE SER DESMONTADO, LIMPA O GRÁFICO DE ACORDO
        return ()=>{
            if(observerRefValue){
                observerRefValue.destroy()
                //console.log("limpou após dismount:" + props.id)
            }
        }
    })

    return(
        <div id={props.id} ref={graficoRef}></div>
    )
}

export default GraficoLimpo