import {useEffect} from 'react';

const LinhaCarteira = (props) => {

    //FUNÇÃO PARA FORMATAÇÃO EM MOEDA
    let RSLocale = Intl.NumberFormat('pt-BR');

    const base = props.dados
    const idLegendaCirculo = "circulo_" + base.name
    const idLegendaTexto = "texto_" + base.name
    const idLegendaValor = "valor_" + base.name

    useEffect(() => {
        document.getElementById(idLegendaCirculo).style.backgroundColor = base.color;
        document.getElementById(idLegendaTexto).style.color = base.color;
        document.getElementById(idLegendaValor).style.color = base.color;
    })

    return(
        <div key={props.chave} className="linhaLegendaCarteira" >
            <div key={idLegendaCirculo} className="corLegendaCarteira" id={idLegendaCirculo}>{parseInt(base.percentual)}%</div>
            <div key={idLegendaTexto} className="tituloLegendaCarteira" id={idLegendaTexto}>{base.name}</div>
            <div key={idLegendaValor} className="valorLegendaCarteira" data-valor id={idLegendaValor}>{RSLocale.format(parseFloat(base.y).toFixed(0))}</div>
        </div>
    )
}

export default LinhaCarteira