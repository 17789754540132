import CanvasJSReact from "../../canvasjs/canvasjs.react"
import "../../paginas/Home/Home.css"
import "../../index.css"
import './CardAcoes.css' 
import {useEffect, useRef} from 'react';
import GraficoLimpo from "../GraficoLimpo";
import { convertDataToText, convertTextoToData } from "../../paginas/Admin/convertData";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CardAcoes = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"
    
    // VARIÁVEL TICKER
    const ticker = props.ticker

    useEffect(() => {

        // PARTE DO CÓDIGO QUE IRÁ RODAR TODA VEZ QUE A TELA FOR RENDERIZADA
        // ESCONDE LINKS DO CANVAS
        var canvaCreditList = document.querySelectorAll(".canvasjs-chart-credit")
    
        for(let i = 0; i < canvaCreditList.length; i++){

            const canvaCredit = canvaCreditList[i];
            canvaCredit.classList.add("hide")
        }

    })    

    const dadosGraficoHistoricoAcao = []
    const dadosGraficoPM = []
    
    // CAPTURA AS INFORMAÇÕES PERTINENTES DA AÇÃO
    let RSLocale = Intl.NumberFormat('pt-BR');
    const nomeAcao = props.acaoDetalhes.ticker
    const pmAcao = props.acaoDetalhes.pm.toFixed(2)
    const paAcao = "R$ " + props.acaoDetalhes.pa.toFixed(2)
    const ptAcao = "R$ " + props.acaoDetalhes.pt.toFixed(2)
    const qtdAcao = RSLocale.format(props.acaoDetalhes.unidades)
    const valorTotal = RSLocale.format(props.acaoDetalhes.valorTotal.toFixed(0))
    const variacao = RSLocale.format(props.acaoDetalhes.variacao.toFixed(0))
    const variacaoPercentual = (props.acaoDetalhes.variacaoPercentual*100).toFixed(1) + "%"
    const data = convertTextoToData(props.acaoDetalhes.dataAtualizacao)
    const dataAjustada = new Date( data.setMonth( data.getMonth()));
    const dataAtualizacao = convertDataToText(dataAjustada)
    const sharePatrimonio = (props.acaoDetalhes.percentualNaCarteira*100).toFixed(0)
    const sharePatrimonioComplementGraph = 100 - sharePatrimonio

    // LOOP DE CONSTRUÇÃO DAS CURVA HISTÓRICA DA AÇÃO
    for (let elementKey in props.dadosHistoricoAcao) {
        const element = props.dadosHistoricoAcao[elementKey];
        
        // CONVERTER O DADO X EM DATA ANTES DE INSERIR NO ARRAY
        // SPLIT O TEXTO
        const dataObj = elementKey.split("-")
        const ano = parseInt(dataObj[0]) 
        const mes = parseInt(dataObj[1]) // DATA FINAL DO MÊS ANTERIOR = 01 DO MÊS SEGUINTE

        // DATA DE FECHAMENTO DO MÊS ANTERIOR VIRA DATA DE ABERTURA DO MÊS SEGUINTE
        const data = new Date(ano,mes,1)
        const hoje = new Date();

        if(data <= hoje){

            // MONTA OS DADOS INDIVIDUAIS
            const inputHistoricoAcao = {x: data, y:element }
            const inputPMAcao = {x: data, y:parseFloat(pmAcao)} 

            // CONSTRÓI O GRÁFICO FINAL
            dadosGraficoHistoricoAcao.push(inputHistoricoAcao)
            dadosGraficoPM.push(inputPMAcao)

        }

    }

    // RANKEIA HISTORICAMENTE OS DADOS
    dadosGraficoHistoricoAcao.sort((a, b) => a.x - b.x);
    dadosGraficoPM.sort((a, b) => a.x - b.x);
    
    // ADICIONA O LABEL DE PREÇO MÉDIO NA CURVA DE PREÇO MÉDIO
    let i = 0
    for (let elementKey in dadosGraficoPM) {
        if(i === 1){
            dadosGraficoPM[elementKey].indexLabel = "PM: {y}";
            dadosGraficoPM[elementKey].indexLabelFontColor = "rgb(150,150,150)";
            dadosGraficoPM[elementKey].indexLabelFontWeight = "bold";
            
        }
        i = i+1
    }

    const dadosGraf = [
        { y: sharePatrimonio, label: "", color: "rgb(0,139,139)" },
        { y: sharePatrimonioComplementGraph, label: "", color: "rgb(235,235,235)" }
    ]

    const optionsCarteira = {
        backgroundColor: "transparent",
        animationEnabled: true,
        height:150,
        width:150,
		title:{
			text: "",
			horizontalAlign: "left",
            fontFamily:fonte
		},
        subtitles: [{
            text: sharePatrimonio+"%",
            verticalAlign: "center",
            horizontalAlign: "center",
            fontColor:"gray",
            fontFamily:fonte,
            fontColor: "rgb(0,139,139)",
            fontSize: 30,
            dockInsidePlotArea: true
        }],
	    toolTip:{
	        enabled: false,
            shared: false,
            fontFamily: fonte
	      },
		data: [{
			type: "doughnut",
			startAngle: 60,
			radius: "90%",
			innerRadius: "90%",
			indexLabelFontSize: 0,
			indexLabelMinWidth: 0,
			indexLabelWrap: true, 
            indexLabelFontFamiy:fonte,
			indexLabel: "",
			toolTipContent: "",
			indexLabelFontColor: "rgb(100,100,100)",
			dataPoints: dadosGraf
		}]
    }

    const optionsEvolucaoAcao = {
        animationEnabled: true,
		backgroundColor: "transparent",
        height:230,
        toolTip:{
            enabled:true,
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
                content += "<tr/>";
                content += "<th style='padding-right:10px'><span style = 'color: rgba(102,205,170,.7)'>  Ação: </span></th> " + "<th><span style='color:gray'> R$" + RSLocale.format(parseFloat(e.entries[0].dataPoint.y)) + "</span></th>";
                content += "</table>"
				return content;
            }
        },
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign: "top",
            fontSize:0,
            fontFamily:fonte
		},
		title:{
			text: "",
			padding:0,
			fontSize:0,
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.000000001,
			lineThickness:0,
			tickThickness: 0,
            titleMaxWidth:0,
            margin: 0,
            labelFontFamily:fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 0,
			tickThickness: 0,
            labelFontSize:0.00000000001,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,0.0)",
			lintThickness: 0.0000001,
            labelFontFamily: fonte

		},
		data: [{
			type: "line",
            name: "Preços",
            indexLabelFontSize: 0,
            indexLabelFontFamily: fonte,
			legendText: "Preço",
			color: "rgba(102,205,170,.7)",
			markerSize: 0,
			dataPoints: dadosGraficoHistoricoAcao
		},{
            type: "line",
            name: "Preço Médio",
			legendText: "Preço Médio",
			color: "rgb(150,150,150)",
            indexLabelFontFamily: fonte,
			markerSize: 0,
            lineThickness: 1,
			dataPoints: dadosGraficoPM
        }]
    }

    if (props.modulo === "info"){
        return(
            <div className="cardAcaoBox box-shadow">
                <div className="cardAcaoTitulo">
                    <div className="nomeAcao">{nomeAcao}</div>
                    <div data-valor className="patrimonioAcao">R$ {valorTotal}</div>
                </div>
                <div className="cardAcaoCorpo">
                    <div className="graficoDistAcao">
                        <GraficoLimpo id={"graf-" + "acao-" + ticker} options = {optionsCarteira}/>
                    </div>
                    <div className="detalhesAcao">
                        <div className="detalhesAcaoLinha">
                            <div className="tituloDetalhesAcaoLinha">Preço Médio</div>
                            <div className="valorDetalhesAcaoLinha">{"R$ " + pmAcao}</div>
                        </div>
                        <div className="detalhesAcaoLinha">
                            <div className="tituloDetalhesAcaoLinha">Preço Atual</div>
                            <div className="valorDetalhesAcaoLinha">{paAcao}</div>
                        </div>
                        <div className="detalhesAcaoLinha">
                            <div className="tituloDetalhesAcaoLinha">Preço Target</div>
                            <div className="valorDetalhesAcaoLinha">{ptAcao}</div>
                        </div>
                        <div className="detalhesAcaoLinha">
                            <div className="tituloDetalhesAcaoLinha">Unidades</div>
                            <div data-valor className="valorDetalhesAcaoLinha">{qtdAcao}</div>
                        </div>
                    </div>
                </div>
                <div className="variacaoAcao">
                    <div><span data-valor>{variacao} |</span><span className="variacaoPerncetual"> {variacaoPercentual}</span></div>
                </div>
                <div className="dataAtualizacaoAcao">
                    <div>Data atualização: {dataAtualizacao}</div>
                </div>
            </div>
        )
    }else if(props.modulo === "graf"){
        return(
            <div className="cardAcaoBox box-shadow">
                <div className="cardAcaoTitulo">
                    <div className="nomeAcao">{nomeAcao}</div>
                    <div data-valor className="patrimonioAcao">R$ {valorTotal}</div>
                </div>
                <div className="cardAcaoCorpoGraf">
                    <div id="grafico-carteira" className="grafico-margin">
                        <GraficoLimpo id={"graf-" + "detalheAcao-" + ticker} options = {optionsEvolucaoAcao}/>
                    </div>
                </div>
            </div>
        )
    }

}

export default CardAcoes