import CanvasJSReact from "../../canvasjs/canvasjs.react";
import { useEffect, useState} from "react";
import './Drilldown.css';
import { convertDataToText } from "../../paginas/Admin/convertData";
    
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

const ShareCarteiraDrillDown = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // VARIÁVEL DO PATRIMÔNIO TOTAL
    const tituloAtivos = props.patrimonio

    // VARIÁVEL DE IMPRESSÃO DO GRÁFICO CANVAS.JS
    var canvas = null
    let observerRefValue = null

    useEffect(() => {

        // OCULTA O BOTÃO DE VOLTAR
        document.getElementById("backButton").classList.add("invisible");

        // IMPRIME O PRIMEIRO GRÁFICO APÓS A PÁGINA ESTAR CARREGADA
        chart = new CanvasJS.Chart("chartContainer", optionsCarteira);
        chart.options.data = CarteiraData["Composição Carteira"];
        observerRefValue = chart
        chart.render();

        // ANTES DO COMPONENTE SER DESMONTADO, LIMPA O GRÁFICO DE ACORDO
        return ()=>{
            if(observerRefValue){
                observerRefValue.destroy()
                //console.log("limpou após dismount: gráfico-carteira")
            }
        }

    })

    // DECLARAÇÃO DE VARIÁVEIS
    const dadosShareCarteiraBase = []
    var dadosShareCarteira = null
    var chart = null
    const dadosDrillDownRF = [];
    const dadosDrillDownAcao = [];
    const dadosDrillDownConta = [];
    const dadosDrillDownFII = [];
    const dadosDrillDownProventos = [];
    const dadosDrillDownLF = [];

    // SUBSTITUIR POR LOOP QUE CRIARÁ UM ARRAY NOVO SEM CONTAR O ÚTLIMO ELEMENTO
    for (let i = 0; i < props.dados.length; i++) {
        if(i !== props.dados.length-1){
            dadosShareCarteiraBase.push(props.dados[i])
        }else{
            dadosShareCarteira = props.dados[i]
        }
    }

    // FUNÇÃO DE DRILLDOWN DO CANVAS JS
    function CarteiraChartDrilldownHandler(e) {

        // AJUSTA AS OPÇÕES DO GRÁFICO SEM CRIAR UM NOVO CANVAS
        chart.options = CarteiraDrilldownedChartOptions
        chart.options.data = CarteiraData[e.dataPoint.name];
        chart.render();
        document.getElementById("backButton").classList.remove("invisible");

        // ESCONDE LINKS DO CANVAS
        var canvaCreditList = document.querySelectorAll(".canvasjs-chart-credit")

        for(let i = 0; i < canvaCreditList.length; i++){

            const canvaCredit = canvaCreditList[i];
            canvaCredit.classList.add("hide")
        }
        
    }

    // APLICA AO BOTÃO DE VOLTA A REIMPRESSÃO DO GRÁFICO
    function backDrillDown(e) { 
        document.getElementById("backButton").classList.add("invisible");

        // AJUSTA AS OPÇÕES DO GRÁFICO SEM CRIAR UM NOVO CANVAS
        chart.options = optionsCarteira
        chart.options.data = CarteiraData["Composição Carteira"];
        chart.render();

        // ESCONDE LINKS DO CANVAS
        var canvaCreditList = document.querySelectorAll(".canvasjs-chart-credit")
    
        for(let i = 0; i < canvaCreditList.length; i++){

            const canvaCredit = canvaCreditList[i];
            canvaCredit.classList.add("hide")
        }
    };

    // LOOP NOS DADOS BASE PARA CONSTRUÇÃO DAS CURVAS DO DRILLDOWN  DA CARTEIRA
    for (let i = 0; i < dadosShareCarteiraBase.length; i++) {

        var somaTotal = dadosShareCarteiraBase[i][2]["y"] + dadosShareCarteiraBase[i][4]["y"] + dadosShareCarteiraBase[i][3]["y"] + dadosShareCarteiraBase[i][5]["y"] + dadosShareCarteiraBase[i][6]["y"] + dadosShareCarteiraBase[i][7]["y"]

        // ADICIONA OS DADOS DEVIDOS PARA CRIAR OS GRÁFICOS INDIVIDUAIS DA CARTEIRA NO TEMPO
        dadosDrillDownAcao.push({
            y:parseInt(dadosShareCarteiraBase[i][2]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][2]["color"]
        });
        dadosDrillDownRF.push({
            y:parseInt(dadosShareCarteiraBase[i][4]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][4]["color"]
        });
        dadosDrillDownConta.push({
            y:parseInt(dadosShareCarteiraBase[i][3]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][3]["color"]
        });
        dadosDrillDownFII.push({
            y:parseInt(dadosShareCarteiraBase[i][5]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][5]["color"]
        });
        dadosDrillDownProventos.push({
            y:parseInt(dadosShareCarteiraBase[i][6]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][6]["color"]
        });
        dadosDrillDownLF.push({
            y:parseInt(dadosShareCarteiraBase[i][7]["y"]/somaTotal*100),
            x:dadosShareCarteiraBase[i][0],
            color:dadosShareCarteiraBase[i][7]["color"]
        })
    } 
    
    // VARIÁVEL PARA IMPRESSÃO DO GRÁFICO DE DRILLDOWN DO CANVAS
    const CarteiraData = {
        "Composição Carteira": [{
            click: CarteiraChartDrilldownHandler,
            cursor: "pointer",
            explodeOnClick: false,
            name: "Composição Carteira",
            type: "doughnut",
			startAngle: 60,
			radius: "100%",
			innerRadius: "85%",
			indexLabelFontSize: 0,
			indexLabelMinWidth: 0,
			indexLabelWrap: true, 
			indexLabel: "",
			toolTipContent: "{name}: {percentual}%",
			indexLabelFontColor: "rgb(100,100,100)",
            indexFontFamily: fonte,
            dataPoints: dadosShareCarteira
        }],
        "Ação": [{
            name: "Ação",
            type: "area",
            toolTipContent: "{name}: {y}%",
            lineThickness:1,
            markerSize:5,
            color: dadosDrillDownAcao[0]["color"],
            dataPoints: dadosDrillDownAcao
        }],
        "Renda Fixa": [{
            name: "Renda Fixa",
            type: "area",
            lineThickness:1,
            toolTipContent: "{name}: {y}%",
            markerSize:5,
            color: dadosDrillDownRF[0]["color"],
            dataPoints: dadosDrillDownRF
        }],
        "FII": [{
            name: "FII",
            type: "area",
            lineThickness:1,
            toolTipContent: "{name}: {y}%",
            markerSize:5,
            color: dadosDrillDownFII[0]["color"],
            dataPoints: dadosDrillDownFII
        }],
        "Conta": [{
            name: "Conta",
            type: "area",
            lineThickness:1,
            toolTipContent: "{name}: {y}%",
            markerSize:5,
            color: dadosDrillDownConta[0]["color"],
            dataPoints: dadosDrillDownConta
        }],
        "Proventos": [{
            name: "Proventos",
            type: "area",
            lineThickness:1,
            toolTipContent: "{name}: {y}%",
            markerSize:5,
            color: dadosDrillDownProventos[0]["color"],
            dataPoints: dadosDrillDownProventos
        }],
        "Lanç. Futuros": [{
            name: "Lançamentos Futuros",
            type: "area",
            lineThickness:1,
            toolTipContent: "{name}: {y}%",
            markerSize:5,
            color: dadosDrillDownLF[0]["color"],
            dataPoints: dadosDrillDownLF
        }]

    };

    const optionsCarteira = {
        backgroundColor: "transparent",
        animationEnabled: true,
        legend:{
            fontSize:0
        },
        subtitles: [{
            text: props.patrimonio,
            verticalAlign: "center",
            fontColor:"gray",
            fontFamily:fonte,
            fontSize: 1.5*props.fontSize,
            dockInsidePlotArea: true
        }],
		title:{
			text: "",
			horizontalAlign: "left",
            fontFamily: ""
		}
    }

    const CarteiraDrilldownedChartOptions = {
        animationEnabled: true,
        theme: "light2",
        type:"line",
        title:{
            fontSize:0
        },
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte
        },
        axisX: {
            labelFontColor: "gray",
            lineColor: "gray",
            tickColor: "rgba(0,0,0,0)",
            labelFontFamily: fonte,
            intervalType: "month",
        },
        axisY: {
            gridThickness: 0,
            includeZero: false,
            labelFontColor: "#717171",
            labelFontSize:0.000001,
            minimum:0,
            maximum:100,
            margin:0,
            lineColor: "#a2a2a2",
            tickColor: "#a2a2a2",
            lineThickness: 0,
            tickColor: "rgba(0,0,0,0)"
        },
        data: CarteiraData
    };  

    return(
        <div className="carteiraDrillDown">
            <div className="drillDownTitle">
                <div className="tituloGrafDrillDown">Clique no gráfico para ver detalhes</div>
                <div className="btnVoltaDrilldown invisible" id="backButton" onClick={backDrillDown}>
                    <span className="material-symbols-outlined">arrow_back_ios</span>
                    Back
                </div>
            </div>
            <div id="chartContainer"></div>
        </div>
    )

}

export default ShareCarteiraDrillDown