import styles from './LinhaClientes.module.css'
import { useState, useEffect, useRef } from 'react';
import ModalApp from '../Modal';
import DetalheNotional from '../GRiscoNotionalDetalhe';
import { convertTextoToData } from '../../paginas/Admin/convertData';

// LINHA DO CLIENTE NA TABELA DE CONTROLE DE RISCO
const LinhaClienteAdmin = (props) => {

    // VARIÁVEL DE ESTADO
    const[input, setInput] = useState([])

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:"formEditOpcoes"
    })

    var titulo = "Título aqui"
    var corpo = "Aqui estará o formulário de edição"

    // TODA VEZ QUE ALTERA AS INFOS DE INPUT REIMPRIME NA TELA
    /*useEffect(()=>{

        // AJUSTA A VISUALIZAÇÃO DOS VALORES PARA UMA IMPRESSÃO CORRETA
        var inputAjustado = JSON.parse(JSON.stringify(props.input));

        let RSLocale = Intl.NumberFormat('pt-BR');

        inputAjustado.deltaBetaPercentual = (inputAjustado.deltaBetaPercentual*100).toFixed(2)
        inputAjustado.deltaBetaValor = RSLocale.format(parseFloat(inputAjustado.deltaBetaValor).toFixed(0))
        inputAjustado.riscoGama = (inputAjustado.riscoGama*100).toFixed(2)
        inputAjustado.valorEmTravaPercentual = (inputAjustado.valorEmTravaPercentual*100).toFixed(1)
        inputAjustado.tetaValor = RSLocale.format(parseFloat(inputAjustado.tetaValor).toFixed(0)*(-1))
        inputAjustado.premiosPercentual = (inputAjustado.premiosPercentual*100).toFixed(1)
        inputAjustado.premios = RSLocale.format(parseInt(inputAjustado.premios))
        inputAjustado.notionalUsadoPercentual = (inputAjustado.notionalUsadoPercentual*100).toFixed(0)
        inputAjustado.notionalTotal = RSLocale.format(parseFloat(inputAjustado.notionalTotal).toFixed(0))
        inputAjustado.notionalUsado = RSLocale.format(parseFloat(inputAjustado.notionalUsado).toFixed(0))
        inputAjustado.margemPercentual = (inputAjustado.margemPercentual*100).toFixed(0)
        inputAjustado.rangeInferior = RSLocale.format(parseFloat(inputAjustado.rangeInferior).toFixed(0))
        inputAjustado.rangeSuperior = RSLocale.format(parseFloat(inputAjustado.rangeSuperior).toFixed(0))

        // ATUALIZA A VARIÁVEL DE ESTADO
        setInput(inputAjustado)
    },[input])*/    

    // TODA VEZ QUE A BASE MUDAR
    useEffect(()=>{

        // VARIÁVEL DE ARRAY
        var arrayTratado = []

        // VARIÁVEIS ACUMULADAS
        var premioPercentualAcumulado = 0
        var premioAcumulado = 0
        var notionalAcumulado = 0

        if(props.base != [] && props.base != undefined){
            // CAPTURA A CHAVE E NOME DO CLIENTE
            var chave = props.base.chave
            var nomeCliente = ""

            // TRATA A BASE TRANSFORMANDO A CHAVE DATA EM UM CAMPO DATA
            for (const data in props.base){
                if(data!="chave"){
                    var inputObject = props.base[data]
                    inputObject.data = data
                    arrayTratado.push(inputObject)

                    var nomeArray = props.base[data]["Cliente"].split(" ")
                    var sobreNomeArray = nomeArray[nomeArray.length - 1].split('')
                    nomeCliente = nomeArray[0] + " " +sobreNomeArray[0] +"."
                }
            }

            // RANKEIA A DATA DA MAIS ANTIGA PRA MAIS NOVA
            arrayTratado.sort((a, b) => convertTextoToData(a.data) - convertTextoToData(b.data));

            // VARIÁVEIS DE INÍCIO E FIM DE CONTRATO
            var inicioContrato =  arrayTratado[arrayTratado.length-1]["Inicio Contrato"]
            var fimContrato = arrayTratado[arrayTratado.length-1]["Fim de Contrato"]

            // VARIÁVEL DE MODELO DE CONTRATO
            var modeloContrato = arrayTratado[arrayTratado.length-1]["Modelo de Contrato"]

            // VERIFICA SE A BASE FOI FILTRADA
            // FAZ DIFERENÇA POIS NOS CASOS EM QUE A BASE NÃO FOI FILTRADA O PRIMEIRO ITEM PRECISA SER IGNORADO
            // O PRIMEIRO ITEM DA BASE NÃO FILTRADA É SÓ UMA REFERÊNCIAD E PATRIMÔNIO
            var baseFiltrada = false

            // VARIÁVEL PARA CÁLCULO DA TIR
            var arrayTir = []

            // CONSTRÓI AS BASES NO TEMPO PARA DRILLDOWN DO GRÁFICO DE CONCILIAÇÃO DE PATRIMÔNIO
            var comissoesAcumuladasAnterior = 0
            var comissoesAcumuladas = 0
            var comissoesMes = 0

            // LOOP EM TODAS AS DATAS PARA CÁLCULO DAS RENTABILIDADES NA MARCAÇÃO A MERCADO
            for(let i=0; i<arrayTratado.length;i++){

                if(i===0){

                    // ADICIONA O PATRIMÔNIO INICIAL NA CURVA DA TIR
                    var patrInicial = arrayTratado[i]["Saldo Visivel na XP"]
                    arrayTir.push(patrInicial)

                }else if(i===arrayTratado.length-1){

                    // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
                    comissoesAcumuladasAnterior = arrayTratado[i-1]["Comissoes"]
                    comissoesAcumuladas = arrayTratado[i]["Comissoes"]
                    comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior

                    // CASO SEJA UMA BASE DE SOMENTE UM MÊS O ÚLTIMO DADO SERÁ TAMBÉM O PRIMEIRO
                    // CASO SEJA O ÚLTIMO VALOR DA BASE, FAZ UM TRATAMENTO DIFERENCIADO PARA CONSTRUÇÃO DO ARRAY DA TIR
                    var ultimoValorTir = arrayTratado[i]["Saldo Visivel na XP"]*(-1) + arrayTratado[i]["Entrada e Saida Total"] - comissoesMes
                    arrayTir.push(ultimoValorTir)

                }else{

                    // ATUALIZA OS VALORES PARA CÁLCULO DAS COMISSÕES MÊS A MÊS
                    comissoesAcumuladasAnterior = arrayTratado[i-1]["Comissoes"]
                    comissoesAcumuladas = arrayTratado[i]["Comissoes"]
                    comissoesMes = comissoesAcumuladas - comissoesAcumuladasAnterior

                    // POPULA A BASE PARA CÁLCULO DO TIR
                    arrayTir.push(arrayTratado[i]["Entrada e Saida Total"] - comissoesMes) 

                }

            }

            // VARIÁVEL PARA CÁLCULO DA TIR
            const { irr } = require('node-irr')
            var periodosCarteira = arrayTratado.length-1
            var rendMensal = (irr(arrayTir)*100)
            var rendAcumulado = ((((1+rendMensal/100) ** (periodosCarteira))-1)*100).toFixed(1)
            var sharpe = 0
            var acumuladoCDI = 1
            var rendimentoWarning = "itemOk"
            rendMensal = rendMensal.toFixed(1)

            // CALCULA A RENTABILIDADE ACUMULADA DO CDI
            for (let j = 1; j < arrayTratado.length; j++) {
                acumuladoCDI = acumuladoCDI * (1+arrayTratado[j]["CDI (%)"])
            }

            acumuladoCDI = ((acumuladoCDI - 1)*100).toFixed(1)

            // WARNING
            if((parseFloat(rendAcumulado) < 0.8*parseFloat(acumuladoCDI)) && (modeloContrato != "Probono")){
                rendimentoWarning = "itemBad"
            }else if((parseFloat(rendAcumulado) < parseFloat(acumuladoCDI)) && (modeloContrato != "Probono")){
                rendimentoWarning = "itemWarning"
            }

            // LOOP EM TODAS AS DATAS PARA CÁLCULO DOS PRÊMIOS
            for(let i=0; i<arrayTratado.length;i++){

                // VERIFICA SE A DATA ESTÁ NO RANGE DO CONTRATO
                if(convertTextoToData(arrayTratado[i]["data"]) < convertTextoToData(inicioContrato) || convertTextoToData(arrayTratado[i]["data"]) > convertTextoToData(fimContrato)){
                    arrayTratado.splice(i, 1)
                    i = i-1
                    baseFiltrada = true
                }else{
                    // O PRIMEIRO PONTO É SÓ UMA REFERÊNCIA DE INÍCIO, NÃO DEVE SER CONTADO
                    if(baseFiltrada===false && i===0){}else{
                        // ARRAY DE PREMIO PERCENTUAL MÊS A MÊS
                        premioAcumulado = premioAcumulado + arrayTratado[i]["Resultado das Opcoes"]
                        notionalAcumulado = notionalAcumulado + arrayTratado[i]["Notional (R$)"]
                        //premioPercentualAcumulado = premioPercentualAcumulado + arrayTratado[i]["Resultado das Opcoes"]/arrayTratado[i]["Notional (R$)"]
                        premioPercentualAcumulado = premioAcumulado / notionalAcumulado

                        // PREMIO PERCENTUAL MÉDIO
                        if(baseFiltrada === false){
                            arrayTratado[i]["premioPercentualMedio"] = premioPercentualAcumulado
                        }else{
                            arrayTratado[i]["premioPercentualMedio"] = premioPercentualAcumulado
                        }
                    }
                }
            }

            // CALCULA OS PREMIOS MÉDIOS N-3, N-2, N-1
            var premiosN1 = 0
            var premiosN2 = 0
            var premiosN3 = 0

            if(arrayTratado.length-1>=0){
                premiosN1 = (arrayTratado[arrayTratado.length-1]["premioPercentualMedio"]*100).toFixed(1)
            }else{
                premiosN1 = ""
            }

            if(arrayTratado.length-2>=0){
                premiosN2 = (arrayTratado[arrayTratado.length-2]["premioPercentualMedio"]*100).toFixed(1)
            }else{
                premiosN2 = ""
            }

            if(arrayTratado.length-3>=0){
                premiosN3 = (arrayTratado[arrayTratado.length-3]["premioPercentualMedio"]*100).toFixed(1)
            }else{
                premiosN3 = ""
            }

            // CALCULA OS PREMIOS WARNING
            var premiosWarning = "itemOk"

            if(parseFloat(premiosN1) < 3 && parseFloat(premiosN2) < 3 && parseFloat(premiosN3) < 3 && (modeloContrato == "Antigo")){
                premiosWarning = "itemBad"
            }else if(parseFloat(premiosN1) < 3 && parseFloat(premiosN2) < 3 && (modeloContrato == "Antigo")){
                premiosWarning = "itemWarning"
            }else if(parseFloat(premiosN1) < 3 && (modeloContrato == "Antigo")){
                premiosWarning = "itemWarning"
            }

            // CALCULA O PERÍODO EM CONTRATO
            var dataFormatoData = convertTextoToData(arrayTratado[arrayTratado.length-1]["data"])
            var dataInicioDeContrato = convertTextoToData(arrayTratado[arrayTratado.length-1]["Inicio Contrato"])
            var periodoAtivo = ((dataFormatoData - dataInicioDeContrato)/2629800000).toFixed(0)

            // ALTERA O INPUT
            var inputObj = {
                nome: nomeCliente,
                chave: chave,
                premiosN1: premiosN1,
                premiosN2: premiosN2,
                premiosN3: premiosN3,
                periodoAtivo: periodoAtivo,
                premiosWarning: premiosWarning,
                rendMensal: rendMensal,
                rendAcumulado: rendAcumulado,
                periodosCarteira:periodosCarteira,
                acumuladoCDI:acumuladoCDI,
                rendimentoWarning: rendimentoWarning
            }

            // ALTERA A VARIÁVEI DE ESTADO
            setInput(inputObj)

        }

    },[props.base])

    // CAPTURA A COR DA LINHA
    var lineColor = "line" + props.lineColor

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){

        // ABRE OU FECHA O MODAL
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE CHAMADA DE MODAL PARA AJUSTE MANUAL E RECÁLCULO
    function openModalDetalhe(){
        titulo = "Notional - " + props.input.nome
        corpo = <DetalheNotional base={props.baseKPIDetalhe} input={props.input}/>
        callModal("formEditOpcoes")
    }

    // SÓ RETORNA ALGO SE O INPUT NÃO FOR NULO
    if(input != null){
        return(
            <div className={`${styles.linhaCliente} ${styles[lineColor]} row`}>
                <ModalApp  tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
                <div className={`${styles.users} col-4 col-md-2`}>
                    <div className={`${styles.index}`}>{props.index}</div>
                    <div className={`${styles.nome}`}>{input.nome}</div>
                </div>
                <div className={`${styles.macrobloco} col-4 col-md-5`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.rendimentoWarning]}`}>{input.rendAcumulado}%</div>
                        <div className={`${styles.coluna} ${styles[input.rendimentoWarning]} ${styles.bigView}`}>{input.acumuladoCDI}%</div>
                        <div className={`${styles.coluna} ${styles[input.rendimentoWarning]}`}>{input.rendMensal}%</div>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.periodosCarteira}</div>
                    </div>
                </div>
                <div className={`${styles.macrobloco} col-4 col-md-5`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.premiosWarning]}`}>{input.premiosN3}%</div>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.premiosWarning]}`}>{input.premiosN2}%</div>
                        <div className={`${styles.coluna} ${styles[input.premiosWarning]}`}>{input.premiosN1}%</div>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.premiosPeriodoAtivo]}`}>{input.periodoAtivo}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LinhaClienteAdmin