import styles from './LinhaClientes.module.css'
import { useState, useEffect, useRef } from 'react';
import ModalApp from '../Modal';
import DetalheNotional from '../GRiscoNotionalDetalhe';

// LINHA DO CLIENTE NA TABELA DE CONTROLE DE RISCO
const LinhaClienteRisco = (props) => {

    // VARIÁVEL DE ESTADO
    const[input, setInput] = useState(null)

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:"formEditOpcoes"
    })

    var titulo = "Título aqui"
    var corpo = "Aqui estará o formulário de edição"

    // TODA VEZ QUE ALTERA AS INFOS DE INPUT REIMPRIME NA TELA
    useEffect(()=>{

        // AJUSTA A VISUALIZAÇÃO DOS VALORES PARA UMA IMPRESSÃO CORRETA
        var inputAjustado = JSON.parse(JSON.stringify(props.input));

        let RSLocale = Intl.NumberFormat('pt-BR');

        inputAjustado.deltaBetaPercentual = (inputAjustado.deltaBetaPercentual*100).toFixed(2)
        inputAjustado.deltaBetaValor = RSLocale.format(parseFloat(inputAjustado.deltaBetaValor).toFixed(0))
        inputAjustado.riscoGama = (inputAjustado.riscoGama*100).toFixed(2)
        inputAjustado.valorEmTravaPercentual = (inputAjustado.valorEmTravaPercentual*100).toFixed(1)
        inputAjustado.tetaValor = RSLocale.format(parseFloat(inputAjustado.tetaValor).toFixed(0)*(-1))
        inputAjustado.premiosPercentual = (inputAjustado.premiosPercentual*100).toFixed(1)
        inputAjustado.premios = RSLocale.format(parseInt(inputAjustado.premios))
        inputAjustado.notionalUsadoPercentual = (inputAjustado.notionalUsadoPercentual*100).toFixed(0)
        inputAjustado.notionalTotal = RSLocale.format(parseFloat(inputAjustado.notionalTotal).toFixed(0))
        inputAjustado.notionalUsado = RSLocale.format(parseFloat(inputAjustado.notionalUsado).toFixed(0))
        inputAjustado.margemPercentual = (inputAjustado.margemPercentual*100).toFixed(0)
        inputAjustado.rangeInferior = RSLocale.format(parseFloat(inputAjustado.rangeInferior).toFixed(0))
        inputAjustado.rangeSuperior = RSLocale.format(parseFloat(inputAjustado.rangeSuperior).toFixed(0))
        inputAjustado.provisaoN1 = RSLocale.format(parseFloat(inputAjustado.provisaoN1/1000).toFixed(0))
        inputAjustado.provisaoHoje = RSLocale.format(parseFloat(inputAjustado.provisaoHoje/1000).toFixed(0))
        inputAjustado.provisaoHojeExtr = RSLocale.format(parseFloat(inputAjustado.provisaoHojeExtr/1000).toFixed(0))
        inputAjustado.provisaoHojeIntr = RSLocale.format(parseFloat(inputAjustado.provisaoHojeIntr/1000).toFixed(0))
        inputAjustado.varPatrimonio = RSLocale.format(parseFloat(inputAjustado.varPatrimonio/1000).toFixed(0))
        inputAjustado.varPatrimonioPercentual = RSLocale.format(parseFloat(inputAjustado.varPatrimonioPercentual*100).toFixed(0)) + "%"

        // ATUALIZA A VARIÁVEL DE ESTADO
        setInput(inputAjustado)
    },[props.input])

    // CAPTURA A COR DA LINHA
    var lineColor = "line" + props.lineColor

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){

        // ABRE OU FECHA O MODAL
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE CHAMADA DE MODAL PARA AJUSTE MANUAL E RECÁLCULO
    function openModalDetalhe(){
        titulo = "Notional - " + props.input.nome
        corpo = <DetalheNotional base={props.baseKPIDetalhe} input={props.input}/>
        callModal("formEditOpcoes")
    }

    // SÓ RETORNA ALGO SE O INPUT NÃO FOR NULO
    if(input != null){
        return(
            <div className={`${styles.linhaCliente} ${styles[lineColor]}`}>
                <ModalApp  tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
                <div className={`${styles.users}`}>
                    <div className={`${styles.index}`}>{props.index}</div>
                    <div className={`${styles.nome}`}>{input.nome}</div>
                </div>
                <div className={`${styles.macrobloco} ${styles.bigView}`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.deltaBetaValor}</div>
                        <div className={`${styles.coluna} ${styles[input.deltaBetaPercentualWarning]} ${styles.bigView}`}>{input.deltaBetaPercentual}%</div>
                        <div className={`${styles.coluna} ${styles[input.riscoGamaWarning]} ${styles.bigView}`}>{input.riscoGama}%</div>
                        <div className={`${styles.coluna} ${styles[input.valorEmTravaPercentualWarning]}`}>{input.valorEmTravaPercentual}%</div>
                    </div>
                </div>
                <div className={`${styles.macrobloco}`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.tetaWarning]}`}>{input.tetaValor}</div>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.rangeInferior}|{input.rangeSuperior}</div>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.premios}</div>
                        <div className={`${styles.coluna} ${styles[input.premiosPercentualWarning]}`}>{input.premiosPercentual}%</div>
                    </div>
                </div>
                <div className={`${styles.macrobloco}`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.notionalTotal}</div>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles.btn}`} onClick={openModalDetalhe}>{input.notionalUsado}</div>
                        <div className={`${styles.coluna} ${styles[input.notionalUsadoPercentualWarning]}`}>{input.notionalUsadoPercentual}%</div>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.margemPercentualWarning]}`}>{input.margemPercentual}%</div>
                    </div>
                </div>
                <div className={`${styles.macrobloco}`}>
                    <div className={`${styles.dados}`}>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.provisaoHojeWarning]}`}>{input.provisaoN1} | {input.provisaoHoje}</div>
                        <div className={`${styles.coluna} ${styles.bigView}`}>{input.provisaoHojeExtr} | {input.provisaoHojeIntr}</div>
                        <div className={`${styles.coluna} ${styles.bigView} ${styles[input.varPatrimonioWarning]}`}>{input.varPatrimonio}</div>
                        <div className={`${styles.coluna} ${styles[input.varPatrimonioPercentualWarning]}`}>{input.varPatrimonioPercentual}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LinhaClienteRisco