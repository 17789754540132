import styles from './CardImagem.module.css';

const CardImagem = (props) => {

    // VARIÁVEIS
    var modelo = props.modelo
    var larguraImagem = props.larguraImagem
    var imgSrc = props.imagemSrc

    return(
        <div className={`${styles.overallbox}`}>
            <div className={`${styles.textobox}`}>
                {props.children}
            </div>
            <div className={`${styles.imagebox}`} width={larguraImagem}>
                <img src={props.imagemSrc} className={`${styles.imgctn}`} alt="Ola" />
            </div>
        </div>
    )

}

export default CardImagem