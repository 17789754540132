import { useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";

function PreLoader(props) {

  const [color, setColor] = useState("rgb(0,139,139)");

  return (
    <div className="preLoader">
      <CircleLoader
        color={color}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default PreLoader