import './Tabela.css'

const TabelaResultados = (props) => {

    //FUNÇÃO PARA FORMATAÇÃO EM MOEDA
    let RSLocale = Intl.NumberFormat('pt-BR');

    const base = props.dados

    return(
        <div className="width100">
            <table className="table">
            <thead>
                <tr>
                    <th scope="col" className="width25">Período</th>
                    <th scope="col" className="width25">Notional</th>
                    <th scope="col" className="width25">Prêmios</th>
                    <th scope="col" className="width25">(%)</th>
                </tr>
            </thead>
            </table>
            <div className="width100 table-results ">
                <table className="table ">
                <tbody>
                    {base.map(produto =>            
                        <tr key={produto.data}>
                            <td className="width25">{produto.data.getMonth() +1 + "/" + produto.data.getFullYear()}</td>
                            <td className="width25" data-valor>{produto.nocional}</td>
                            <td className="width25" data-valor>{produto.premio}</td>
                            <td className="width25">% {produto.percentual}</td>
                        </tr>)
                    }
                </tbody>
                </table>
            </div>
        </div>
    )

}

export default TabelaResultados