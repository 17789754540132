import { useRef, useEffect, useState } from "react";
import "./OpcoesOpItem.css";
import { convertDataToText, convertTextoToData } from "../../paginas/Admin/convertData";
import ModalApp from "../Modal";
import FormEditOpcoes from "../FormEditOp";

const OpcoesOpItem = (props) => {

    // UMA VERIFICAÇÃO BÁSICA PARA EVITAR QUEBRAS NO APP
    if(props.baseHistoricoAcao == undefined){
        var baseHistoricoAcao = []
    }else{
        // VARIÁVEL DE HISTÓRICO DA AÇÃO
        var baseHistoricoAcao = JSON.parse(JSON.stringify((props.baseHistoricoAcao)))
    }

    // VARIÁVEL DE ESTADO DA OPÇÃO
    const [opcaoDados, setOpcaoDados] = useState(props.dados)
    const [tetaVega, setTetaVega] = useState(0)

    // VARIÁVEL QUE INDICA SE O CAMPO ESTÁ NA SHORTLIST OU NÃO
    const [shortList, setShortList] = useState(props.shortList)
    const [symbol, setSymbol] = useState("edit_note")

    // NO CASO DA OPÇÃO MUDAR, É PRECISO QUE SE ATUALIZE A LISTA INTEIRA
    useEffect(()=>{

        // CALCULA TETA/VEGA
        var tetaVega = -1*((opcaoDados.tetaValor/opcaoDados.vega)*100)
        if(tetaVega > 0){}else{tetaVega = 0}
        setTetaVega(tetaVega)

    },[opcaoDados])

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:"formEditOpcoes"
    })

    var titulo = "Título aqui"
    var corpo = "Aqui estará o formulário de edição"

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){

        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){

        // ALTERA A VARIÁVEL NO PAI QUE INDICA QUE O MODAL ESTÁ ABERTO OU FECHADO
        props.abriuModal(statusModal)

        // ABRE OU FECHA O MODAL
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // VARIÁVEL DE REFERÊNCIA
    const opcaoLinha = useRef()
    const addRemoveShortlist = useRef()

    // AJUSTA A VARIÁVEL DE SÍMBOLO
    useEffect(()=>{
        if(shortList===true){
            setSymbol("edit_note")
        }else{
            
        }
    },[shortList])
    
    // AJUSTE DE VARIÁVEIS DE IMPRESSÃO
    var tipo = ""
    if(opcaoDados.tipo==="PUT"){tipo="P"}else{tipo="C"}
    
    // FUNÇÃO DE CHAMADA DE MODAL PARA AJUSTE MANUAL E RECÁLCULO
    function openModalSimulator(){
        titulo = opcaoDados.ticker + " - " + opcaoDados.tipo
        corpo = <FormEditOpcoes opcaoDados = {opcaoDados} baseHistoricoAcao={baseHistoricoAcao} alteraOpcao={setOpcaoDados}/>
        props.abriuModal(true)
        callModal("formEditOpcoes")
    }
    
    // JSX
    return(
        <div ref={opcaoLinha} className="opcoesOpItemBox box-shadow">
            <ModalApp  tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
            {shortList ?
                <div className="checkOpcao" ref={addRemoveShortlist} onClick={openModalSimulator}>
                    <span className="material-symbols-outlined addOpList">{symbol}</span>
                </div>
            :(
                <div className="checkOpcao" ref={addRemoveShortlist}>
                    <div className="rankingOp">{opcaoDados.ranking}</div>
                </div>
            )}
            <div className="linhaOpcao">
                <div className="opcoesOpData">
                    <div className="ticker">{opcaoDados.ticker}</div>
                    <div className="opdata smallView preco"><span>{opcaoDados.ultimo} / </span><span>{parseFloat(opcaoDados.retornoMensal*100).toFixed(1)}%</span></div>
                    <div className="opdata smallView">{tipo}</div>
                    <div className="opdata smallView">&#916;: {parseFloat(opcaoDados.delta).toFixed(2)}</div>
                    <div className="opdata smallView">V: {convertDataToText(new Date(opcaoDados.vencimento))}</div>
                </div>
                <div className="opcoesOpAcaoData">
                    <div className="acao">{props.dados.acao}</div>
                    <div className="acaodata smallView">Strk: {parseFloat(opcaoDados.strike).toFixed(2)}</div>
                    <div className="acaodata smallView">P: {parseFloat(opcaoDados.precoAcao).toFixed(2)}</div>
                    <div className="acaodata smallView">PA: {parseFloat(opcaoDados.precoAlvo).toFixed(2)}</div>
                    <div className="acaodata smallView">IVR: {parseFloat(opcaoDados.ivRank).toFixed(2)}</div>
                    <div className="acaodata smallView">IVP: {parseFloat(opcaoDados.ivRankPutsPercentil).toFixed(1)}%</div>
                    <div className="acaodata smallView bigView">Theta/Vega: {tetaVega.toFixed(0)}%</div>
                    <div className="acaodata smallView bigView">IFR: {parseInt(opcaoDados.ifr).toFixed(0)}</div>
                </div>
            </div>
        </div>
    )

}

export default OpcoesOpItem