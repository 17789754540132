    // LOOP NA BASE DE DADOS PARA CAPTURAR AS INFORMAÇÕES NECESSÁRIAS DE IMPRESSÃO
    function sum(ob1, ob2) {

        let sum = {};

        // SE SOMENTE O OBJETO 1 FOR INEXISTENTE, O RESULTADO É IGUAL O OBJETO 2
        if(ob1===undefined && ob2 !==undefined){
            sum = ob2

        // SE SOMENTE O OBJETO 2 FOR INEXISTENTE, O RESULTADO É IGUAL O OBJETO 2
        }else if(ob2===undefined && ob1 !== undefined){

            sum = ob1

        }else{

            // DEFINE UM OBJETO VAZIO PARA AÇÕES EM SUM CASO O MESMO AINDA NÃO TENHA SIDO FEITO
            sum["Ações"] = {}

            // SE OBJ1.AÇÕES NÃO FOR NULO E OBJ2.AÇÕES FOR NULO, SUM.AÇÕES = OBJ1.AÇÕES
            if(ob1["Ações"] !== undefined && ob2["Ações"] === undefined){
                sum["Ações"] = JSON.parse(JSON.stringify(ob1["Ações"]))
            }

            // SE OBJ2.AÇÕES NÃO FOR NULO E OBJ1.AÇÕES FOR NULO, SUM.AÇÕES = OBJ2.AÇÕES
            if(ob2["Ações"] !== undefined && ob1["Ações"] === undefined){
                sum["Ações"] = JSON.parse(JSON.stringify(ob2["Ações"]))
            }

            // SE NENHUM DOS DOIS FOR NULO
            if(ob2["Ações"] !== undefined && ob1["Ações"] !== undefined){

                // FAZ UM LOOP PELAS AÇÕES EM OBJ1.AÇÕES
                Object.keys(ob1["Ações"]).forEach(acao => {

                    // SE OBJ1.AÇÕES TIVER AÇÃO E OBJ2 NÃO SUM.AÇÕES.AÇÃO =  OBJ1.AÇÕES.AÇÃO
                    if(ob1["Ações"][acao] && (ob2["Ações"][acao] == {} || ob2["Ações"][acao] === undefined || ob2["Ações"][acao] === null)  ){
                        sum["Ações"][acao] = JSON.parse(JSON.stringify(ob1["Ações"][acao]))
                    }

                    // SE AMBOS TIVERAM A MESMA AÇÃO, LOOP NAS PROPRIEDADES
                    if(ob1["Ações"][acao] && ob2["Ações"][acao]){
                        
                        // DEFINE UM OBJETO VAZIO PARA AÇÃO CASO ELE NÃO EXISTA AINDA
                        sum["Ações"][acao] = {}

                        // DEFINE UMA NOVA VARIÁVEL CHAMADA SOMARPRODUTOPM PARA CÁLCULO DO PREÇO MÉDIO
                        sum["Ações"][acao]["somarprodutoPM"] = 0
                        
                        // PARA CADA PROPRIEDADE SUM.AÇÕES.AÇÃO.PROP = OBJ1.AÇÕES.AÇÃO.PROP + OBJ2.AÇÕES.AÇÃO.PROP
                        Object.keys(ob1["Ações"][acao]).forEach(propriedade => {
                            // PARA ESSES CAMPOS NENHUMA AÇÃO DE SOMA É REQUERIDA
                            if(propriedade === "Setor" || propriedade === "Preco Teto" || propriedade === "Preco Atual" || propriedade === "Preco Mes Anterior"){
                                sum["Ações"][acao][propriedade] = JSON.parse(JSON.stringify(ob1["Ações"][acao][propriedade]))
                            }else{
                                // NO GERAL AS OUTRAS PROPRIEDADES SERÃO SOMA
                                sum["Ações"][acao][propriedade] = ob1["Ações"][acao][propriedade] + ob2["Ações"][acao][propriedade]
                            }
                            
                        })

                        // DEFINE O PREÇO ATUAL
                        sum["Ações"][acao]["Preco Atual"] = sum["Ações"][acao]["Valor total"] / sum["Ações"][acao]["Quantidade"]

                        // DEFINE O PREÇO MÉDIO
                        sum["Ações"][acao]["Preco Medio"] = ((ob1["Ações"][acao]["Quantidade"]*ob1["Ações"][acao]["Preco Medio"]) + (ob2["Ações"][acao]["Quantidade"]*ob2["Ações"][acao]["Preco Medio"]))/(ob1["Ações"][acao]["Quantidade"] + ob2["Ações"][acao]["Quantidade"])
                        
                        // DEFINE O PREÇO MÉDIO
                        sum["Ações"][acao]["Preco Mes Anterior"] = ((ob1["Ações"][acao]["Quantidade"]*ob1["Ações"][acao]["Preco Mes Anterior"]) + (ob2["Ações"][acao]["Quantidade"]*ob2["Ações"][acao]["Preco Mes Anterior"]))/(ob1["Ações"][acao]["Quantidade"] + ob2["Ações"][acao]["Quantidade"])
                     
                    }

                })

                // FAZ UM LOOP PELAS AÇÕES EM OBJ2.AÇÕES
                Object.keys(ob2["Ações"]).forEach(acao => {

                    // SE OBJ2.AÇÕES TIVER AÇÃO E OBJ1 NÃO SUM.AÇÕES.AÇÃO =  OBJ2.AÇÕES.AÇÃO
                    if(ob2["Ações"][acao] && (ob1["Ações"][acao] == {} || ob1["Ações"][acao] === undefined || ob1["Ações"][acao] === null)  ){
                        sum["Ações"][acao] = JSON.parse(JSON.stringify(ob2["Ações"][acao]))
                    }

                })
            }

            // LOOP NOS CAMPOS DO OBJETO1 PARA SOMA DOS CAMPOS RESTANTES
            Object.keys(ob1).forEach(key => {
                if(key==="Ações"){}else{

                    // NESSES CASOS NÃO É PARA QUE A SOMA ACONTEÇA
                    if(key==="IBOV (%)" || key==="IPCA (%)" || key==="CDI (%)"){
                        sum[key] = parseFloat(ob1[key])
                    
                    }else if(key==="Data do Registro"){
                        sum[key] = ob1[key]

                    // CRIA O VALOR PONDERADO PARA CÁLCULO DAS COTAS
                    }else if(key==="Valor da Cota"){
                        var cotaPonderadaOb1 = Number(ob1["Valor da Cota"]) * Number(ob1["Quantidade de Cota"])
                        var cotaPonderadaOb2 = Number(ob2["Valor da Cota"]) * Number(ob2["Quantidade de Cota"])
                        var patrimonioTotal = Number(ob1["Quantidade de Cota"]) + Number(ob2["Quantidade de Cota"])
                        sum["Valor da Cota"] = (cotaPonderadaOb1 + cotaPonderadaOb2)/patrimonioTotal

                    // CRIA O VALOR PONDERADO PARA CÁLCULO DAS COTAS MODELO ASSET
                    }else if(key==="Valor da Cota Asset"){
                        var cotaPonderadaOb1Asset = Number(ob1["Valor da Cota Asset"]) * Number(ob1["Quantidade de Cota Asset"])
                        var cotaPonderadaOb2Asset = Number(ob2["Valor da Cota Asset"]) * Number(ob2["Quantidade de Cota Asset"])
                        var patrimonioTotalAsset = Number(ob1["Quantidade de Cota Asset"]) + Number(ob2["Quantidade de Cota Asset"])
                        sum["Valor da Cota Asset"] = (cotaPonderadaOb1Asset + cotaPonderadaOb2Asset)/patrimonioTotalAsset
                   
                    // PARA O RESTANTE A SOMA ACONTECE PADRÃO
                    }else{
                        if (ob2.hasOwnProperty(key)) {
                            sum[key] = parseFloat(ob1[key]) + parseFloat(ob2[key])
                        } 
                    }

                }
            })

            // LOOP NOS CAMPOS DO OBJETO2 PARA CAPTURAR OS CAMPOS QUE POR VENTURA NÃO EXISTIAM NO OBJETO 1
            Object.keys(ob2).forEach(key2 => {
                if(key2==="Ações"){}else if(key2==="Data do Registro"){
                    sum[key2] = ob2[key2]
                }else{
                    if(ob1[key2]){}else{
                        sum[key2] = parseFloat(ob2[key2])
                    }
                }
            })

            // CALCULA O RESULTADO DAS OPÇÕES (%)
            if(sum["Resultado das Opcoes"] && sum["Notional (R$)"]){
                sum["Resultado das Opcoes (%)"] = sum["Resultado das Opcoes"] / sum["Notional (R$)"]
            }

        }
        
        return sum;
    }

    export {sum}