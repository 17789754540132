    // FUNÇÃO DE MÁSCARA DE CURRENCY
    const moneyMask = (value) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits:2 }
        value = new Intl.NumberFormat('pt-BR', options).format(value)
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
        const result = new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value) / 100
        )
      
        return 'R$ ' + result
    }

    // FUNÇÃO DE MÁSCARA NÚMERO
    const numMask = (value) => {
        const options = { minimumFractionDigits: 4, maximumFractionDigits:4 }
        value = new Intl.NumberFormat('pt-BR', options).format(value)
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')        
        const result = new Intl.NumberFormat('pt-BR', options).format(
          parseFloat(value) / 10000
        )
      
        return result
    }

    // FUNÇÃO DE REVERSÃO DA CURRENCY
    function reverseFormatMoney(val){
        val = val.slice(3);
        val = String(val);
        val = val.replace('.', '').replace(',', '').replace(/\D/g, '')
        const result = (Number(val)/100).toFixed(2)

        return Number(result)

    }

    // FUNÇÃO QUE REVERTE A MÁSCARA DE NÚMERO
    const reverseNumMask = (val) => {
        val = String(val);
        val = val.replace('.', '').replace(',', '').replace(/\D/g, '')
        const result = (Number(val)/10000).toFixed(4)
        return Number(result) 
    }

    export {
        moneyMask,
        numMask,
        reverseNumMask,
        reverseFormatMoney
      }