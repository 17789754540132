import Accordion from 'react-bootstrap/Accordion';
import { filterData, SearchType } from 'filter-data';
import { useState, useEffect, useRef} from 'react';
import CampoForm from '../CampoForm';
import "./FiltrosOpcoesOp.css";
import { moneyMask, reverseFormatMoney, reverseNumMask, numMask } from "../NumberMasks";
import ModalApp from '../Modal';

const FiltrosOpcoesOp = (props) => {

    // VARIÁVEL REF DO BOTÃO DE FILTRO
    const botaoFiltro = useRef()
    const selectCallsEPuts = useRef()

    // VARIÁVEIS DO MODAL
    const [modalShow, setModalShow] = useState(false)
    const [modalTitulo, setModalTitulo] = useState("")
    const [modalCorpo, setModalCorpo] = useState("")
    const [modalTipo, setModalTipo] = useState("")

    var titulo = ""
    var corpo = ""

    function callModal(props){
        setModalTitulo(titulo);
        setModalCorpo(corpo);
        setModalTipo(props);
        setModalShow(true);
    }

    // VARIÁVEIS DE ESTADO DE ARMAZENAMENTO
    const [dataVencInicio, setDataVencInicio] = useState("")
    const [dataVencFim, setDataVencFim] = useState("")
    const [deltaInicio, setDeltaInicio] = useState("")
    const [deltaFim, setDeltaFim] = useState("")
    const [ticker, setTicker] = useState("")
    const [acao, setAcao] = useState("")
    const [strikeMin, setStrikeMin] = useState("")
    const [strikeMax, setStrikeMax] = useState("")
    const [callsEputs, setCallsEPuts] = useState(0)
    const [precoAcaoMin, setPrecoAcaoMin] = useState("")
    const [precoAcaoMax, setPrecoAcaoMax] = useState("")

    // VARIÁVEL DE BASE DE RANKINGS
    const rankingOpcoes =[
        {nome: "Ranking 5", chave:0, id:"ranking5", valor:"5", checked:true},
        {nome: "Ranking 4", chave:1, id:"ranking4", valor:"4", checked:true},
        {nome: "Ranking 3", chave:2, id:"ranking3", valor:"3", checked:true},
        {nome: "Ranking 2", chave:3, id:"ranking2", valor:"2", checked:true},
        {nome: "Ranking 1", chave:4, id:"ranking1", valor:"1", checked:true},
        {nome: "Ranking 0", chave:5, id:"ranking0", valor:"0", checked:true},
        {nome: "Ranking -", chave:6, id:"ranking-", valor:"-", checked:true}
    ]

    const [rankings, setRankings] = useState(rankingOpcoes);

    // VARIÁVEL DE LISTA DE CALLS E PUTS
    const listaCallsEPuts = [
        {chave:"TodosOsTipos", nome:"Todos", value: 0},
        {chave:"TodosAsCalls", nome:"Call's", value: 1},
        {chave:"TodosAsPuts", nome:"Put's", value: 2},
    ]

    // BASES COMPLETAS
    const baseCompleta = JSON.parse(JSON.stringify((props.baseCompleta)))

    // FUNÇÃO QUE FILTRA    
    function submitFiltro (evento) {

        // PREVINE A AÇÃO NATURAL DO BOTÃO DE SUBMIT
        if(evento){
            evento.preventDefault()
        }

        // CRIA UMA CÓPIA DA BASE COMPLETA
        var baseCompletaCopia = JSON.parse(JSON.stringify((baseCompleta)))

        // FILTRA O TICKER
        searchConditions = [
            {
            key: 'ticker',
            value: ticker,
            type: SearchType.LK,
            },
        ];

        baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);

        // FILTRA A AÇÃO
        searchConditions = [
            {
            key: 'acao',
            value: acao,
            type: SearchType.LK,
            },
        ];

        baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);

        // FILTRO DE RANKINGS NA BASE
        rankings.forEach((ranking,index) => {

            if(ranking.checked === false){
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'ranking',
                    value: ranking.valor,
                    type: SearchType.NE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }
        })

        // FILTRA O TIPO
        if(callsEputs==="0"){

        }else if(callsEputs==="1"){
            searchConditions = [
                {
                key: 'tipo',
                value: 'CALL',
                type: SearchType.LK,
                },
            ];
    
            baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
        }else{
            searchConditions = [
                {
                key: 'tipo',
                value: 'PUT',
                type: SearchType.LK,
                },
            ];
    
            baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
        }

        // VALIDA SE OS CAMPOS DE DATA ESTÃO PREENCHIDOS E APLICA OS FILTROS DE ACORDO NAS BASES DE DADOS E NA DE USUÁRIOS
        if((dataVencInicio ===null || dataVencInicio === "" ) && (dataVencFim === null || dataVencFim === "")){
        }else if(dataVencFim > dataVencInicio || (dataVencInicio !== "" && dataVencFim === "") || (dataVencInicio === "" && dataVencFim !== "")){

            // FILTRA A BASE DA ACORDO COM A DATA INICIAL
            if(dataVencInicio === null || dataVencInicio === "" ){}else{
                for (let i = 0; i < baseCompletaCopia.length; i++) {
                    var vencimento = new Date(baseCompletaCopia[i].vencimento)
                    var vencMin = new Date(dataVencInicio)
                    if(vencimento <= vencMin){
                        baseCompletaCopia.splice(i,1)
                        i=i-1
                    }else{}
                }
            }  

            // FILTRA A BASE DA ACORDO COM A DATA FINAL
            if(dataVencFim === null || dataVencFim === ""){}else{
                for (let i = 0; i < baseCompletaCopia.length; i++) {
                    var vencimento = new Date(baseCompletaCopia[i].vencimento)
                    var vencMax = new Date(dataVencFim)
                    if(vencimento >= vencMax){
                        baseCompletaCopia.splice(i,1)
                        i=i-1
                    }else{}
                }
            }

        }

        // FILTRA DE ACORDO COM MENOR E MAIOR VALOR DE STRIKE DA AÇÃO
        // VERIFICA SE OS CAMPOS DE PATRIMÔNIO ESTÃO COERENTES E APLICA OS FILTROS DE ACORDO
        if((strikeMax == null || strikeMax === "" || strikeMax == 0) && (strikeMin == null || strikeMin === "" | strikeMin == 0)){
        }else if((strikeMax < strikeMin) && (strikeMax != 0 && strikeMin != 0)){

            //console.log("dados inconsistentes")
            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os campos <strong>Strike Máximo e Data Fim</strong></p>
                    <p>Devem ser maiores do que os campos <strong>Strike Mínimo e Data Início</strong> respectivamente</p>
                </div>
            callModal("")
            return false

        }else{

            if(strikeMin == null || strikeMin === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                var searchConditions = [
                    {
                    key: 'strike',
                    value: strikeMin,
                    type: SearchType.GTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

            if(strikeMax == null || strikeMax === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'strike',
                    value: strikeMax,
                    type: SearchType.LTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

        }

        // FILTRA DE ACORDO COM MENOR E MAIOR VALOR DE STRIKE DA AÇÃO
        // VERIFICA SE OS CAMPOS DE PATRIMÔNIO ESTÃO COERENTES E APLICA OS FILTROS DE ACORDO
        if((precoAcaoMax == null || precoAcaoMax === "" || precoAcaoMax == 0) && (precoAcaoMin == null || precoAcaoMin === "" | precoAcaoMin == 0)){
        }else if((precoAcaoMax < precoAcaoMin) && (precoAcaoMax != 0 && precoAcaoMin != 0)){

            //console.log("dados inconsistentes")
            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os campos <strong>Strike Máximo, Preço Ação Máximo e Data Fim</strong></p>
                    <p>Devem ser maiores do que os campos <strong>Strike Mínimo, Preço Ação Mínimo e Data Início</strong> respectivamente</p>
                </div>
            callModal("")
            return false

        }else{

            if(precoAcaoMin == null || precoAcaoMin === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                var searchConditions = [
                    {
                    key: 'precoAcao',
                    value: precoAcaoMin,
                    type: SearchType.GTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

            if(precoAcaoMax == null || precoAcaoMax === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'precoAcao',
                    value: precoAcaoMax,
                    type: SearchType.LTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

        }

        // FILTRA DE ACORDO COM MENOR E MAIOR VALOR DE DELTA DA AÇÃO
        // VERIFICA SE OS CAMPOS DE PATRIMÔNIO ESTÃO COERENTES E APLICA OS FILTROS DE ACORDO
        if((deltaFim == null || deltaFim=== "" || deltaFim == 0) && (deltaInicio == null || deltaInicio === "" | deltaInicio == 0)){
        }else if((deltaFim < deltaInicio) && (deltaFim != 0 && deltaInicio != 0)){

            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os campos <strong>Strike Máximo e Data Fim</strong></p>
                    <p>Devem ser maiores do que os campos <strong>Strike Mínimo e Data Início</strong> respectivamente</p>
                </div>
            callModal("")
            return false

        }else{

            if(deltaInicio == null || deltaInicio === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                var searchConditions = [
                    {
                    key: 'delta',
                    value: deltaInicio,
                    type: SearchType.GTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

            if(deltaFim == null || deltaFim === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'delta',
                    value: deltaFim,
                    type: SearchType.LTE,
                    },
                ];

                baseCompletaCopia = filterData(baseCompletaCopia, searchConditions);
            }

        }

        // CONVERTE TODOS OS CAMPOS DATA DE TEXTO PARA DATA
        for (let i = 0; i < baseCompletaCopia.length; i++) {
            var vencimentoData = new Date(baseCompletaCopia[i]["vencimento"])
            baseCompletaCopia[i]["vencimento"] = vencimentoData
        }

        // FILTRA RETORNOS MENSAIS ABSURDOS
        for (let i = 0; i < baseCompletaCopia.length; i++) {
            var rendimento = baseCompletaCopia[i]["retornoMensal"]
            if(rendimento > 0.03){
                baseCompletaCopia[i]["retornoMensal"] = 0
            }
        }

        // RANKEIA A BASE POR RENDIMENTO
        baseCompletaCopia.sort((a, b) => b.retornoMensal - a.retornoMensal);

        // RETIRA DA LISTA DE OPÇÕES OS ITENS QUE ESTÃO NA SHORTLIST
        for (let i = 0; i < props.shortList.length; i++) {
            // VARRE TODOS OS TICKERS NA BASE COMPLETA
            for (let j = 0; j <baseCompletaCopia.length; j++) {
                if(props.shortList[i]["ticker"]===baseCompletaCopia[j]["ticker"]){
                    baseCompletaCopia.splice(j,1)
                    j=j-1
                }
            }
        }

        // VERIFICA SE A BASE RETORNADA ESTÁ VAZIA
        if(baseCompletaCopia.length===0){
            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os filtros estão retornando uma base sem dados</p>
                    <p>Por favor reveja os critérios inseridos e tente novamente</p>
                </div>
            callModal("")
            return false
        }else{
            // ATUALIZA A BASE DE AÇÕES FORA DA SHORTLIST
            props.atualizaBaseOpcoes(baseCompletaCopia)

        }

    }

    // FUNÇÃO PROMISE DE ZERAR VARIÁVEIS DE ESTADO
    const resetVarEstado = async () => {

        // RETORNA O SELECT PARA VALOR PADRÃO
        selectCallsEPuts.current.selectedIndex = 0

        // ZERA AS VARIÁVEIS DE ESTADO
        setRankings(rankingOpcoes)
        setPrecoAcaoMin("")
        setPrecoAcaoMax("")
        setCallsEPuts(0)
        setDataVencInicio("")
        setDataVencFim("")
        setTicker("")
        setAcao("")
        setStrikeMin("")
        setStrikeMax("")
        setDeltaInicio("")
        setDeltaFim("")
    }

    // FUNÇÃO RESET
    const resetFiltro = async (eventoReset) => {
        
        // PREVENT THE DEFAULT
        if(eventoReset){
            eventoReset.preventDefault()
        }

        await resetVarEstado()

        // RODA A FUNÇÃO DE FILTRO NORMALMENTE
        botaoFiltro.current.click()

    }

    return(
        <div>
            <ModalApp tipo ={modalTipo} show={modalShow} change={setModalShow} titulo={modalTitulo} corpo ={modalCorpo}/>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Filtros</Accordion.Header>
                    <Accordion.Body>
                        <form id="form-cadastro-profile" data-form="CadastroPerfil">
                            <div className="row">
                                <div className="col-4 paddingForm">
                                    <div className="conjuntoInputsTitulo">Ativos</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm 
                                            valor = {ticker}
                                            aoAlterado = {setTicker}
                                            nomeCampo= "Ticker" 
                                            campoId="ticker" 
                                            tipo="text" 
                                            dataCampo="ticker" 
                                            placeholder="" 
                                            dataMask=""
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm 
                                            valor = {acao}
                                            aoAlterado = {setAcao}
                                            nomeCampo= "Ação" 
                                            campoId="acao" 
                                            tipo="text" 
                                            dataCampo="acao" 
                                            placeholder="" 
                                            dataMask=""
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                </div>
                                <div className="col-4 paddingForm">
                                    <div className="conjuntoInputsTitulo">Strikes</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm 
                                            valor = {moneyMask(strikeMin)} 
                                            aoAlterado = {valor => setStrikeMin((prev)=>(reverseFormatMoney(valor)))} 
                                            nomeCampo= "Min" 
                                            campoId="strikeMin" 
                                            tipo="text" 
                                            dataCampo="strikeMin" 
                                            placeholder="Strike Mínimo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm 
                                            valor = {moneyMask(strikeMax)} 
                                            aoAlterado = {valor => setStrikeMax((prev)=>(reverseFormatMoney(valor)))} 
                                            nomeCampo= "Max" 
                                            campoId="strikeMax" 
                                            tipo="text" 
                                            dataCampo="strikeMax" 
                                            placeholder="Strike Máximo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                </div>
                                <div className="col-4 paddingForm">
                                    <div className="conjuntoInputsTitulo">Delta</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm 
                                            valor = {numMask(deltaInicio)} 
                                            aoAlterado = {valor => setDeltaInicio((prev)=>(reverseNumMask(valor)))} 
                                            nomeCampo= "Min" 
                                            campoId="deltamin" 
                                            tipo="text" 
                                            dataCampo="deltaMin" 
                                            placeholder="Delta Mínimo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm 
                                            valor = {numMask(deltaFim)} 
                                            aoAlterado = {valor => setDeltaFim((prev)=>(reverseNumMask(valor)))} 
                                            nomeCampo= "Max" 
                                            campoId="deltamax" 
                                            tipo="text" 
                                            dataCampo="deltaMax" 
                                            placeholder="Delta Máximo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                </div>
                                <div className="col-5 paddingForm">
                                    <div className="conjuntoInputsTitulo">Data de Vencimento</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm
                                            valor = {dataVencInicio} 
                                            aoAlterado = {setDataVencInicio}
                                            nomeCampo= "Data Início" 
                                            campoId="data-inicio" 
                                            tipo="date" 
                                            dataCampo="dataInicio" 
                                            placeholder="" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm
                                            valor = {dataVencFim} 
                                            aoAlterado = {setDataVencFim}
                                            nomeCampo= "Data Fim" 
                                            campoId="data-fim" 
                                            tipo="date" 
                                            dataCampo="dataFim" 
                                            placeholder="" 
                                            dataMask= ""
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                </div>
                                <div className="col-4 paddingForm">
                                    <div className="conjuntoInputsTitulo">Preço Ação</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm 
                                            valor = {moneyMask(precoAcaoMin)} 
                                            aoAlterado = {valor => setPrecoAcaoMin((prev)=>(reverseFormatMoney(valor)))} 
                                            nomeCampo= "Min" 
                                            campoId="precomin" 
                                            tipo="text" 
                                            dataCampo="precoMin" 
                                            placeholder="Preço Mínimo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm 
                                            valor = {moneyMask(precoAcaoMax)} 
                                            aoAlterado = {valor => setPrecoAcaoMax((prev)=>(reverseFormatMoney(valor)))} 
                                            nomeCampo= "Max" 
                                            campoId="precomax" 
                                            tipo="text" 
                                            dataCampo="precoMax" 
                                            placeholder="Preço Máximo" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                </div>
                                <div className="col-3 paddingForm">
                                    <div className="conjuntoInputsTitulo">Puts & Calls</div>
                                    <div className="conjuntoInputsBox row">
                                        <CampoForm 
                                            referencia={selectCallsEPuts}
                                            valor = {callsEputs}
                                            aoAlterado = {setCallsEPuts}
                                            nomeCampo= "Calls / Puts" 
                                            lista = {listaCallsEPuts}
                                            campoId="callsEPuts" 
                                            tipo="select" 
                                            dataCampo="callsEPuts" 
                                            placeholder="" 
                                            dataMask=""
                                            required= {false}  
                                            largura= "12"
                                        />
                                    </div>
                                </div>       
                                <div className="col-12 radio-box">
                                    <div className="radio-line">
                                        <div className="radio-title">Ranking</div>
                                        {rankings.map((objeto) => (
                                            <CampoForm 
                                                key = {objeto.chave}
                                                checked = {rankings[objeto.chave]["checked"]}
                                                valor = {objeto.valor}
                                                aoAlterado = {checked => setRankings(rankings.map(pr =>{
                                                    if(objeto.chave === pr.chave){
                                                        return {
                                                            nome: pr.nome,
                                                            chave:pr.chave,
                                                            id:pr.id,
                                                            valor:pr.valor,
                                                            checked: checked,
                                                        }
                                                    }else{
                                                        return pr
                                                    }
                                                }))}
                                                nomeCampo= {objeto.nome}
                                                campoId={objeto.nome} 
                                                tipo="checkbox" 
                                                dataCampo={objeto.nome} 
                                                placeholder="" 
                                                dataMask="" 
                                                required= {false}  
                                                largura= "2"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 btn-cadastro-box">
                                    <button type="reset" className="btn btn-gray btn-reset" id="btn-reset" data-campo="resetForm" onClick={resetFiltro}>Reset</button>
                                    <button ref={botaoFiltro} type="submit" className="btn btn-primary btn-cadastro margin-left" id="btn-filtro" data-campo="submitForm" onClick={submitFiltro}>Enviar</button>
                                </div>
                            </div>
                        </form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )

}

export default FiltrosOpcoesOp