import './Termos.css'
import '../Home/Home.css'
import '../../index.css'
import Form from 'react-bootstrap/Form';
import PreLoader from '../../componentes/PreLoader';
import ModalApp from '../../componentes/Modal';
import { useState, useRef, useEffect } from 'react'
import { doc, updateDoc, getDoc } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import { app } from '../../config/firebase';
import { auth } from '../../config/firebase';
import { signOut } from 'firebase/auth';
import { getStorage, ref,getDownloadURL } from "firebase/storage";

// INICIA O BANCO DE DADOS DO FIREBASE
const db = getFirestore(app);

// MÓDULO DE TERMOS E POLÍTICA
const TermoEPolitica = (props) => {

    //VARIÁVEIS DE ESTADO
    const [modalShow, setModalShow] = useState(false)
    const [modalTitulo, setModalTitulo] = useState("")
    const [modalCorpo, setModalCorpo] = useState("")
    const [modalTipo, setModalTipo] = useState("")
    const [logOut, setLogOut] = useState("")

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalTitulo(titulo);
        setModalCorpo(corpo);
        setModalTipo(props);
        setModalShow(true);
    }

    const [loading, setLoading] = useState(false);

    var corpo = ""
    var titulo = ""

    // FUNÇÕES QUE ATRIBUEM AÇÕES A ITENS NO HTML APÓS A PÁGINA SER CARREGADA
    const reff = useRef(null);
    useEffect(() => {
    })

    //DADOS MESTRES
    const userUid = props.userUid;
    const nomeUsuario = props.user.nome;
    const consultor = props.user.consultor
    const dadosConsultor = {}

    // PUXA OS DADOS DO CONSULTOR DEVIDO
    props.baseConsultores.forEach(element => {
        if(parseInt(element.chave) === consultor){
            dadosConsultor["nome"] = element.nome
            dadosConsultor["email"] = element.email
            dadosConsultor["telefone"] = element.telefone
            dadosConsultor["whatsapp"] = element.telefone
            dadosConsultor["descricao"] = element.descricao
            dadosConsultor["imgBackground"] = element.imagem
        }
    });

    // ADICIONA AO BOTÃO DO FORMULÁRIO AS FUNÇÕES DE VALIDAÇÃO E UPLOAD NO FIREBASE
    const submitAprovacaoTermo = (evento) => {
        evento.preventDefault()
        setLoading(true);
        //CAPTURA AS VARIÁVEIS DO EVENTO
        const form = evento.target.form
        var aprovacao = document.getElementById("termoAceiteCheckbox").checked;
        //VALIDA A APROVAÇÃO
        if(aprovacao===true){
            
            // SOBE O CADASTRO COMPLETO NO FIRESTORE
            var horaAprovacao = Date. now() 
            const docRef = doc(db, 'users', userUid)
            const aceiteTermo = updateDoc(docRef,{
                termoEPolitica: true,
                horaAprovacaoTermoEPolitica: horaAprovacao
            }).then((usuario) => {
                const baseUsuario = getDoc(docRef).then((base)=>{;
                    props.aceite(true);
                    props.alteraUsuario(base.data())
                })
            }).catch((error) => {
                titulo = "Erro ao processar a alteração"
                corpo = 
                    <div className="">
                        <p>Aconselhamos que procure por uma iternet estável e tente novamente em instantes</p>
                    </div>
                setLoading(false);
                callModal("")
                return false
            });

        }else{
            titulo = "Termo não aceito!"
            corpo = 
                <div className="">
                    <p>É necessário confirmar o aceite dos termos e política para prosseguir</p>
                    <p>O checkbox para tal econtra-se acima do botão de enviar</p>
                </div>
            setLoading(false);
            callModal("")
            return false
        }
        
    }

    const downloadTermo = (evento) => {
        evento.preventDefault()
        setLoading(true);

        // Create a reference with an initial file path and name
        const storage = getStorage();
        const referencia = 'termoEPolitica/Termos_de_Uso_TechUm.pdf'

        getDownloadURL(ref(storage, referencia))
        .then((url) => {
            fetch(url).then(response=>response.blob()).then(blob=>{
                const blobURL = window.URL.createObjectURL(new Blob([blob]))
                const fileName = referencia.split("/").pop();
                const aTag = document.createElement("a");
                aTag.href = blobURL;
                aTag.setAttribute("download", fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
                setLoading(false);
            })
        })
        .catch((error) => {
            titulo = "Erro"
            corpo = 
            <div className="">
                <p>Não foi possível fazer o download da documentação</p>
                <p>Por favor entre em contato com seu consultor.</p>
            </div>
            setLoading(false);
            callModal("")

        });

    }

    //ADICIONA A FUNÇÃO DE ABERTURA DO MODAL NO BOTÃO DE LOG-OUT
    const modalLogOut = () => {
        titulo = "Log-Out"
        corpo = 
        <div className="">
            <p>Tem certeza que deseja sair da sua conta?</p>
            <p>Caso sim, confirme no botão "Entendi"</p>
        </div>
        setLoading(false);
        callModal("logOut")
    }

    //FUNÇÃO DE LOG-OUT
    if(logOut===true){
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }

    //JSX
    return(
        <div>
            {loading ? (<PreLoader/>) : (
            <main>
                <section className="minha-conta container-md">
                <ModalApp tipo ={modalTipo} show={modalShow} logOut={setLogOut} change={setModalShow} titulo={modalTitulo} corpo ={modalCorpo}/>
                    <div className="cadastro-bemvindo">
                        <p><strong>Olá {nomeUsuario}</strong></p>
                        <p>Seja bem vindo a TechUm!</p>
                        <p>Para que possa usufruir das facilidades do nosso App é preciso que aceite nossos <em>Termos e Condições</em></p>
                        <p>Em caso de dúvidas, por favor entre em contato conosco:</p>
                        <p><em>{dadosConsultor.nome}</em></p>
                        <p><em>{dadosConsultor.telefone}</em></p>
                    </div>
                </section>
                <section className="projecao-carteira container-md">
                    <h1><span className="material-symbols-outlined icon-title">description</span>Termos e Condições</h1>
                    <div className="row">
                        <div className="col-12 texto-politica-box">
                            <div className="col-12 texto-politica">
                                <p>1. Este Termo refere-se &agrave; TechUm. Ao navegar neste site e usar os servi&ccedil;os que s&atilde;o fornecidos, voc&ecirc; <strong>AFIRMA</strong> que <strong>LEU, COMPREENDEU e CONCORDA</strong> com nossos Termos e Condi&ccedil;&otilde;es. Estes Termos e Condi&ccedil;&otilde;es abrangem todos os aplicativos, servi&ccedil;os de Internet ou extens&otilde;es dos sites relacionados. <strong>Caso voc&ecirc; n&atilde;o concorde&nbsp;</strong>ou n&atilde;o tenha ficado claro algum ponto, sugere-se que voc&ecirc; <strong>N&Atilde;O NAVEGUE MAIS NELE</strong> at&eacute; que voc&ecirc; tenha sanado todas as suas d&uacute;vidas. Voc&ecirc; poder&aacute; ainda, a qualquer tempo, retornar ao site, clicar em termos de uso e reler quantas vezes desejar.&nbsp;</p>
                                <p><strong>Termos e Condi&ccedil;&otilde;es </strong></p>
                                <p>2. Os Termos e Condi&ccedil;&otilde;es da TechUm regem o uso deste Site e todo o seu conte&uacute;do &#40;&quot;Site&quot;&#41;. Estes Termos descrevem as regras e regulamentos que orientam o uso de TechUm localizado em http://techum.com.br. Todos os materiais/informa&ccedil;&otilde;es/documentos/servi&ccedil;os ou todas as outras entidades &#40;coletivamente referidas como &quot;conte&uacute;do&apos;&apos;&#41; que aparecem no http://techum.com.br ser&atilde;o administrados de acordo com estes Termos e Condi&ccedil;&otilde;es.&nbsp;</p>
                                <p><strong>ATEN&Ccedil;&Atilde;O: N&atilde;o continue a usar este site se voc&ecirc; tiver qualquer obje&ccedil;&atilde;o a qualquer um dos Termos e Condi&ccedil;&otilde;es declarados nesta p&aacute;gina. </strong></p>
                                <p>3. O site &eacute; destinado a usu&aacute;rios com 18 &#40;dezoito&#41; anos de idade ou mais. <strong>Se voc&ecirc; tem menos de 18 &#40;dezoito&#41; anos, n&atilde;o poder&aacute; usar ou registrar-se para usar este site ou seus servi&ccedil;os sem a permiss&atilde;o ou consentimento dos pais.</strong> Ao concordar com estes Termos e Condi&ccedil;&otilde;es, voc&ecirc; tem a capacidade legal necess&aacute;ria para cumprir e ficar vinculado por estes Termos e Condi&ccedil;&otilde;es.&nbsp;</p>
                                <p>4. TechUm faz uso de cookies. Ao acessar nosso site, voc&ecirc; concorda em usar cookies de acordo com nossa Pol&iacute;tica de Cookies. &quot;Algum dos nossos parceiros de site podem usar cookies.&quot;&nbsp;</p>
                                <p>5. TechUm det&ecirc;m os direitos de propriedade intelectual de todo o conte&uacute;do. Todos os direitos de propriedade intelectual s&atilde;o reservados. Voc&ecirc; pode acessar qualquer conte&uacute;do do site para seu uso pessoal, sujeito &agrave;s restri&ccedil;&otilde;es definidas nestes termos e condi&ccedil;&otilde;es. TechUm, por meio deste, determina que o usu&aacute;rio do site <strong>n&atilde;o</strong> cometa as seguintes a&ccedil;&otilde;es:&nbsp;</p>
                                <ul>
                                    <li>Reproduzir, republicar, duplicar ou copiar qualquer conte&uacute;do da TechUm ;</li>
                                    <li>Vender, alugar, sublicenciar e/ou de outra forma comercializar qualquer conte&uacute;do da TechUm ;&nbsp;</li>
                                    <li>Executar e / ou exibir publicamente qualquer conte&uacute;do da TechUm ;&nbsp;</li>
                                    <li>Usar este site de forma que seja, ou talvez, danifique e/ou afete o acesso do usu&aacute;rio a este site;&nbsp;</li>
                                    <li>Usar este site contr&aacute;rio &agrave;s regras, leis e regulamentos relevantes do seu pa&iacute;s de resid&ecirc;ncia, ou de uma maneira que cause, ou possa causar, danos ao site ou a qualquer pessoa ou entidade comercial;&nbsp;</li>
                                    <li>Realizar minera&ccedil;&atilde;o de dados ou qualquer outra atividade semelhante relacionada a este site, ou durante o uso deste site; e&nbsp;</li>
                                    <li>Usando este site para se envolver em qualquer forma de publicidade ou marketing empresarial.&nbsp;</li>
                                </ul>
                                <p>6. &Aacute;reas espec&iacute;ficas deste site podem ser restritas ao acesso do usu&aacute;rio, e a TechUm pode estender ainda mais essa restri&ccedil;&atilde;o a todo o site, a qualquer momento e a seu exclusivo crit&eacute;rio. Qualquer identifica&ccedil;&atilde;o de usu&aacute;rio, chave de seguran&ccedil;a ou senha que voc&ecirc; possa ter neste site s&atilde;o confidenciais e voc&ecirc; &eacute; respons&aacute;vel por manter a confidencialidade dessas informa&ccedil;&otilde;es.&nbsp;</p>
                                <p><strong>Link e Hiperlink </strong></p>
                                <p>7. N&oacute;s nos reservamos o direito de registrar solicita&ccedil;&otilde;es para que voc&ecirc; remova todos os links ou qualquer link espec&iacute;fico criado por voc&ecirc; que redirecione para o nosso site, e voc&ecirc; aprova a remo&ccedil;&atilde;o imediata desses links para o nosso site, mediante solicita&ccedil;&atilde;o.&nbsp;</p>
                                <p>8. Podemos alterar os termos e condi&ccedil;&otilde;es desses direitos de vincula&ccedil;&atilde;o a qualquer momento. Ao conectar-se continuamente ao nosso site, voc&ecirc; concorda em se comprometer e seguir os termos desta pol&iacute;tica de links.&nbsp;</p>
                                <p>9. Por favor, entre em contato conosco se encontrar algum link em nosso site que seja ofensivo, e poderemos <strong>considerar e analisar</strong> solicita&ccedil;&otilde;es de remo&ccedil;&atilde;o de tais links.</p>
                                <p><strong>Link para conte&uacute;do de terceiros.</strong></p>
                                <p>10. Este site pode conter links para sites ou aplicativos operados por terceiros. N&atilde;o controlamos nenhum desses sites ou aplicativos de terceiros ou o operador de terceiros. Este Site, objeto do presente Termos de Uso n&atilde;o &eacute; respons&aacute;vel e n&atilde;o endossa quaisquer sites ou aplicativos de terceiros ou sua disponibilidade ou conte&uacute;do.&nbsp;TechUm n&atilde;o se responsabiliza pelos an&uacute;ncios contidos no site. Voc&ecirc; concorda em faz&ecirc;-lo por sua pr&oacute;pria conta e risco ao adquirir quaisquer bens e / ou servi&ccedil;os de terceiros. O anunciante &eacute; quem permanece respons&aacute;vel por tais bens e/ou servi&ccedil;os, e se voc&ecirc; tiver alguma d&uacute;vida ou reclama&ccedil;&atilde;o sobre eles, voc&ecirc; deve entrar em contato com o anunciante.</p>
                                <p><strong>Conte&uacute;do do usu&aacute;rio&nbsp;</strong></p>
                                <p>11. Importante salientar que o termo usado &quot;Conte&uacute;do do Usu&aacute;rio&quot; significa qualquer &aacute;udio, v&iacute;deo, texto, imagens ou outro material ou conte&uacute;do que voc&ecirc; decida exibir neste Site. Com rela&ccedil;&atilde;o ao conte&uacute;do do usu&aacute;rio, ao exibi-lo, voc&ecirc; concede ao TechUm uma licen&ccedil;a <strong>n&atilde;o exclusiva, mundial, irrevog&aacute;vel, isenta de royalties e sublicenci&aacute;vel para usar, reproduzir, adaptar, publicar, traduzir e distribuir em qualquer m&iacute;dia.&nbsp;</strong></p>
                                <p>12. O Conte&uacute;do do Usu&aacute;rio deve ser seu e n&atilde;o deve infringir os direitos de terceiros. TechUm reserva-se o direito de remover qualquer parte do seu conte&uacute;do deste site a qualquer momento, sem aviso pr&eacute;vio. TechUm tem permiss&atilde;o para monitorar suas atividades no site e remover qualquer conte&uacute;do do usu&aacute;rio considerado impr&oacute;prio, ofensivo, contr&aacute;rio &agrave;s leis e regulamentos aplic&aacute;veis, ou que cause uma viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es.&nbsp;</p>
                                <p><strong>Pol&iacute;tica de Privacidade </strong></p>
                                <p>13. Ao acessar este Site, informa&ccedil;&otilde;es espec&iacute;ficas sobre o Usu&aacute;rio, como endere&ccedil;os de protocolo de Internet &#40;IP&#41;, navega&ccedil;&atilde;o no site, software do usu&aacute;rio e tempo de navega&ccedil;&atilde;o, juntamente com outras informa&ccedil;&otilde;es semelhantes, ser&atilde;o armazenadas nos servidores da TechUm. As informa&ccedil;&otilde;es sobre suas identidades, como nome, endere&ccedil;o, detalhes de contato, informa&ccedil;&otilde;es de faturamento e outras informa&ccedil;&otilde;es armazenadas neste site, ser&atilde;o estritamente usadas apenas para fins estat&iacute;sticos e n&atilde;o ser&atilde;o publicadas para acesso geral. A TechUm, no entanto, n&atilde;o assume nenhuma responsabilidade pela seguran&ccedil;a dessas informa&ccedil;&otilde;es.&nbsp;</p>
                                <p>14. O site &eacute; fornecido, com todas as responsabilidades e n&atilde;o assume compromissos, representa&ccedil;&otilde;es ou garantias expressas ou impl&iacute;citas de qualquer tipo relacionadas a este site ou ao conte&uacute;do nele contido.&nbsp;</p>
                                <p><strong>Indeniza&ccedil;&atilde;o </strong></p>
                                <p>15. O usu&aacute;rio concorda em indenizar o Site e suas afiliadas em toda a extens&atilde;o, frente &agrave; todas as a&ccedil;&otilde;es, reclama&ccedil;&otilde;es, responsabilidades, perdas, danos, custos, demandas e despesas decorrentes do uso deste Site pelo Usu&aacute;rio, incluindo, sem limita&ccedil;&atilde;o, qualquer reclama&ccedil;&atilde;o relacionada &agrave; viola&ccedil;&atilde;o de qualquer uma das disposi&ccedil;&otilde;es destes Termos e Condi&ccedil;&otilde;es. Se estiver insatisfeito com algum ou todo o conte&uacute;do deste site ou qualquer um ou todos os seus Termos e Condi&ccedil;&otilde;es, o usu&aacute;rio pode interromper o uso deste site.&nbsp;</p>
                                <p>16. Em qualquer momento, os usu&aacute;rios podem interromper o uso do Site para isso, no site, est&atilde;o dispon&iacute;veis as orienta&ccedil;&otilde;es necess&aacute;rias. Caso ainda fique algum d&uacute;vida, n&atilde;o hesite em enviar um e-mail para contato@techum.com .&nbsp;</p>
                                <p>17. N&oacute;s nos reservamos o direito e crit&eacute;rio exclusivo de, e sem aviso pr&eacute;vio ou responsabilidade, negar o acesso e uso do site &#40;incluindo o bloqueio de endere&ccedil;os IP espec&iacute;ficos&#41; a qualquer usu&aacute;rio por qualquer motivo, incluindo, mas n&atilde;o se limitando &agrave; viola&ccedil;&atilde;o de qualquer representa&ccedil;&atilde;o, garantia ou acordo nestes Termos ou em qualquer lei ou regulamento aplic&aacute;vel.</p>
                                <p><strong>Disposi&ccedil;&otilde;es Gerais </strong></p>
                                <p>18. Os Termos e Condi&ccedil;&otilde;es deste site ser&atilde;o regidos e interpretados de acordo com as leis do pa&iacute;s ou estado em que o Site opera. Voc&ecirc;, por meio deste, se submete incondicionalmente &agrave; jurisdi&ccedil;&atilde;o n&atilde;o exclusiva dos tribunais localizados no Brasil para a resolu&ccedil;&atilde;o de quaisquer disputas.&nbsp;</p>
                                <p>19. Este Site reserva-se o direito de revisar estes Termos a qualquer momento conforme julgar adequado. Por isso &eacute; fundamental que os seus usu&aacute;rios estejam atentos &agrave; essas altera&ccedil;&otilde;es.&nbsp;</p>
                                <p>20. O Site reserva-se o direito de ceder, transferir e subcontratar seus direitos e/ou obriga&ccedil;&otilde;es sob este Acordo sem qualquer notifica&ccedil;&atilde;o ou consentimento pr&eacute;vio necess&aacute;rio. Os usu&aacute;rios n&atilde;o ter&atilde;o permiss&atilde;o para atribuir, transferir ou subcontratar qualquer um de seus direitos e/ou obriga&ccedil;&otilde;es sob estes Termos. Al&eacute;m disso, uma pessoa que n&atilde;o seja parte destes Termos e Condi&ccedil;&otilde;es n&atilde;o ter&aacute; o direito de fazer cumprir qualquer disposi&ccedil;&atilde;o neles contida.&nbsp;</p>
                                <p>21. Estes Termos e Condi&ccedil;&otilde;es, incluindo quaisquer avisos legais e isen&ccedil;&otilde;es de responsabilidade neste site, constituem o acordo completo entre o Site e voc&ecirc; em rela&ccedil;&atilde;o ao uso deste site. Em &uacute;ltima an&aacute;lise, este Acordo substitui todos os acordos e entendimentos anteriores relativos ao mesmo.&nbsp;</p>
                                <p>22. Qualquer d&uacute;vida, entre em contato por meio do endere&ccedil;o de e-mail: contato@techum.com.</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <form id="form-termo-politica" data-form="TermoPerfil">
                            <Form.Group className="mb-3" controlId="termoAceiteCheckbox">
                                <Form.Check id="termoAceiteCheckbox" required type="checkbox" feedback="Você deve concordar com temo antes de prosseguir" feedbackType="invalid" label="Concordo com os termos acima" />
                            </Form.Group>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-3 centralized">
                                    <button type="reset" className="btn btn-reset" id="btn-download-termo" data-campo="submitForm" onClick={downloadTermo}>Download Termo</button>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 centralized">
                                    <button type="submit" className="btn btn-primary btn-submit-termo" id="btn-aceite-termo" data-campo="submitForm" onClick={submitAprovacaoTermo}>Enviar</button>
                                </div>
                            </div>
                            <div className="col-12 btn-cadastro-box margin-top">
                                <div className="login-esqueceu-senha btn-cadastro">
                                    <p>Deseja Sair? <span onClick={modalLogOut} className="esqueceu-senha-clique">Log-Out</span></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>   
            )}
        </div>
    )

}

export default TermoEPolitica