import { useState } from "react";
import "./PerformanceContratos.css"
import { convertTextoToData } from "../../paginas/Admin/convertData";
import GraficoLimpo from "../GraficoLimpo";

function PerformanceTrack(props) {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // COLETA A BASE GERAL
    var baseTotal = JSON.parse(JSON.stringify(props.base));

    // DEFINE OS ARRAYS DE BASES DE DADOS
    var basePerformance = [] // DEFINE O ARRAY DE TRABALHO PARA CADA CLIENTE
    var baseReceitaSoftware = [] // DEFINE O ARRAY QUE SIMULA A RECEITA PELO SOFTWARE
    var dadosGraficoBonus = [] 

    // VARIÁVEIS DE PERFORMANCE
    var performanceEsperada = 0.03
    var taxaPerformance = 0.2

    // VARRE CADA CLIENTE
    for (let i = 0; i < baseTotal.length; i++){

        // DETERMINA AS VARIÁVEIS DE ENTRADA
        var baseCliente = baseTotal[i]
        var chaveCliente = baseTotal[i]["chave"]
        
        // CRIA UM ARRAY INICIAL
        basePerformance[i] = []

        // POPULA O ARRAY DE PERFORMANCE INDIVIDUAL DOS CLIENTES ADEQUADAMENTE
        for (const key in baseCliente){
            if(key !== "chave"){
                // CONSTRÓI A BASE DE PERFORMANCE DOS CLIENTES INDIVIDUALMENTE
                basePerformance[i].push({
                    // AINDA FALTARIA ADICIONAR A DATA DE INÍCIO DO CONTRATO
                    // AINDA FALTARIA ADICIONAR A DATA DE FIM DO CONTRATO
                    nome: baseCliente[key]["Cliente"],
                    chave:chaveCliente,
                    data: convertTextoToData(key),
                    notional: baseCliente[key]["Notional (R$)"],
                    premiosEsperados: performanceEsperada * baseCliente[key]["Notional (R$)"],
                    premios: baseCliente[key]["Resultado das Opcoes"],
                    premioPercentil: baseCliente[key]["Resultado das Opcoes"] / baseCliente[key]["Notional (R$)"],
                    saldo: baseCliente[key]["Resultado das Opcoes"] - (performanceEsperada * baseCliente[key]["Notional (R$)"]),
                    provisaoOpcoes: baseCliente[key]["Valor em Opcoes"],
                    contratoInicio: baseCliente[key]["Inicio Contrato"],
                    contratoFim: baseCliente[key]["Fim de Contrato"],
                    valorTeorico: baseCliente[key]["Valor Teorico"],
                    valorCobrado: baseCliente[key]["Valor Cobrado"],
                    valorPago: baseCliente[key]["Valor Pago"],
                })
            }
        }
    }

    // VARIÁVEIS DE SOMA PARA O BRIDGE ACUMULADO DE TODOS OS CLIENTES JUNTOS
    var premiosTotaisDevidos = 0
    var premiosTotaisPagos = 0
    var provisoesTotais = 0

    // LOOP NA BASE DOS CLIENTES
    for (let i = 0; i < basePerformance.length; i++){

        // DEFINE A BASE DO CLIENTE
        var baseClienteCopia = JSON.parse(JSON.stringify(basePerformance[i]))
        var baseClienteEmContrato = []
        // NA BASE DE UM ÚNICO CLIENTE
        // RANKEIA POR TEMPO
        baseClienteCopia.sort((a, b) => new Date(a.data) - new Date(b.data));

        // NA DATA MAIS ATUAL PEGA A REFERÊNCIA DE
        // DATA DE INÍCIO DO CONTRATO
        var dataInicioContrato = convertTextoToData(baseClienteCopia[baseClienteCopia.length-1]["contratoInicio"])
        // DATA DE FIM DO CONTRATO
        var dataFimContrato = convertTextoToData(baseClienteCopia[baseClienteCopia.length-1]["contratoFim"])
        // EXTENSÃO DO CONTRATO
        var extensãoContrato = parseInt((dataFimContrato - dataInicioContrato)/2629800000)

        // LOOP DENTRO DA BASE CLIENTE RANKEADA
        // ELIMINA AS DATAS QUE NÃO ESTÃO DENTROS DO PERÍODO ESTABELECIDO
        for (let j = 0; j < baseClienteCopia.length; j++){
            if(new Date(baseClienteCopia[j]["data"]) > dataInicioContrato && new Date(baseClienteCopia[j]["data"]) < dataFimContrato){
                baseClienteEmContrato.push(baseClienteCopia[j])
            }
        }

        // VERIFICA SE A BASE DE INTERVALO DE CONTRATO ESTÁ RETORNANDO VAZIA POR ALGUM MOTIVO
        // TALVEZ O FILTRO DE DATAS TENHA ESPECIFICADO UM PERÍODO EM QUE NENHUM CONTRATO ESTAVA ATIVO
        if(baseClienteEmContrato.length===0){
            // RETORNA UM JSON COM A FRASE DE QUE NÃO HAVIAM CONTRATOS ATIVOS
            return(<div className="contratovazio">Sem contrato ativo no período</div>)
        }

        // COM A BASE LIMPA, CALCULA:
        // NÚMERO DE MESES JÁ CORRIDOS
        var numeroPeriodosCorridos = baseClienteEmContrato.length
        var numeroPeriodosACorrer = extensãoContrato - numeroPeriodosCorridos
        // VARIÁVEIS ACUMULADAS DO CLIENTE
        var saldoTotal = 0 // SALDO TOTAL DO CLIENTE
        var somaPercentuais = 0 // MÉDIA PERNCETUAL DE PRÊMIOS
        var provisoesCliente = 0 // VARIÁVEL DA PROVISÃO DAQUELE CLIENTE

        // ACUMULADOS
        for (let j = 0; j < baseClienteEmContrato.length; j++){
            somaPercentuais = somaPercentuais + baseClienteEmContrato[j]["premioPercentil"]
            // VARIÁVEIS ACUMULADAS PARA TODOS OS CLIENTES
            premiosTotaisDevidos = premiosTotaisDevidos + baseClienteEmContrato[j]["premios"]*taxaPerformance
            premiosTotaisPagos = premiosTotaisPagos + baseClienteEmContrato[j]["valorPago"]

            // VARIÁVEIS ACUMULADAS SOMENTE DO CLIENTE ESPECÍFICO
            saldoTotal = saldoTotal + baseClienteEmContrato[j]["premios"]*taxaPerformance - baseClienteEmContrato[j]["valorPago"]
            provisoesCliente = baseClienteEmContrato[j]["provisaoOpcoes"]*taxaPerformance
        }

        // SOMA AS PROVISÕES DOS CLIENTES
        provisoesTotais = provisoesTotais + provisoesCliente

        var mediaPercentuais = somaPercentuais / numeroPeriodosCorridos
        // MÉDIA TARGET A ATINGIR
        var mediaTarget = ((extensãoContrato*performanceEsperada) - (mediaPercentuais*numeroPeriodosCorridos))/numeroPeriodosACorrer
        // VALOR EM PROVISÃO
        let RSLocale = Intl.NumberFormat('pt-BR');
        var provisaoOpcoesText = RSLocale.format(parseFloat(baseClienteEmContrato[(baseClienteEmContrato.length-1)]["provisaoOpcoes"]*taxaPerformance)) // OQUE TERÍAMOS QUE PAGAR SE FECHASEMOS HOJE A CONTA
        var provisaoOpcoesValor= baseClienteEmContrato[(baseClienteEmContrato.length-1)]["provisaoOpcoes"]*taxaPerformance

        // VARIÁVEIS DE IMPRESSÃO QUE SÃO OCULTAS DEPENDENDO DO TAMANHO DA BARRA
        var mediaRealizadaText = ""
        var targetToGoText = ""
        if(numeroPeriodosCorridos===0){
            targetToGoText = parseFloat(mediaTarget*100).toFixed(1) + "%"
        }else if(numeroPeriodosCorridos===extensãoContrato){
            mediaRealizadaText = parseFloat(mediaPercentuais*100).toFixed(1) + "%"
        }else{
            mediaRealizadaText = parseFloat(mediaPercentuais*100).toFixed(1) + "%"
            targetToGoText = parseFloat(mediaTarget*100).toFixed(1) + "%"
        }

        // CRIA UMA VARIÁVEL MAIS SIMPLES PARA O NOME DO CLIENTE
        var nomeArray = baseClienteCopia[baseClienteCopia.length-1]["nome"].split(' ')

        // CRIA A VARIÁVEL DE SALDO PARA CÁLCULO DE PERCENTUAL DE CADA CLIENTE
        var saldoParaPercentual = 0
        if(saldoTotal<0){saldoParaPercentual=0}else{saldoParaPercentual=saldoTotal}

        // DEPENDENDO DO PERCENTUAL DO SALDO UMA COR DIFERENTE SERÁ APLICADA NO LAYOUT
        var corTema = ""
        var backgroundTema = ""
        var saldoPercentual = (-1)*saldoParaPercentual/provisaoOpcoesValor*100
        if((saldoPercentual)<10){
            corTema = "rgb(193,51,3)"
            backgroundTema = "linear-gradient(to bottom, rgb(193,51,3) , rgb(111,30,2) )"
        }else if((saldoPercentual)<20){
            corTema = "rgb(255,191,170)"
            backgroundTema = "linear-gradient(to bottom, rgb(255,191,170) , rgb(212, 145, 123) )"
        }else if((saldoPercentual)<40){
            corTema = "rgb(102,205,170)"
            backgroundTema = "linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) )"
        }else{
            corTema = "rgb(0,139,139)"
            backgroundTema = "linear-gradient(to bottom, rgb(0,139,139) , rgb(0,94,94) )"
        }

        // CRIA O ARRAY DE IMPRESSÃO
        baseReceitaSoftware.push({
            dataInicioContrato:dataInicioContrato,
            dataFimContrato:dataFimContrato,
            nome:nomeArray[0] + " " + nomeArray[nomeArray.length-1],
            performanceSizeBar: (numeroPeriodosCorridos/(numeroPeriodosCorridos+numeroPeriodosACorrer)) * 100,
            performanceBarComplementar: 100 - (numeroPeriodosCorridos/(numeroPeriodosCorridos+numeroPeriodosACorrer)) * 100,
            mesesCorridos: numeroPeriodosCorridos,
            mediaRealizada: mediaPercentuais,
            mediaRealizadaText: mediaRealizadaText,
            targetToGoText: targetToGoText,
            targetToGo:mediaTarget,
            saldo:saldoTotal,
            saldoTexto: RSLocale.format(saldoTotal.toFixed(0)),
            saldoPercentual: saldoPercentual,
            saldoParaPercentual: saldoParaPercentual,
            corTema:corTema,
            backgroundTema:backgroundTema,
            provisaoOpcoes: provisaoOpcoesText,
            provisaoOpcoesValor: provisaoOpcoesValor
        })

    }

    // RANKEAR DO CLIENTE EM PIOR SITUAÇÃO PRO MELHOR
    baseReceitaSoftware.sort((a, b) => a.saldoPercentual - b.saldoPercentual);

    // CRIA O ARRAY DE IMPRESSÃO DO GRÁFICO DE BRIDGE

    const dadosBridge = [
        { label: "Comissões devidas",  y: premiosTotaisDevidos },
        { label: "Comissões arrecadadas", y: premiosTotaisPagos*-1 },
        { label: "Bônus Gross", isIntermediateSum: true, color: "rgba(200,200,200,1.0)"},
        { label: "Provisões",  y: provisoesTotais },
        { label: "Bônus Net", isCumulativeSum: true, color: "rgba(200,200,200,1.0)" }
    ]

    const optionsBridgePerformance = {
        animationEnabled: true,
        height:300,
        backgroundColor: "transparent",
        legend:{
            fontColor: "rgb(150,150,150)",
            fontFamily: fonte,
        },
        title:{
            text: "Flutuação do Petrimônio (R$)",
            padding:20,
            fontSize:0,
            fontColor: "rgba(102,205,170,1.0)",
            fontFamily: fonte
        },
        toolTip:{
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');
                var content = "<table/>"  ;              
				content += "<th style='padding-right:10px'><span style='color: gray '>" + e.entries[0].dataPoint.label + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[0].dataPoint.y)) + "</span></th>";
				content += "<tr/>";
                content += "</table>"
				return content;
            }
        },
        axisY: {
            title: "",
            gridThickness: 0,
            labelFontSize:0.0000000000001,
            lineThickness:0,
            tickThickness: 0,
            labelFontFamily: fonte,
            stripLines: [{
                value: 0,
                label: "Zero",
                lineDashType: "dash",

            }]
        },
        axisX:{
            gridThickness: 0,
            margin: 10,
            tickThickness: 0,
            labelFontColor: "rgba(150,150,150,1.0)",
            lineColor: "rgba(150,150,150,1.0)",
            lintThickness: 0.5,
            labelFontSize:12,
            interval:1,
            labelFontFamily: fonte,
        },
        data: [{
            type: "waterfall",
            color: "rgba(200,200,200,0,7)",
            risingColor: "rgba(102,205,170,.7)",
            fallingColor: "rgba(255,127,80,.4)",
            lineThickness:1,
            lineColor:"rgba(200,200,200,1)",
            indexLabel: "{y}",
            indexLabelFontSize: 12,
            indexLabelPlacement: "outside",
            indexLabelFontColor: "rgb(150,150,150)",
            indexLabelFontFamily: fonte,
            dataPoints: dadosBridge,
            indexLabelFormatter:function (e) {               
                    var content = parseInt(e.dataPoint.y/1000) + " k";
                    return content;
                }            
        }]
    }

    // LOOP NA BASE PARA CONSTRUÇÃO DO GRÁFICO DE BÔNUS PROJETADOS
    var saldoTotal = 0
    for (let i = 0; i < baseReceitaSoftware.length; i++){

        // SE FOR O PRIMEIRO DADO ADICIONA NA BASE
        if(i===0){
            dadosGraficoBonus.push({
                x:baseReceitaSoftware[i].dataFimContrato,
                y:parseFloat(baseReceitaSoftware[i].saldo)
            })
        // SE NÃO FOR O PRIMEIRO DADO, VERIFICA SE A DATA DE TÉRMINO DO CONTRATO É EQUIVALENTE A DE ALGUM OUTRO CLIENTE
        }else{

            var temNaBase = false
            // LOOP PARA VERIFICAR SE A DATA DA BASE JÁ EXISTE NO ESCOPO DO GRÁFICO
            for (let j = 0; j < dadosGraficoBonus.length; j++){
                // SE A DATA NA BASE BATER COM ALGUMA DATA QUE JÁ FOI ADICIONADA
                if(baseReceitaSoftware[i].dataFimContrato = dadosGraficoBonus[j].x){
                    var saldoAnterior = parseFloat(dadosGraficoBonus[j].y)*1
                    dadosGraficoBonus[j].y = saldoAnterior + parseFloat(baseReceitaSoftware[i].saldo)
                    temNaBase = true
                }
            }

            // SE A DATA NA BASE AINDA NÃO TIVER SIDO ADICIONADA NO ESCOPO DO GRÁFICO, ADICIONA
            if(temNaBase===false){
                dadosGraficoBonus.push({
                    x:baseReceitaSoftware[i].dataFimContrato,
                    y:baseReceitaSoftware[i].saldo
                })
            }
        }
    }

    // VARIÁVEL DE IMPRESSÃO DOS BÔNUS A SEREM RECEBIDOS
    const optionsCurvaBonus = {
		animationEnabled: true,
		backgroundColor: "transparent",
        height:300,
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign:"top",
            fontFamily: fonte,
            fontWeight: "normal"
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
            
        },
		title:{
			text: "Projeção de Bônus (R$)",
			padding:0, //20
			fontSize:0, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lintThickness: 0.5,
            intervalType: "month",
            labelFontFamily: fonte,
            labelFontSize:10,
            valueFormatString:"MMM YYYY"
		},
		data: [{
			type: "line",
            lineDashType: "dot",
            name: "Projeção Bônus",
			showInLegend: true,
            indexLabel: "{y}",
            indexLabelFontColor: "rgb(150,150,150)",
            indexLabelFontFamily: fonte,
            indexLabelFontStyle: "bold",
			legendText: "Projeção Bônus",
			color: "rgba(102,205,170,.7)",
			markerSize: 10,
            labelFontSize: 10,
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$ #,###,.K",
			dataPoints: dadosGraficoBonus
		}, ]
    }

    // JSX
    return (
        <div className="performanceContainer">
            <div className="performanceGraficoReceita">
                <div className="bonus-resumo">Bônus cobre <em>{parseInt(((premiosTotaisPagos-premiosTotaisDevidos)/provisoesTotais)*100)}%</em> das provisões</div>
                <GraficoLimpo id="graf-proj-bonus" options={optionsBridgePerformance}/>
            </div>
            {baseReceitaSoftware.map(cliente =>
                <div key={"chavePerformance" +cliente.nome} className="performanceBox">
                    <div className="performanceClientName" style={{color:cliente.corTema}}>
                        <div className="nome">{cliente.nome}</div>
                        <div>{cliente.mesesCorridos} meses corridos</div>
                    </div>
                    <div className="performanceScope">
                        <div className="performanceBar" style={{width: cliente.performanceSizeBar + "%", backgroundImage:cliente.backgroundTema}}>
                            <div className="mediaRealizado">{cliente.mediaRealizadaText}</div>
                        </div>
                        <div className="performanceBarComplementar" style={{width: cliente.performanceBarComplementar + "%"}}>
                            <div className="targetToGo">{cliente.targetToGoText}</div>
                        </div>
                    </div>
                    <div className="saldoACompensar">Saldo / Provisões Op : <b style={{color:cliente.corTema}}>R$ {cliente.saldoTexto} / {cliente.provisaoOpcoes} &#40;{parseInt(cliente.saldoParaPercentual/cliente.provisaoOpcoesValor*100*(-1))}%&#41;</b></div>
                </div>
            )}
        </div>
    );
}

export default PerformanceTrack

