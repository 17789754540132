import Inputmask from "inputmask"

function validaItem(item){

    /* SE FOR REQUIRED, AVALIA SE ESTÁ VAZIO */
    if(item.required){

        if(!item.value){
            item.classList.add("is-invalid");
            item.classList.remove("is-valid");
            return ("required false")
        }
    }

    /* AVALIA O TIPO DO INPUT*/
    if(item.validity.valid == false){
        item.classList.add("is-invalid");
        item.classList.remove("is-valid");
        return ("type false")
    }else{

    }   

    item.classList.add("is-valid");
    item.classList.remove("is-invalid");
    return ("ok")

    /* AVALIA SE O INPUT CORRESPONDE A MÁSCARA */
    // CÓDIGO ABAIXO ESTÁ SEMPRE RETORNANDO FALSO 
    /*if(item.dataset.inputmask == ""){

    } else {
        console.log(item.dataset.inputmask)
        if(Inputmask(item).isComplete() == false){
            item.classList.add("is-invalid");
            item.classList.remove("is-valid");
            return false
        }else{

        }

    }*/
}

export default validaItem;