import { Sidebar, Menu, MenuItem, SubMenu, SidebarHeader } from 'react-pro-sidebar';
import styles from './Navbar.module.css'
import { useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useState, useRef} from 'react';

const NavbarCustom = (props) => {

	// DECLARAÇÃO DE VARIÁVEIS
	const local = useLocation()

	// LÓGICA PARA DETERMINAÇÃO DAS VARIÁVEIS DE EXIBIÇÃO
	var exibeLogado = ""
	var exibeAdmin = ""
	var exibeTermoEPolitica = ""
    var nome = ""

    // VARIÁVEIS DE ESTADO
    const [collapsed, setCollapsed] = useState(false);
    const [subMenusOpen, setSubMenusOpen] = useState({home:false, admin:false})

    // LOGIC TO HIDE ITEMS ON THE MENU
	if (props.user) {
		exibeLogado = "sim"
        nome = props.user.nome + " " + props.user.sobrenome
		if(props.user.termoEPolitica === false){
			exibeTermoEPolitica = "não"
		}else{
			exibeTermoEPolitica = "sim"
			if (props.user.email === "techumltda@gmail.com") {
				exibeAdmin = "sim"
			}else{
				exibeAdmin = "não"
			}
		}
	}else{
		exibeLogado = "não"
		exibeTermoEPolitica = "sim"
	}

    // LOGIC OF CLOSING SUBMENUS
    function openCloseSubMenus(pagina){
        if(pagina==="home"){
            if(subMenusOpen.home === false){
                setSubMenusOpen({home:true, admin:false})
            }else{
                setSubMenusOpen({home:false, admin:false})
            }
        }else if (pagina==="admin"){
            if(subMenusOpen.admin === false){
                setSubMenusOpen({home:false, admin:true})
            }else{
                setSubMenusOpen({home:false, admin:false})
            }
        }
    }

    return(
        <Sidebar 
            className={`${styles.sidebar}`}
            width="210px"
            breakPoint="lg"
            backgroundColor='rgba(240, 244, 246,1)'
            collapsed={collapsed}
        >
            <div className={`${styles.collapse}`}>
                <span className={`${styles.iconNav} ${styles.collapseBottom} material-symbols-outlined icon-title`} onClick={(e) => collapsed == true ? setCollapsed(false) : setCollapsed(true)}>{collapsed == true ? "expand_content"  : "collapse_content"}</span>
            </div>
            <div className={`${styles.sidebarheader}`}>
                <span className={`${styles.sidebarheadertech} ${collapsed == true ? styles.hide : ""}`}><em>Tech</em>|Um</span>
                <span className={`${styles.sidebarheaderuser} ${collapsed == true ? styles.hide : ""}`}>{nome}</span>
            </div>
            <Menu>
                <div className={`${styles.sidebarsubtitulo} ${collapsed == true ? styles.hide : ""}`}>My App</div>
                <LinkContainer to ="/perfil">
                    <MenuItem 
                        icon={<span className={`${styles.iconNav} material-symbols-outlined icon-title`}>account_circle</span>}
                        className={`${styles.menuItem}`}
                    > 
                        Perfil
                    </MenuItem>
                </LinkContainer>
                <LinkContainer to ="/">
                    <SubMenu 
                        label="Home"
                        icon={<span className={`${styles.iconNav} material-symbols-outlined icon-title`}>home</span>}
                        className={`${styles.menuItem}`}
                        open={subMenusOpen.home}
                        onClick={(e)=> openCloseSubMenus("home")}
                    >
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoPatrimonio.current.scrollIntoView({behavior: "smooth",block: "start"})}> Evolução Patrimônio </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoRentabilidade.current.scrollIntoView({behavior: "smooth",block: "start"})}> Rentabilidade </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoResultadosMes.current.scrollIntoView({behavior: "smooth",block: "start"})}> Resultados do mês </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoIndices.current.scrollIntoView({behavior: "smooth",block: "start"})}> Índices </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoConciliacao.current.scrollIntoView({behavior: "smooth",block: "start"})}> Conciliação </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoCarteira.current.scrollIntoView({behavior: "smooth",block: "start"})}> Carteira </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoMeusPremios.current.scrollIntoView({behavior: "smooth",block: "start"})}> Prêmios </MenuItem>
                        <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoMeuContato.current.scrollIntoView({behavior: "smooth",block: "start"})}> Meu Contato </MenuItem>
                    </SubMenu>
                </LinkContainer>
                <div className={`${exibeAdmin === "não" ? styles.hide : ""} `}>   
                    <div className={`${styles.sidebarsubtitulo} ${collapsed == true ? styles.hide : ""}`}>Admnistrador</div>
                    <LinkContainer to ="/admin">
                        <SubMenu 
                            label="Admin"
                            icon={<span className={`${styles.iconNav} material-symbols-outlined icon-title`}>monitoring</span>}
                            className={`${styles.menuItem}`}
                            open={subMenusOpen.admin}
                            onClick={(e)=> openCloseSubMenus("admin")}
                        >
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoNossosClientes.current.scrollIntoView({behavior: "smooth",block: "start"})}> Nossos Clientes </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoPatrimonio.current.scrollIntoView({behavior: "smooth",block: "start"})}> Evolução Patrimônio </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoRentabilidade.current.scrollIntoView({behavior: "smooth",block: "start"})}> Rentabilidade </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoResultadosMes.current.scrollIntoView({behavior: "smooth",block: "start"})}> Resultados do mês </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoIndices.current.scrollIntoView({behavior: "smooth",block: "start"})}> Índices </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoConciliacao.current.scrollIntoView({behavior: "smooth",block: "start"})}> Conciliação </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoCarteira.current.scrollIntoView({behavior: "smooth",block: "start"})}> Carteira </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoMeusPremios.current.scrollIntoView({behavior: "smooth",block: "start"})}> Prêmios </MenuItem>
                            <MenuItem className={`${styles.menuItem}`} onClick={() =>props.secaoGestaoPerformance.current.scrollIntoView({behavior: "smooth",block: "start"})}> Gestão de Performance </MenuItem>
                        </SubMenu>
                    </LinkContainer>
                    <LinkContainer to ="/opcoesOp">
                        <MenuItem 
                            icon={<span className={`${styles.iconNav} material-symbols-outlined icon-title`}>dashboard</span>}
                            className={`${styles.menuItem}`}
                        > 
                            Operação Opções
                        </MenuItem>
                    </LinkContainer>
                    <LinkContainer to ="/gestaoRisco">
                        <MenuItem 
                            icon={<span className={`${styles.iconNav} material-symbols-outlined icon-title`}>admin_panel_settings</span>}
                            className={`${styles.menuItem}`}
                        > 
                            Gestão de Risco
                        </MenuItem>
                    </LinkContainer>
                </div>
            </Menu>
        </Sidebar>
    )
}

export default NavbarCustom