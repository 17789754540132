import { useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import styles from './HeaderLink.module.css';
import { LinkContainer } from 'react-router-bootstrap';

export default function MenuLink({ children, to, exibeLogado, conteudoProtegido, exibeAdmin, conteudoAdmin, exibeTermoEPolitica}) {
    
    // DECLARAÇÃO DAS VARIÁVEIS
    const localizacao = useLocation();

        return (
            <LinkContainer to ={to}>
                <Nav.Link className={`
                    ${localizacao.pathname === to ? styles.linkDestacado : ""}
                    ${exibeLogado === conteudoProtegido ? "" : styles.linkOculto}
                    ${exibeAdmin === "não" && conteudoAdmin === "sim" || exibeTermoEPolitica === "não"  ? styles.linkOculto : ""}
                `}>
                    {children}
                </Nav.Link>
            </LinkContainer>
        )
}