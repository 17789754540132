import OpcoesOpItem from "../../componentes/OpcoesOpItem"
import "./OpcoesOp.css"
import PreLoader from "../../componentes/PreLoader"
import LoaderOpcoes from "./loader";
import React, { useState, useEffect, useRef } from 'react';
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../config/firebase';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { getFirestore, collection, query, doc, onSnapshot, setDoc } from "firebase/firestore";
import FiltrosOpcoesOp from "../../componentes/FiltrosOpcoesOp";
import { convertTextoToData } from "../Admin/convertData";

const OpcoesOp = (props) => {

    // VARIÁVEL FIRESTORE
    const db = getFirestore(app);

    // VARIÁVEL DE BASE DE DADOS EM FORMATO ARRAY
    var baseOpcoesArrayTemp = []

    // VARIÁVEL QUE INDICA SE O MODAL ESTÁ ABERTO PARA QUE LOADINGS NÃO ACONTEÇAM 
    const modalAberto = useRef(false)

    // VARIÁVEIS DE ESTADO
    const [baseHistoricoAcoes, setBaseHistoricoAcoes] = useState(props.baseHistoricoAcoes) // VARIÁVEL DE ESTADO QUE ARMAZENA HISTÓRICO DA AÇÃO
    const [loading, setLoading] = useState(true); // VARIÁVEL DE ESTADO DE LOADING
    const [loadingOp, setLoadingOp] = useState(false);
    const [baseCompletaOpcoes, setBaseCompletaOpcoes] = useState([])
    const [shortList, setShortList] = useState([]) // VARIÁVEL DE ESTADO DA SHORTLIST
    const [baseOpcoesArray, setBaseOpcoes] = useState([]) // VARIÁVEL DE ESTADO DA BASE DE OPÇÕES
    const [baseShortList, setBaseShortList] = useState([]) // VARIÁVEL DE ESTADO DA SHORTLIST COMPLETA
    const [dataHora, setDataHora] = useState(null) // DATA E HORA DE ATUALIZAÇÃO DA BASE

    // FUNÇÃO QUE FAZ REQUESTO DO JSON 
    function makeRequest (method, url) {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.open(method, url);
          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else {
              reject({
                status: xhr.status,
                statusText: xhr.statusText
              });
            }
          };
          xhr.onerror = function () {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
          };
          xhr.send();
        });
    }
    
    // EXECUTA AO CARREGAR A PÁGINA SOMENTE NA PRIMEIRA VEZ
    useEffect(()=>{

        var cont = 1

        // BAIXA BASE JSON COM HISTÓRICO DE OPÇÕES E DEPOIS ADICIONA UM LISTENER NO FIREBASE PARA CAPTURAR A SHORTLIST
        const storage = getStorage(app);
        const pathReference = ref(storage, 'opcoesOp/ListaOpcoesStorage.json');
        getDownloadURL(ref(storage, pathReference)).then((url) => {
            makeRequest('GET', url)
            .then(function (datums) {

                var baseOpcoes = JSON.parse(datums)

                // CRIA A BASE DE IMPRESSÃO
                for (let ticker in baseOpcoes) {
                    // AJUSTA A DATA DE VENCIMENTO
                    var vencimento = convertTextoToData(baseOpcoes[ticker]["Vencimento"])
                    var ranking = String(baseOpcoes[ticker]["Ranking de escolhas"])
                    
                    // ATUALIZA A DATA E HORA DA ATUALIZAÇÃO
                    if(baseOpcoes[ticker]["Delta"] !=0 && baseOpcoes[ticker]["Delta"] != null){
                        if(cont===1){
                            //console.log(baseOpcoes[ticker])
                            setDataHora(baseOpcoes[ticker]["DataDeRegistro"])
                            cont=2
                        }
                    }

                    // ADICIONA OS DADOS NO ARRAY DE IMPRESSÃO
                    baseOpcoesArrayTemp.push({
                        DataDeRegistro: baseOpcoes[ticker]["DataDeRegistro"],
                        ticker: ticker,
                        strike: baseOpcoes[ticker]["Strike"],
                        ultimo: baseOpcoes[ticker]["Último"],
                        acao: baseOpcoes[ticker]["Nome"],
                        bandaInferior: baseOpcoes[ticker]["Banda Inferior"],
                        bandaSuperior: baseOpcoes[ticker]["Banda Superior"],
                        precoAcao: baseOpcoes[ticker]["Preço da Ação"],
                        precoAlvo: baseOpcoes[ticker]["Preço-alvo"],
                        mediaMovel20: baseOpcoes[ticker]["Média Móvel de 20"],
                        tendencia: baseOpcoes[ticker]["Tendencia"],
                        maiorVenc: baseOpcoes[ticker]["Maior Preço no vencimento"],
                        menorVenc: baseOpcoes[ticker]["Menor Preço no vencimento"],
                        vencimento: vencimento,
                        tipo: baseOpcoes[ticker]["tipo"],
                        volImplicita: baseOpcoes[ticker]["Vol. Impl. (%)"],
                        ivRank:baseOpcoes[ticker]["IVRank Geral"],
                        ivRankCalls: baseOpcoes[ticker]["IVRank Calls"],
                        ivRankPuts: baseOpcoes[ticker]["IVRank Puts"],
                        ivRankMedioCalls: baseOpcoes[ticker]["IVRank Médio Calls"],
                        ivRankMedioPuts:baseOpcoes[ticker]["IVRank Médio Puts"],
                        ivRankCallsPercentil: baseOpcoes[ticker]["IV Percentil Calls"],
                        ivRankPutsPercentil: baseOpcoes[ticker]["IV Percentil Puts"],
                        retornoMensal: baseOpcoes[ticker]["Retorno Eq. Mensal (%)"],
                        delta:Math.abs(baseOpcoes[ticker]["Delta"]),
                        beta:baseOpcoes[ticker]["Beta"],
                        gama:baseOpcoes[ticker]["Gamma"],
                        vega: baseOpcoes[ticker]["Vega"],
                        tetaPercentual:baseOpcoes[ticker]["Theta (%)"],
                        tetaValor: baseOpcoes[ticker]["Theta ($)"],
                        ranking: ranking,
                        ifr: baseOpcoes[ticker]["IFR"]
                    })
                }   

                // RETIRA OS ITENS COM RENDIMENTO MAIOR QUE 3%
                for (let i = 0; i < baseOpcoesArrayTemp.length; i++) {
                    var rendimento = baseOpcoesArrayTemp[i]["retornoMensal"]
                    if(rendimento > 0.03){
                        baseOpcoesArrayTemp[i]["retornoMensal"] = 0
                    }
                }

                // RANKEIA DO MAIOR PRO MENOR RANKING
                baseOpcoesArrayTemp.sort((a, b) => b.retornoMensal - a.retornoMensal);
                setBaseCompletaOpcoes(baseOpcoesArrayTemp) 
                setBaseOpcoes(baseOpcoesArrayTemp)
                setLoading(false)

                // ADICIONA UM LISTENER PARA CADA ADIÇÃO NA BASE DE SHORTLIST
                const unsub = onSnapshot(doc(db, "shortListOpcoes", "Lista"), (doc) => {

                    // CARREGA O LOADING OP
                    if(modalAberto.current == false){
                        setLoadingOp(true)
                    }else{}

                    const dadosFirebase = doc.data()["shortList"]

                    // FILTRA AS OPÇÕES DE ACORDO COM A DATA DE ADICIONAMENTO NA BASE
                    if(dadosFirebase.length===0){
                        var shortListFirebaseTemp = dadosFirebase
                    }else{
                        // VARIÁVEL TEMP
                        var shortListFirebaseTemp = []

                        // CAPTURA A DATA ATUAL
                        let agora = new Date()

                        // LOOP NA BASE DO FIREBASE
                        // ATUALIZARÁ AS INFORMAÇÕES QUE VEM DA MESMA
                        for (let i = 0; i < dadosFirebase.length; i++) {
                            
                            // CAPTURA O HORÁRIO EM QUE A INFO FOI ADICIONADA
                            let horario = dadosFirebase[i]["Horario"]*1
                            let dif = (agora - horario)/86400000

                            // CAPTURA O TICKER DA SHORTLIST
                            var tickerShortList = dadosFirebase[i]["ticker"]

                            // OBJETO PARA POPULAR SHORTLIST
                            // A PRINCÍPIO VAI SER IGUAL OQ ESTIVER NO FIREBASE
                            var objetoShortlist = JSON.parse(JSON.stringify(dadosFirebase[i]))

                            // ATUALIZA OS DADOS DA SHORTLIST SEGUNDO NOVO CARREGAMENTO DA BASE
                            for (let j = 0; j < baseOpcoesArrayTemp.length; j++) {
                                if(tickerShortList==baseOpcoesArrayTemp[j]["ticker"]){

                                    // OBJETO VAI SER ATUALIZADO
                                    objetoShortlist = JSON.parse(JSON.stringify(baseOpcoesArrayTemp[j]))

                                    // MANTÉM O HORÁRIO ORIGINAL EM QUE FOI ADICIONADO NA BASE
                                    objetoShortlist["Horario"] = horario
                                }
                            }

                            // SOMENTE ADICIONA OPÇÕES ADICIONADAS NA SHORTLIST HÁ MENOS DE 1 DIA
                            if(dif<1){
                                shortListFirebaseTemp.push(objetoShortlist)
                            }else{

                            }
                        }

                    } 

                    setShortList(shortListFirebaseTemp)
                    
                });

            })
            .catch(function (err) {
                console.error('Erro:', err);
            });
        })

    },[])

    // FUNÇÃO DE DRAG AND DROP
    function handleOnDragEnd(result) {
        // EM CASO DE ERRO DO CARD TER SIDO ARRASTADO PARA UM LUGAR EM QUE ELE NÃO PODE FICAR
        if (!result.destination) return;
        // CAPTURA SE O ATIVO ESTÁ SENDO DESLOCADO DA LISTA PARA SHORTLIST
        const destino = result.destination.droppableId
        const origem = result.source.droppableId

        if(origem==="opcoes" && destino==="shortListOpcoes"){

            // LOADING
            setLoadingOp(true)

            // CAPTURA A HORA EXATA DA TRANSIÇÃO
            let now = Date.now();

            // CAPTURA O ATIVO
            var indexReferência = result.source.index
            var opcaoDeslocada = JSON.parse(JSON.stringify((baseOpcoesArray[indexReferência])))
            var opcaoParaIncluir = opcaoDeslocada
            opcaoParaIncluir["Horario"] = now

            // RETIRA O ATIVO DA BASE DE OPÇÕES
            const items = Array.from(baseOpcoesArray);
            //const items = JSON.parse(JSON.stringify((baseOpcoesArray)))
            const [reorderedItem] = items.splice(result.source.index, 1);
            setBaseOpcoes(items);
            
            // ADICIONA O ATIVO NA SHORTLIST
            const shortListCopy = Array.from(shortList);
            shortListCopy.splice(result.destination.index,0,opcaoParaIncluir)

            setDoc(doc(db, "shortListOpcoes", "Lista"), {
                shortList: shortListCopy
            }).then((ticker)=>{
            });

        }else if(origem==="shortListOpcoes" && destino==="opcoes"){

            // LOADING
            setLoadingOp(true)

            // CAPTURA O ATIVO
            var indexReferência = result.source.index
            var opcaoDeslocada = JSON.parse(JSON.stringify((baseShortList[indexReferência])))
            var opcaoParaIncluir = opcaoDeslocada

            // ADICIONA O ATIVO NA BASE OPÇÕES
            const items = Array.from(baseOpcoesArray);
            items.splice(result.destination.index,0,opcaoParaIncluir)
            setBaseOpcoes(items)
            
            // ATUALIZA A SHORTLIST
            const shortListCopy = Array.from(shortList);
            shortListCopy.splice(result.source.index,1)

            setDoc(doc(db, "shortListOpcoes", "Lista"), {
                shortList: shortListCopy
            }).then((ticker)=>{});


        }else if(origem==="opcoes" && destino==="opcoes"){

            // ATUALIZA O INDEX DOS CARDS DESLOCADOS
            const items = Array.from(baseOpcoesArray);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setBaseOpcoes(items);   

        }else if(origem==="shortListOpcoes" && destino==="shortListOpcoes"){

            // LOADING
            setLoadingOp(true)

            // ATUALIZA O INDEX DOS CARDS DESLOCADOS
            const items = Array.from(shortList);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            
            setDoc(doc(db, "shortListOpcoes", "Lista"), {
                shortList: items
            }).then((ticker)=>{});

        }
    }

    // TODA VEZ QUE A SHORTLIST FOR ALTERADA ESSA FUNÇÃO RODARÁ PARA RERENDERIZAR A BASE CORRETA NO FRONT
    useEffect(()=>{

        if(shortList===undefined){}else{

            setBaseShortList(shortList)

            var listaOpcoesTemp = JSON.parse(JSON.stringify((baseOpcoesArray)))
            // RETIRA DA LISTA DE OPÇÕES OS ITENS QUE ESTÃO NA SHORTLIST
            for (let i = 0; i < shortList.length; i++) {
                // VARRE TODOS OS TICKERS NA BASE COMPLETA
                for (let j = 0; j <listaOpcoesTemp.length; j++) {
                    if(shortList[i]["ticker"]===listaOpcoesTemp[j]["ticker"]){
                        listaOpcoesTemp.splice(j,1)
                        j=j-1
                    }
                }
            }
            setBaseOpcoes(listaOpcoesTemp)

        }

        setLoadingOp(false)

    },[shortList])

    // FUNÇÃO QUE RECEBE OS NOVOS DADOS DE UMA OPÇÃO E ALTERA A SHORTLIST
    const updateShortlist = (opcao) =>{

        // ATUALIZA A SHORTLIST NO FIREBASE
        var baseCompletaShortlist = JSON.parse(JSON.stringify((shortList)))
        var opcaoDadosCopy = JSON.parse(JSON.stringify(opcao))

        if(baseCompletaShortlist===undefined || baseCompletaShortlist.length===0){}else{
            // VARRE A BASE COMPLETA
            for (let i = 0; i < baseCompletaShortlist.length; i++) {
                // ATUALIZA AS VARIÁVEIS NA OPÇÃO CABÍVEL
                if(baseCompletaShortlist[i]["ticker"]===opcaoDadosCopy["ticker"]){
                    baseCompletaShortlist[i]["ultimo"] = opcaoDadosCopy["ultimo"]*1
                    baseCompletaShortlist[i]["delta"] = opcaoDadosCopy["delta"]*1
                    baseCompletaShortlist[i]["retornoMensal"] = opcaoDadosCopy["retornoMensal"]*1
                }
            }  
                        
            setDoc(doc(db, "shortListOpcoes", "Lista"), {
                shortList: baseCompletaShortlist
            }).then((ticker)=>{
            });
            
        }
    }

    // FUNÇÃO QUE DETECTA ALTERAÇÃO NO STATUS DO MODAL (ABERTOU OU FECHADO)
    const statusModal = (status) =>{
        modalAberto.current = status
    }

    // JSX TESTE
    return(
        <main>
            {loading ? (<PreLoader/>) : (
                <div className="telaToda container-lg">
                    <FiltrosOpcoesOp baseCompleta={baseCompletaOpcoes} shortList={shortList} atualizaBaseOpcoes={setBaseOpcoes} />
                    <div className="dataAtualizacao">Hora Atualização: <em>{dataHora}</em></div>
                    <div className="row">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <div className="col-12 col-sm-6 box">
                                <div className="tituloLista">Lista de Ativos</div>
                                {loadingOp ? (<LoaderOpcoes/>) :
                                <Droppable droppableId="opcoes">
                                    {(provided) => (
                                        <ul className="listaOpcoes" {...provided.droppableProps} ref={provided.innerRef}>
                                            {baseOpcoesArray.map((opcao, index) => {
                                                if(index < 100){
                                                    return (
                                                    <Draggable key={opcao.ticker} draggableId={opcao.ticker} index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}><OpcoesOpItem dados={opcao} shortList={false} baseShortList={shortList} alteraShorlist={setShortList} baseHistoricoAcao={baseHistoricoAcoes[opcao.acao]}/></li>
                                                        )}
                                                    </Draggable>
                                                    )
                                                }
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                                }
                            </div>
                            <div className="col-12 col-sm-6 box">
                                <div className="tituloLista">ShortList</div>
                                {loadingOp ? (<LoaderOpcoes/>) :
                                <Droppable droppableId="shortListOpcoes">
                                    {(provided) => (
                                        <ul className="listaOpcoes" {...provided.droppableProps} ref={provided.innerRef}>
                                            {baseShortList.map((opcao, index) => {
                                                if(index < 50){
                                                    return (
                                                    <Draggable key={opcao.ticker} draggableId={opcao.ticker} index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}><OpcoesOpItem abriuModal={statusModal} dados={opcao} alteraDados={updateShortlist} shortList={true} baseHistoricoAcao={baseHistoricoAcoes[opcao.acao]}/></li>
                                                        )}
                                                    </Draggable>
                                                    )
                                                }
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                                }
                            </div>
                        </DragDropContext>
                    </div>
                </div>
            )}
        </main>
    )

}

export default OpcoesOp