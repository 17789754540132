import styles from './MapaOpPorCliente.module.css';

const LinhaTabelaCliente = ({baseOperacoesAcao, cliente,chave, arrayTickers, baseAcoesCallCoberta}) => {
    
    // AJUSTA O NOME DO CLIENTE
    var arr = cliente.split(' ');
    var nome = arr[0]
    var sobreNome = arr[arr.length-1].split('')[0] + "."
    var clienteNome = nome + " " + sobreNome

    // VARIÁVEIS DE IMPRESSÃO
    var totalAcoes = 0
    var saldoAcoesCallCobertas = 0
    var totalCalls = 0
    var pm = 0
    var pa = 0

    if(baseAcoesCallCoberta != undefined){
        if(baseAcoesCallCoberta[chave] != undefined){
            totalAcoes = baseAcoesCallCoberta[chave]["Quantidade"]
            totalCalls = baseAcoesCallCoberta[chave]["TotalVendidoCalls"]
            saldoAcoesCallCobertas = totalAcoes - baseAcoesCallCoberta[chave]["SaldoCallCoberta"]
            if(baseAcoesCallCoberta[chave]["Preco Medio"] != undefined){pm = baseAcoesCallCoberta[chave]["Preco Medio"]}
            if(baseAcoesCallCoberta[chave]["Preco Teto"] != undefined){pa = baseAcoesCallCoberta[chave]["Preco Teto"]}
        }
    }

    // AJUSTA OS FORMATOS
    let RSLocale = Intl.NumberFormat('pt-BR');
    var totalAcoesTexto = RSLocale.format(totalAcoes.toFixed(0))
    var totalCallsTexto = RSLocale.format(totalCalls.toFixed(0))
    var pmtexto = RSLocale.format(pm.toFixed(2))
    var patexto = RSLocale.format(pa.toFixed(2))

    return(
        <tr key={"tabeltickers" + cliente}>
            <td className={`${styles.tableCliente}`}>{clienteNome}</td>
            <td className={`${styles.tableClienteCCoberta}`}>
                <div className={`${styles.tableValorInteiro}`}>
                    <div className={`${styles.tableValorCallCoberta}`}>{totalAcoesTexto}</div>
                    <div className={`${styles.tableValorCallCoberta}`}>| {totalCallsTexto}</div>
                    <div className={`${styles.tableValorCallCoberta}`}>| {pmtexto}</div>
                    <div className={`${styles.tableValorCallCoberta}`}>| {patexto}</div>
                </div>
            </td>
            {arrayTickers.map((inputTicker) =>{

                var valor = 0
                var retorno = 0
                var quantidade = 0
                var warning = ""

                // CAPTURA OS VALORES DEVIDOS
                if(baseOperacoesAcao[inputTicker.ticker] != undefined){
                    if(baseOperacoesAcao[inputTicker.ticker][cliente] != undefined){
                        quantidade = baseOperacoesAcao[inputTicker.ticker][cliente]["quantidade"]
                        retorno = baseOperacoesAcao[inputTicker.ticker][cliente]["retorno"]
                        valor = baseOperacoesAcao[inputTicker.ticker][cliente]["notional"]
                    }
                }

                // WARNING ADJUSTMENT
                // SE A QUANTIDADE FOR 0, O ITEM CERTAMENTE ESTÁ OK
                if(quantidade===0){
                    warning = "itemOk"
                }else{
                    warning = inputTicker.warning
                }

                // AJUSTA OS FORMATOS
                let RSLocale = Intl.NumberFormat('pt-BR');
                var valorTexto = RSLocale.format(valor.toFixed(0))
                var quantidadeTexto = RSLocale.format(quantidade.toFixed(0))
                var retornoTexto = (retorno*100).toFixed(0) + "%"

                if(baseOperacoesAcao[inputTicker.ticker][cliente] != undefined){
                    if(baseOperacoesAcao[inputTicker.ticker][cliente]["callCoberta"]==true){
                        return(
                            <td key={"linha" + inputTicker.ticker + cliente} className={`${styles.tableValor} ${styles.itemOk}`}>
                                <div className={`${styles.tableValorInteiro}`}>
                                    <div className={`${styles.tableValorParte}`}>{quantidadeTexto}</div>
                                    <div className={`${styles.tableValorParte}`}>| {valorTexto}</div>
                                    <div className={`${styles.tableValorParte}`}>{"| " + retornoTexto}</div>
                                    <div className={`${styles.tableValorParte} ${styles.tableCoberta}`}>* C</div>
                                </div>
                            </td>
                        )
                    }else{
                        return(
                            <td key={"linha" + inputTicker.ticker + cliente} className={`${styles.tableValor} ${styles[warning]}`}>
                                <div className={`${styles.tableValorInteiro}`}>
                                    <div className={`${styles.tableValorParte}`}>{quantidadeTexto}</div>
                                    <div className={`${styles.tableValorParte}`}>| {valorTexto}</div>
                                    <div className={`${styles.tableValorParte}`}>{"| " + retornoTexto}</div>
                                </div>
                            </td>
                        )
                    }
                }else{
                    return(
                        <td key={"linha" + inputTicker.ticker + cliente} className={`${styles.tableValor} ${styles[warning]}`}>
                            <div className={`${styles.tableValorInteiro}`}>
                                <div className={`${styles.tableValorParte}`}>{quantidadeTexto}</div>
                                <div className={`${styles.tableValorParte}`}>| {valorTexto}</div>
                                <div className={`${styles.tableValorParte}`}>{"| " + retornoTexto}</div>
                            </div>
                        </td>
                    )
                }

            })}
        </tr>
    )

}

export default LinhaTabelaCliente