import CampoForm from "../CampoForm"
import { useState } from "react"
import "./FormEditOp.css"
import { useEffect } from "react"
import GraficoLimpo from "../GraficoLimpo"
import { moneyMask, reverseFormatMoney, reverseNumMask, numMask } from "../NumberMasks"

const FormEditOpcoes = ({opcaoDados, baseHistoricoAcao, alteraOpcao}) => {

    console.log(opcaoDados)

    // VARIÁVEL DE ESTADO COM HISTÓRICO DA AÇÃO
    var baseHitoricoAcao = JSON.parse(JSON.stringify((baseHistoricoAcao)))
    
    // VARIÁVEIS DE ESTADO QUE ARMAZENA OS VALORES DA OPÇÃO
    const [opcao, setOpcao] = useState({
        Comentários:opcaoDados.Comentários,
        Etiqueta:opcaoDados.Etiqueta,
        Horario:opcaoDados.Horario,
        ticker: opcaoDados.ticker,
        strike: opcaoDados.strike,
        ultimo: opcaoDados.ultimo,
        DataDeRegistro: opcaoDados.DataDeRegistro,
        acao: opcaoDados.acao,
        precoAcao: opcaoDados.precoAcao,
        precoAlvo: opcaoDados.precoAlvo,
        bandaInferior: opcaoDados.bandaInferior,
        bandaSuperior: opcaoDados.bandaSuperior,
        mediaMovel20: opcaoDados.mediaMovel20,
        tendencia: opcaoDados.tendencia,
        maiorVenc: opcaoDados.maiorVenc,
        menorVenc: opcaoDados.menorVenc,
        vencimento: opcaoDados.vencimento,
        tipo: opcaoDados.tipo,
        volImplicita: opcaoDados.volImplicita,
        ivRank: opcaoDados.ivRank,
        ivRankCalls: opcaoDados.ivRankCalls,
        ivRankPuts: opcaoDados.ivRankPuts,
        ivRankMedioCalls: opcaoDados.ivRankMedioCalls,
        ivRankMedioPuts: opcaoDados.ivRankMedioPuts,
        ivRankPutsPercentil: opcaoDados.ivRankPutsPercentil,
        ivRankCallsPercentil: opcaoDados.ivRankCallsPercentil,
        retornoMensal: opcaoDados.retornoMensal,
        delta: opcaoDados.delta,
        beta: opcaoDados.beta,
        gama: opcaoDados.gama,
        tetaPercentual: opcaoDados.tetaPercentual,
        tetaValor: opcaoDados.tetaValor,
        ranking: opcaoDados.ranking,
        ifr: opcaoDados.ifr
    })

    // TODA VEZ QUE O PREÇO FOR ATUALIZADO, AUTOMATICAMENTE A RENTABILIDADE MENSAL É CALCULADA
    useEffect(()=>{
        // CAPTURA A DATA DE HOJE
        var hoje = new Date()

        // CAPTURA O VENCIMENTO
        var vencimento = new Date(opcao.vencimento)

        // CALCULA A DIFERENÇA DE DIAS ENTRE HOJE E O VENCIMENTO
        var difDias = parseInt((vencimento - hoje)/86400000)

        // CÁLCULO DA RENTABILIDADE TOTAL DA OPERAÇÃO
        var rentabilidade = opcao.ultimo / opcao.strike

        // CALCULA A RENTABILIDADE MENSAL
        var rentDiaria = (((rentabilidade+1)**(1/difDias))-1)
        var rentMensal = ((1+rentDiaria)**(30))-1    

        setOpcao((prev)=>({...prev, retornoMensal: rentMensal}))

    },[opcao.ultimo])

    // TODA VEZ QUE O VALOR MENSAL OU DELTA FOR ATUALIZADO, ATUALIZA O FIREBASE
    useEffect(()=>{
        //alteraOpcao(opcao)
    },[opcao.retornoMensal])

    // TODA VER QUE O DELTA MUDAR, ALTERA A OPÇÃO
    useEffect(()=>{
        //alteraOpcao(opcao)
    },[opcao.delta])

    // CONSTRUÇÃO DAS CURVAS DE IMPRESSÃO
    var baseGraficoAcao = []
    var baseGraficoPalvo = []
    var baseGraficoBordaInferior = []
    var baseGraficoBordaSuperior = []
    var baseGraficoStrike =[]
    var baseGraficoMenorVenc = []
    var baseGraficoMaiorVenc = []

    for (let elementKey in baseHitoricoAcao) {

        // CAPTURA O VALOR DA AÇÃO
        const element = baseHitoricoAcao[elementKey];

        // CAPTURA A DATA DE HOJE E O PERÍODO DE 12 MESES ATRÁS
        const dataHoje = new Date()

        // CONVERTER O DADO X EM DATA ANTES DE INSERIR NO ARRAY
        // SPLIT O TEXTO
        const dataObj = elementKey.split("-")
        const ano = parseInt(dataObj[0]) 
        const mes = parseInt(dataObj[1])-1 // DATA FINAL DO MÊS ANTERIOR = 01 DO MÊS SEGUINTE
        const dia = parseInt(dataObj[2])

        // DATA DE FECHAMENTO DO MÊS ANTERIOR VIRA DATA DE ABERTURA DO MÊS SEGUINTE
        const data = new Date(ano,mes,dia)
        const difMeses = (dataHoje - data)/2629800000

        // MONTA OS DADOS INDIVIDUAIS
        const inputHistoricoAcao = {x: data, y:element }
        const inputPA = {x: data, y:parseFloat(opcao.precoAlvo)}
        const inputBInf = {x: data, y:parseFloat(opcao.bandaInferior)}
        const inputBSup = {x: data, y:parseFloat(opcao.bandaSuperior)}
        const inputStrike = {x: data, y:parseFloat(opcao.strike)}
        const inputMenorV = {x: data, y:parseFloat(opcao.menorVenc)} 
        const inputMaiorV = {x: data, y:parseFloat(opcao.maiorVenc)}

        // CONSTRÓI O GRÁFICO FINAL
        // FILTRA SOMENTE OS ÚLTIMOS 12 MESES
        if(difMeses<13){
            baseGraficoAcao.push(inputHistoricoAcao)
            baseGraficoPalvo.push(inputPA)
            baseGraficoBordaInferior.push(inputBInf)
            baseGraficoBordaSuperior.push(inputBSup)
            baseGraficoStrike.push(inputStrike)
            baseGraficoMenorVenc.push(inputMenorV)
            baseGraficoMaiorVenc.push(inputMaiorV)
        }

    }

    // RANKEIA HISTORICAMENTE OS DADOS
    baseGraficoAcao.sort((a, b) => a.x - b.x);
    baseGraficoPalvo.sort((a, b) => a.x - b.x);
    baseGraficoBordaInferior.sort((a, b) => a.x - b.x);
    baseGraficoBordaSuperior.sort((a, b) => a.x - b.x);
    baseGraficoStrike.sort((a, b) => a.x - b.x);
    baseGraficoMenorVenc.sort((a, b) => a.x - b.x);
    baseGraficoMaiorVenc.sort((a, b) => a.x - b.x);

    // INSERE O NO GRÁFICO DE EVOLUÇÃO DA AÇÃO O ÚLTIMO PREÇO QUE VEM DA BASE OPÇÕES
    var data = opcao.DataDeRegistro.split("-")
    var dia = data[0]
    var mes = data[1]-1
    var resto = data[2].split(" ") 
    var ano = resto[0]
    var data = new Date(ano,mes,dia)
    var ultimoInputPrecoAcao = {x:data , y:opcao.precoAcao }
    var inputPA = {x: data, y:parseFloat(opcao.precoAlvo)}
    var inputBInf = {x: data, y:parseFloat(opcao.bandaInferior)}
    var inputBSup = {x: data, y:parseFloat(opcao.bandaSuperior)}
    var inputStrike = {x: data, y:parseFloat(opcao.strike)}
    var inputMenorV = {x: data, y:parseFloat(opcao.menorVenc)} 
    var inputMaiorV = {x: data, y:parseFloat(opcao.maiorVenc)}

    baseGraficoAcao.push(ultimoInputPrecoAcao)
    baseGraficoPalvo.push(inputPA)
    baseGraficoBordaInferior.push(inputBInf)
    baseGraficoBordaSuperior.push(inputBSup)
    baseGraficoStrike.push(inputStrike)
    baseGraficoMenorVenc.push(inputMenorV)
    baseGraficoMaiorVenc.push(inputMaiorV)

    // VARIÁVEIS DE IMPRESSÃO DO GRÁFICO DA AÇÃO  
    const fonte = "sans-serif"

    const optionsEvolucaoAcao = {
        animationEnabled: true,
		backgroundColor: "transparent",
        height:260,
        toolTip:{
            enabled:true,
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseFloat(e.entries[i].dataPoint.y).toFixed(2)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
        },
		legend:{
			fontColor: "rgb(150,150,150)",
            fontWeight: "normal",
            verticalAlign: "top",
            fontSize:10,
            fontFamily:fonte
		},
		title:{
			text: "",
			padding:0,
			fontSize:0,
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 1,
            gridColor: "rgb(200,200,200)",
            gridDashType: "dash",
			labelFontSize:10,
            labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lineThickness: 1,
			tickThickness: 0,
            titleMaxWidth:0,
            margin: 0,
            labelFontFamily:fonte
		},
		axisX:{
			gridThickness: 1,
            gridColor: "rgb(200,200,200)",
            gridDashType: "dash",
			margin: 0,
			tickThickness: 0,
            labelFontSize:10,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lineThickness: 1,
            labelFontFamily: fonte

		},
		data: [{
			type: "line",
            showInLegend: true, 
            name: "Preço",
            indexLabelFontSize: 0,
            indexLabelFontFamily: fonte,
			legendText: "Preço",
			color: "rgba(102,205,170,.7)",
			markerSize: 0,
			dataPoints: baseGraficoAcao
		},{
            type: "line",
            showInLegend: true,
            name: "Preço Alvo",
			legendText: "Preço Alvo",
			color: "rgb(0,139,139,.7)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 10,
			markerSize: 0,
            lineThickness: 1,
			dataPoints: baseGraficoPalvo
        },{
            type: "line",
            showInLegend: true,
            name: "Strike",
			legendText: "Strike",
			color: "rgb(0,94,94)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 10,
			markerSize: 0,
            lineThickness: 1,
			dataPoints: baseGraficoStrike
        },{
            type: "line",
            showInLegend: true,
            name: "Borda Inferior",
			legendText: "Borda Inferior",
			color: "rgba(255,127,80,.7)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 10,
			markerSize: 0,
            lineThickness: 1,
            lineDashType: "dash",
			dataPoints: baseGraficoBordaInferior
        },{
            type: "line",
            showInLegend: true,
            name: "Borda Superior",
			legendText: "Borda Superior",
			color: "rgba(255,127,80,.7)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 0.00001,
			markerSize: 0,
            lineThickness: 1,
            lineDashType: "dash",
			dataPoints: baseGraficoBordaSuperior
        },{
            type: "line",
            showInLegend: true,
            name: "Menor Vencimento",
			legendText: "Menor Vencimento",
			color: "rgba(65,105,225,.7)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 0.00001,
			markerSize: 0,
            lineThickness: 1,
            lineDashType: "dash",
			dataPoints: baseGraficoMenorVenc
        },{
            type: "line",
            showInLegend: true,
            name: "Maior Vencimento",
			legendText: "Maior Vencimento",
			color: "rgba(65,105,225,.7)",
            indexLabelFontFamily: fonte,
            indexLabelFontSize: 0.00001,
			markerSize: 0,
            lineThickness: 1,
            lineDashType: "dash",
			dataPoints: baseGraficoMaiorVenc
        }]
    }  

    return(
        <div className="formEditOp">
            <div className="calculadoraBox">
                <div className="calculadoraTitulo">CALCULADORA</div>
                <div className="row camposCalculadora">
                    <CampoForm 
                        valor = {moneyMask(opcao.ultimo)} 
                        aoAlterado = {valor => setOpcao((prev)=>({...prev, ultimo: Number(reverseFormatMoney(valor))}))} 
                        nomeCampo= "Preço Opção" 
                        campoId="form-edit-precoOpcao" 
                        tipo="text" 
                        dataCampo="precoOpcao" 
                        placeholder="Preço da Opção" 
                        dataMask="" 
                        required= {true}  
                        largura= "12"
                    />
                    <CampoForm 
                        valor = {numMask(opcao.delta)} 
                        aoAlterado = {valor => setOpcao((prev)=>({...prev, delta: reverseNumMask(valor)}))} 
                        nomeCampo= "Delta" 
                        campoId="form-edit-delta" 
                        tipo="text" 
                        dataCampo="deltaOpcao" 
                        placeholder="delta da Opcao" 
                        dataMask="" 
                        required= {false}  
                        largura= "12"
                    />
                </div>
                <div className="calculadoraResultadoMensal">
                    <div className="opcaoResultMensalTitulo">Resultado Mensal</div>
                    <div className="opcaoResultMensalValor">{(opcao.retornoMensal*100).toFixed(2) + "%"}</div>
                </div>
            </div>
            <div className="opcaoDetalheBox">
                <div className="opcaoDetalheSubTitulo">Gregas</div>
                <div className="opcaoDetalheGregas">
                    <div className="opcaoGregasDet">
                        <div className="opcaoGregasLetra">&#920;</div>
                        {parseFloat(opcao.tetaValor).toFixed(3)}
                    </div>
                    <div className="opcaoGregasDet">
                        <div className="opcaoGregasLetra">&#914;</div>
                        {parseFloat(opcao.beta).toFixed(3)}
                    </div>
                    <div className="opcaoGregasDet">
                        <div className="opcaoGregasLetra">&#915;</div>
                        {parseFloat(opcao.gama).toFixed(3)}
                    </div>
                </div>
                <div className="opcaoDetalheVolatilidade">
                    <div className="opcaoDetalheSubTitulo">{opcao.acao + " - "}Volatilidade</div>
                    <div className="opcaoVol">
                        <div className="opcaoVolImp">VI <b>{": " + opcao.volImplicita.toFixed(2) + "%"}</b></div>
                        <div className="opcaoVolIVR">IVR <b>{": " + opcao.ivRank.toFixed(1) + "%"}</b></div>
                    </div>
                </div>
                <div className="opcaoDetalheSubTitulo">Preço</div>
                <div className="opcaoDetalhePrecoAtivo">
                    <div className="opcaoDetalhePrecoAtivoPreco">Preço <div className="destaquePrecoOpcao">{opcao.precoAcao}</div></div>
                    <div className="opcaoDetalhePrecoAtivoStrike"> Strike <div className="destaquePrecoOpcao">{opcao.strike}</div></div>
                    <div className="opcaoDetalhePrecoAtivoPercentual">Dif. <div className="destaquePrecoOpcao">{((1- (opcao.strike/opcao.precoAcao))*100).toFixed(1)}%</div></div>
                </div>
            </div>
            <div className="acaoBox">
                <div className="tituloAcao">{opcao.acao}</div>
                <div className="graficoAcao">
                    <GraficoLimpo id={"graf-" + "detalheAcao-" + opcao.acao} options = {optionsEvolucaoAcao}/>
                </div>
                <div className="comentariosGraficoAcao">
                    <div className="tendenciaAcao">Tendência: <em>{opcao.tendencia}</em></div>
                    <div className="tendenciaAcao">IFR: <em>{parseFloat(opcao.ifr).toFixed(1) + "%"}</em></div>
                </div>
            </div>
        </div>
    )

}

export default FormEditOpcoes