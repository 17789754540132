import { useState } from "react";
import { FadeLoader } from "react-spinners";

function LoaderOpcoes(props) {

  const [color, setColor] = useState("rgba(0,139,139,0.5)");

  return (
    <div className="loaderOpcoes">
      <FadeLoader
        color={color}
        loading={true}
        aria-label="Loading Spinner"
        data-testid="loaderOptions"
      />
    </div>
  );
}

export default LoaderOpcoes