import LinhaClienteAdmin from './linhasClientes';
import styles from './PainelAdmin.module.css';
import { useState, useEffect, useRef } from 'react';
import { filterData, SearchType } from 'filter-data';

// MÓDULO DE GESTÃO DE RISCO
const PainelClientesAdmin = (props) => {

    // VARIÁVEL QUE CONTA LINHAS ÍMPARES E PARES
    const linhaCont = useRef(0);

    // TODA VEZ QUE A PÁGINA CARREGAR, ALTERA A VARIÁVEL DE ESTADO
    useEffect(()=>{
        linhaCont.current = 0
    })

    return(
        <section className={`${styles.secao}`}>
            <div className={`${styles.painelGeral}`}>
                <div className={`${styles.painelTitulo} row`}>
                    <div className={`${styles.users} col-4 col-md-2`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Clientes</div>
                            <div className={`${styles.subtitulo}`}></div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco} col-4 col-md-5`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Rendimento</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Acumulado</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>CDI</div>
                                <div className={`${styles.coluna} `}>TIR</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Período</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco} col-4 col-md-5`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Prêmios</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>N-3</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>N-2</div>
                                <div className={`${styles.coluna}`}>N-1</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Período ativo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.painelCorpo}`}>
                    {props.baseAdmin.map((objeto) => {
                        
                        // ATUALIZA O INDEX DA LINHA
                        linhaCont.current = linhaCont.current + 1

                        // ATUALIZA O KEY DA LINHA
                        var key = "linhaTabelaAdmin" + linhaCont.current
                        var index = linhaCont.current*1

                        // CAPTURA A CHAVE DO CLIENTE
                        var chave = objeto.chave

                        // PASSA SOMENTE A BASE FILTRADA DO CLIENTE COMO PROPS
                        // VERIFICA SE É ÍMPAR OU PAR
                        if(linhaCont.current%2 == 0){
                            return(<LinhaClienteAdmin base={objeto} chave={chave} index={index} key={key} lineColor="white" linhaCont={linhaCont.current}/>)
                        }else{
                            return(<LinhaClienteAdmin base={objeto} chave={chave} index={index} key={key} lineColor="gray" linhaCont={linhaCont.current}/>)
                        }

                    })}
                </div>
            </div>
        </section>
    )
}

export default PainelClientesAdmin;