import { useLocation } from 'react-router-dom';
import "./Header.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import HeaderLink from '../HeaderLink'

const Header = (props) => {

	// DECLARAÇÃO DE VARIÁVEIS
	const local = useLocation()

	// LÓGICA PARA DETERMINAÇÃO DAS VARIÁVEIS DE EXIBIÇÃO
	var exibeLogado = ""
	var exibeAdmin = ""
	var exibeTermoEPolitica = ""

	if (props.user) {
		exibeLogado = "sim"
		if(props.user.termoEPolitica === false){
			exibeTermoEPolitica = "não"
		}else{
			exibeTermoEPolitica = "sim"
			if (props.user.email === "techumltda@gmail.com") {
				exibeAdmin = "sim"
			}else{
				exibeAdmin = "não"
			}
		}
	}else{
		exibeLogado = "não"
		exibeTermoEPolitica = "sim"
	}

	// JSX
	return(
		<Navbar bg="light" expand="lg" fixed="top">
			<Container fluid="md">
				<Navbar.Brand href="">            
					<img
					alt=""
					src="imagens/logo.png"
					width="200"
					className="d-inline-block align-top hide-app"
					/>{' '}
				</Navbar.Brand>
				<div className="tituloPagina">
					<span className="tituloPaginaTexto">{props.nomePagina}</span>
				</div>
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
					<Nav className="ml-auto">	
						<HeaderLink to="/perfil" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="não">Perfil</HeaderLink>
						<HeaderLink to="/" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="não">Dashboard</HeaderLink>
						<HeaderLink to="/cadastro" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="não" exibeAdmin={exibeAdmin} conteudoAdmin="não">Cadastro</HeaderLink>
						<HeaderLink to="/logIn" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="não" exibeAdmin={exibeAdmin} conteudoAdmin="não">Log-In</HeaderLink>
						<HeaderLink to="/admin" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="sim">Admin Tech</HeaderLink>
						<HeaderLink to="/opcoesOp" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="sim">Opcões Op</HeaderLink>
						<HeaderLink to="/gestaoRisco" exibeTermoEPolitica={exibeTermoEPolitica} exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="sim">Gestão de Risco</HeaderLink>
					</Nav>
				</Navbar.Collapse>
			</Container>
	  	</Navbar>
	)

}

export default Header