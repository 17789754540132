import './CampoForm.css' 
import validaItem from './validaForm';
import Inputmask from "inputmask";
import React from 'react';

const CampoForm = React.forwardRef((props, ref) => {

    const campoId = props.campoId;
    const nomeCampo = props.nomeCampo;
    const tipo = props.tipo;
    const dataCampo = props.dataCampo;
    const placeholder = props.placeholder;
    const dataMask = props.dataMask;
    const required = props.required;
    const valor = props.valor;
    const checked = props.checked;
    const largura = "col-12 col-md-" + props.largura + " mb-3"

    const mostrarSenha = (evento) =>{

        const tag = evento.target.tagName
        var elementoInput
        var elementoBtn
        var divPai
    
        /* O CLICK PODE ACONTECER NA SPAN OU NO BUTTON*/
        if(tag == "BUTTON"){
            divPai = evento.target.parentElement
            elementoInput = divPai.childNodes[0]
            elementoBtn = divPai.childNodes[1]
        }else{
            elementoBtn = evento.target.parentElement
            divPai = elementoBtn.parentElement
            elementoInput = divPai.childNodes[0] 
        }
    
        /* MOSTRA OU ESCONDE AS SENHAS */
        if(elementoInput){
    
            if(elementoInput.type === 'password'){
                elementoInput.type = 'text'
                elementoBtn.classList.add('btn-outline-padrao');
                elementoBtn.firstChild.innerText = "visibility"
    
            }else{
                elementoInput.type = 'password'
                elementoBtn.classList.remove('btn-outline-padrao');
                elementoBtn.firstChild.innerText = "visibility_off"
            };
    
        }
    
    }

    const aoDigitar = (evento) => {
        var item = evento.target
        var tipoItem = item.type
        var valido = false

        if(tipoItem == "checkbox"){

            props.aoAlterado(item.checked)

        }else{
            props.aoAlterado(item.value)
            if(dataMask == ""){
    
            }else{
                var im = new Inputmask(item)
                im.mask(item)
            }
            
            {valido = validaItem(item)}

        }

    }

    if(tipo == "password"){

        return (
            <div>
                <label htmlFor= {campoId} className="form-label">{nomeCampo}</label>
                <div className="input-group mb-3">
                    <input value = {valor} onChange={aoDigitar} type= {tipo} className="form-control" placeholder={placeholder} aria-label="Senha" aria-describedby="cadastro-senha" id={campoId} required data-campo={dataCampo} />
                    <button className="btn btn-outline-secondary btn-fim-input btn-ver-senha" type="button" data-ver-senha onClick={mostrarSenha}>
                        <span className="material-symbols-outlined">visibility_off</span>
                    </button>
                </div>
            </div>
        )

    }else if(tipo == "checkbox"){

        return(
            <div className="form-check" key={valor}>
                <input className="form-check-input" type="checkbox" value = "" id ={campoId} data-campo={dataCampo} checked={checked} onChange={aoDigitar}/>
                <label className="form-check-label" htmlFor={campoId}>
                    {nomeCampo}
                </label>
            </div>
        )

    }else if(tipo == "select"){
        return(
            <div className={largura}>
                <div className="titulo-select">{props.nomeCampo}</div>
                <select ref={props.referencia} className="form-select" aria-label={nomeCampo} onChange={aoDigitar}>
                    {props.lista.map((objeto) => (
                        <option key={objeto.chave} value={objeto.value}>{objeto.nome}</option>
                    ))}
                </select>
            </div>
        )

    }else{

        if(props.altura === "textoLargo"){
            return (
                <div className = {largura}>
                    <label htmlFor={campoId} className="form-label">{nomeCampo}</label>
                    <textarea ref={ref} value = {valor} onChange={aoDigitar} required={required} type={tipo} className="form-control textoLargo" id={campoId} data-campo={dataCampo} placeholder={placeholder} data-inputmask={dataMask}/>
                </div>
            )
        }else{
            return (
                <div className = {largura}>
                    <label htmlFor={campoId} className="form-label">{nomeCampo}</label>
                    <input ref={ref} value = {valor} onChange={aoDigitar} required={required} type={tipo} className="form-control" id={campoId} data-campo={dataCampo} placeholder={placeholder} data-inputmask={dataMask}/>
                </div>
            )
        }


    }

})

export default CampoForm