function convertDataToText(data){

    if(data instanceof Date){
        var dia = ""
        var mes = ""
        if(data.getDate()<10){
            dia = "0" + data.getDate()
        }else{
            dia = data.getDate()
        }

        if((data.getMonth()+1) <10){
            mes = "0" + (data.getMonth()+1)
        }else{
            mes = (data.getMonth()+1)
        }

        var dataTexto = dia + "-" + mes + "-" + data.getFullYear()
    }else{
    }

    return dataTexto
        
}

function convertTextoToData(dataTexto){

    if(dataTexto!=undefined){
        
        // CONVERTE AS DATAS DE FORMATO TEXTO PARA DATA
        // SPLIT O TEXTO
        var dataObj = dataTexto.split("-")
        var ano = parseInt(dataObj[2]) 
        var mes = parseInt(dataObj[1])-1
        var dia = parseInt(dataObj[0])

        // VERIFICA SE ESTÁ NO FORMATO DE INPUTS (YYYY/MM/DD)
        if(dia > 31){
            // NEW DATE FORMATO AMERICADO PADRÃO DE INPUTS
            var data = new Date(dia,mes,ano)
            return data
        }else{
            // NEW DATE
            var data = new Date(ano,mes,dia)
            return data
        }
    }else{

    }
}

export {convertDataToText, convertTextoToData}