import './Cadastro.css'
import '../../index.css'
import CampoForm from '../../componentes/CampoForm'
import validaItem from '../../componentes/CampoForm/validaForm.js'
import { createUserWithEmailAndPassword, deleteUser,updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";
import { app, auth } from '../../config/firebase';
import { useState, useRef, useEffect} from 'react'
import ModalApp from '../../componentes/Modal';
import PreLoader from '../../componentes/PreLoader';
import { LinkContainer } from 'react-router-bootstrap';
  
// INICIA O BANCO DE DADOS DO FIREBASE
const db = getFirestore(app);

// MÓDULO DE CADASTRO
const Cadastro = (props) => {

    // hook para permitir um estado dentro de uma função
    // O objetivo de criar um estado é para que exista via de mão dupla entre o componente e o dado, diferente do props que é uma via de mão única
    // O estado é como se fosse uma variável global para o ambiente React que permite disparos, conexões e métodos
    // O valor entre aspas de useState é o valor original inicial que se deseja atribuir a variável
    const [nome, setNome] = useState ('')
    const [sobrenome, setSobrenome] = useState ('')
    const [cpf, setCpf] = useState ('')
    const [nascimento, setNascimento] = useState ('')
    const [email, setEmail] = useState ('')
    const [confEmail, setConfEmail] = useState ('')
    const [celular, setCelular] = useState ('')
    const [whatsapp, setWhatsapp] = useState ('')
    const [cep, setCep] = useState ('')
    const [logradouro, setLogradouro] = useState ('')
    const [endComplemento, setEndComplemento] = useState ('')
    const [bairro, setBairro] = useState ('')
    const [cidade, setCidade] = useState ('')
    const [estado, setEstado] = useState ('')
    const [senha, setSenha] = useState ('')
    const [confSenha, setConfSenha] = useState ('')
    const [termoEPolitica, setTermoEPolitica] = useState(false)
    const [consultor, setConsultor] = useState(1)

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:""
    })

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // FUNÇÕES QUE ATRIBUEM AÇÕES A ITENS NO HTML APÓS A PÁGINA SER CARREGADA
    useEffect(() => {
    },[])

    const [loading, setLoading] = useState(false);

    var corpo = ""
    var titulo = ""

    // DATA DE HOJE
    const date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var currentDate = `${year}-${month}-${day}`

    // ADICIONA AO BOTÃO DO FORMULÁRIO AS FUNÇÕES DE VALIDAÇÃO E UPLOAD NO FIREBASE
    const submitCadastro = (evento) => {
        evento.preventDefault()
        setLoading(true)
        const form = evento.target.form
        var listaInputFormulario = form.querySelectorAll("input")
        var dadosCadastrais = {}

        // LOOP EM TODOS OS CAMPOS PARA VALIDAÇÃO
        for (let contador = 0; contador < listaInputFormulario.length; contador++) {
            var input = listaInputFormulario[contador]

            // RETORNO DOS CASOS DE CAMPOS NÃO PREENCHIDOS
            if (validaItem(input) == "required false"){
                titulo = "Formulário Incompleto!"
				corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> não foi preenchido, favor revisar</p>
                        <p>Os mesmos estão indicados por um (*)</p>
                    </div>
                setLoading(false);
                callModal("")
                return false

            // RETORNO DOS CASOS DE CAMPOS COM TIPOS DIFERENTES DO DEVIDO
            }else if(validaItem(input) == "type false"){
                titulo = "Valor incoerente!"
				corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> ossui um padrão de preenchimento que não foi obedecido, favor revisar</p>
                        <p>Ex.: E-mail que precisa conter @</p>
                    </div>
                setLoading(false);
                callModal("")
                return false

            }else{

                // VALIDA SE OS CAMPOS DUPLICADOS ESTÃO SEMELHANTES
                var campoDataset = "[data-campo" + "='" + input.dataset.campo + "']"
                var camposIguais = form.querySelectorAll(campoDataset);
                var valorCamposIguais = camposIguais[0].value;

                for (let contador2 = 0; contador2 < camposIguais.length; contador2++) {

                    if(camposIguais[contador2].value == valorCamposIguais){
    
    
                    }else{
    
                        titulo = "Valores do campo " + input.dataset.campo + " não correspondentes"
                        corpo = 
                        <div className="">
                            <p>Alguns campos devem ser preenchidos 2x como forma de validar suas informações.</p>
                            <p>Os valores de <strong>{input.dataset.campo}</strong> não estão correspondendo</p>
                        </div>
                        setLoading(false);
                        callModal("")
                        return false
    
                    }
    
                }

                // SE O CAMPO ESTIVER TOTALMENTE OK, É ENTÃO ADICIONADO NA BASE FINAL A SER CARREGADA NO BACKEND
                
                var campo = input.dataset.campo
                var valor = input.value

                dadosCadastrais[campo] = valor

            }

        }

        // FAZ UPLOAD DO CADASTRO NO FIREBASE
        const email = dadosCadastrais["email"];
        const password = dadosCadastrais["senha"];

        //SOBE CADASTRO NO FIREBASE
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

            // APAGA A SENHA DOS DADOS CADASTRAIS PARA QUE NÃO SUBA NO FIRESTORE
            dadosCadastrais["senha"] = ""

            // ADICIONA O VALOR TERMOS E POLÍTICA, DATA E CONSULTOR
            dadosCadastrais["termoEPolitica"] = termoEPolitica
            dadosCadastrais["dataCriacao"] = currentDate
            dadosCadastrais["consultor"] = consultor

            // SIGNED IN 
            const user = userCredential.user;
            const userUid = user.uid

            // SOBE O CADASTRO COMPLETO NO FIRESTORE 
            const docRef = doc(db, 'users', userUid)
            const cadastro = setDoc(docRef, dadosCadastrais).then((cadastro) => {
                // MENSAGEM DE QUE O CADASTRO FOI EFETUADO COM SUCESSO
                titulo = "Seu cadastro foi efetuado com sucesso!"
                corpo = 
                <div className="">
                    <p>Nossos gestores irão verificar e entrar em contato em breve.</p>
                </div>
                setLoading(false);
                callModal("")

                // LIMPA O FORMULÁRIO
                setNome('')
                setSobrenome('')
                setCpf('')
                setNascimento('')
                setEmail('')
                setConfEmail('')
                setCelular('')
                setWhatsapp('')
                setCep('')
                setLogradouro('')
                setEndComplemento('')
                setBairro('')
                setCidade('')
                setEstado('')
                setSenha('')
                setConfSenha('')

            }).catch((error) => {
                // DELETA CÓDIGO DA BASE DE USUÁRIOS
                deleteUser(user).then(() => {
                    // MENSAGEM DE ERRO
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    titulo = "Erro no processo de cadastro"
                    corpo = 
                    <div className="">
                        <p>Um erro foi indentificado</p>
                        <p>{errorMessage}</p>
                        <p>Caso não consiga solucioná-lo entre em contato com um de nossos gestores</p>
                    </div>
                    setLoading(false);
                    callModal("")
                  }).catch((error) => {
                  });

            });

        })
        .catch((error) => {
            // MENSAGEM DE ERRO
            const errorCode = error.code;
            const errorMessage = error.message;
            titulo = "Erro no processo de cadastro"
            corpo = 
            <div className="">
                <p>Um erro foi indentificado</p>
                <p>{errorMessage}</p>
                <p>Caso não consiga solucioná-lo entre em contato com um de nossos gestores</p>
            </div>
            setLoading(false);
            callModal("")
        });

    }

    // RETORNA O HTML DEVIDO DO FORMULÁRIO
    return(
        <div>
            {loading ? (<PreLoader/>) : (
            <main>
                <section className="cadastro container-md">
                <ModalApp tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
                    <div className="cadastro-bemvindo">
                        <p><strong>Olá, seja bem vindo(a)!</strong></p>
                        <p>Estamos felizes de ter você conosco</p>
                        <p>Para <em>abrir uma conta gratuitamente</em> e usufruir do nosso webapp precisamos realizar seu cadastro</p>
                    </div>
                    <div className="form-text">
                        Já possui cadastro? 
                        <LinkContainer to ="/logIn">
                            <span className="esqueceu-senha-clique"> Entre Aqui!</span>
                        </LinkContainer>
                    </div>
                    <form id="form-cadastro-profile" data-form="CadastroPerfil">
                        <h1><span className="material-symbols-outlined icon-title">person</span>Dados pessoais</h1>
                        <div className="row">
                            <CampoForm 
                                valor = {nome} 
                                aoAlterado = {valor => setNome(valor)} 
                                nomeCampo= "Nome *" 
                                campoId="cadastro-nome" 
                                tipo="text" 
                                dataCampo="nome" 
                                placeholder="Digite seu nome" 
                                dataMask="" 
                                required= {true}  
                                largura= "4"
                            />
                            <CampoForm
                                valor = {sobrenome} 
                                aoAlterado = {valor => setSobrenome(valor)}
                                nomeCampo= "Sobrenome *" 
                                campoId="cadastro-sobrenome" 
                                tipo="text" dataCampo="sobrenome" 
                                placeholder="Digite seu sobrenome" 
                                dataMask="" 
                                required= {true} 
                                largura= "8" 
                            />
                            <CampoForm
                                valor = {cpf}
                                aoAlterado = {valor => setCpf(valor)}
                                nomeCampo= "CPF *" 
                                campoId="cadastro-cpf" 
                                tipo="text" 
                                dataCampo="cpf" 
                                placeholder="999.999.999-99" 
                                dataMask="'mask': '999.999.999-99'" 
                                required= {true}  
                                largura= "6"
                            />
                            <CampoForm 
                                valor = {nascimento}
                                aoAlterado = {valor => setNascimento(valor)}
                                nomeCampo= "Data de Nascimento *" 
                                campoId="cadastro-nascimento" 
                                tipo="date" 
                                dataCampo="nascimento" 
                                placeholder="" 
                                dataMask="" 
                                required= {true}  
                                largura= "6"
                            />
                            <CampoForm
                                valor = {email}
                                aoAlterado = {valor => setEmail(valor)} 
                                nomeCampo= "E-mail *" 
                                campoId="cadastro-email" 
                                tipo="email" 
                                dataCampo="email" 
                                placeholder="exemplo@exemplo.com" 
                                dataMask="" 
                                required= {true} 
                                largura= "6" 
                            />
                            <CampoForm 
                                valor = {confEmail}
                                aoAlterado = {valor => setConfEmail(valor)} 
                                nomeCampo= "Confirmação E-mail *" 
                                campoId="cadastro-confemail" 
                                tipo="email" 
                                dataCampo="email" 
                                placeholder="exemplo@exemplo.com" 
                                dataMask="" 
                                required= {true} 
                                largura= "6" 
                            />
                            <CampoForm 
                                valor = {celular}
                                aoAlterado = {valor => setCelular(valor)} 
                                nomeCampo= "Celular" 
                                campoId="cadastro-celular" 
                                tipo="text" 
                                dataCampo="celular" 
                                placeholder="(99) 9 9999-9999" 
                                dataMask="'mask': '(99) 9 9999-9999'" 
                                required= {false} 
                                largura= "6" />
                            <CampoForm 
                                valor = {whatsapp}
                                aoAlterado = {valor => setWhatsapp(valor)} 
                                nomeCampo= "Whatsapp" 
                                campoId="cadastro-wpp" 
                                tipo="text" 
                                dataCampo="whatsapp" 
                                placeholder="(99) 9 9999-9999" 
                                dataMask="'mask': '(99) 9 9999-9999'" 
                                required= {false} 
                                largura= "6" 
                            />
                        </div>
                        <h1><span className="material-symbols-outlined icon-title">home</span>Endereço</h1>
                        <div className="row">
                            <CampoForm 
                                valor = {cep}
                                aoAlterado = {valor => setCep(valor)} 
                                nomeCampo= "Cep *" 
                                campoId="cadastro-cep" 
                                tipo="text" 
                                dataCampo="cep" 
                                placeholder="99999999" 
                                dataMask="'mask': '99999999'" 
                                required= {true} 
                                largura="4" 
                            />
                            <CampoForm
                                valor = {logradouro}
                                aoAlterado = {valor => setLogradouro(valor)}  
                                nomeCampo= "Logradouro *" 
                                campoId="cadastro-logradouro" 
                                tipo="text" 
                                dataCampo="logradouro" 
                                placeholder="Rua exemplo" 
                                dataMask="" 
                                required= {true} 
                                largura="4"  
                            />
                            <CampoForm
                                valor = {endComplemento}
                                aoAlterado = {valor => setEndComplemento(valor)}  
                                nomeCampo= "Complemento" 
                                campoId="cadastro-complemento" 
                                tipo="text" 
                                dataCampo="enderecoComplemento" 
                                placeholder="Casa 1 apt 01"
                                dataMask="" 
                                required= {false} 
                                largura="4" 
                            />
                            <CampoForm 
                                valor = {bairro}
                                aoAlterado = {valor => setBairro(valor)}
                                nomeCampo= "Bairro *" 
                                campoId="cadastro-bairro" 
                                tipo="text" 
                                dataCampo="bairro" 
                                placeholder="Bairro"
                                dataMask="" 
                                required= {true} 
                                largura="6" 
                            />
                            <CampoForm 
                                valor = {cidade}
                                aoAlterado = {valor => setCidade(valor)}
                                nomeCampo= "Cidade *" 
                                campoId="cadastro-cidade" 
                                tipo="text" 
                                dataCampo="cidade" 
                                placeholder="Cidade" 
                                dataMask="" 
                                required= {true} 
                                largura="4" 
                            />
                            <CampoForm 
                                valor = {estado}
                                aoAlterado = {valor => setEstado(valor)}
                                nomeCampo= "Estado *" 
                                campoId="cadastro-estado" 
                                tipo="text" 
                                dataCampo="estado" 
                                placeholder="Estado" 
                                dataMask="" 
                                required= {true} 
                                largura="2" 
                            />
                        </div>
                        <h1><span className="material-symbols-outlined icon-title">supervisor_account</span>Consultor</h1>
                        <div className="row">
                            <CampoForm
                                valor = {consultor}
                                aoAlterado = {valor => setConsultor(valor)}
                                nomeCampo= "Consultor" 
                                campoId= "cadastro-consultor"
                                tipo="select"
                                label="Quem te indicou para TechUm?"
                                lista = {props.baseConsultores}
                                dataCampo="consultor"
                                placeholder= "" 
                                dataMask="" 
                                required= {true} 
                                largura="6"
                            />
                        </div>
                        <h1><span className="material-symbols-outlined icon-title">password</span>Login</h1>
                        <div className="row">
                            <CampoForm
                                valor = {senha}
                                aoAlterado = {valor => setSenha(valor)} 
                                nomeCampo= "Senha *" 
                                campoId="cadastro-senha" 
                                tipo="password" 
                                dataCampo="senha"
                                placeholder="Senha" 
                                dataMask="" 
                                required= {true} 
                                largura="6"
                            />
                            <CampoForm 
                                valor = {confSenha}
                                aoAlterado = {valor => setConfSenha(valor)}
                                nomeCampo= "Confirme sua senha *" 
                                campoId="cadastro-confsenha" 
                                tipo="password" 
                                dataCampo="senha" 
                                placeholder="Confirme sua senha" 
                                dataMask="" 
                                required= {true} 
                                largura="6" 
                            />
                        </div>
                        <div className="row">
                            <div className="col-12 btn-cadastro-box">
                                <button type="submit" className="btn btn-primary btn-cadastro" id="btn-cadastro" data-campo="submitForm" onClick={submitCadastro}>Enviar</button>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
            )}
        </div>
    )

}

export default Cadastro;