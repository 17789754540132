import CanvasJSReact from "../../canvasjs/canvasjs.react";
import './EstatisticaPremios.css';
import Recorder from 'sample-distribution';
import {convertTextoToData, convertDataToText } from "../../paginas/Admin/convertData";
import { useEffect, useState} from "react";
import GraficoLimpo from "../GraficoLimpo";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const EstatisticaPremios = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // OCULTAR E EXIBIR DIVS NA TELA APÓS CARREGAMENTO DO COMPONENTE
    useEffect(() => {
        // MUDANÇAS DE ESTILO NA BARRA DE NAVEGAÇÃO DE ACORDO COM USUÁRIO
        if(drilldown ==="geral"){
            // EXIBE OS NÍVEIS DE DETALHE ADEQUADOS
            document.getElementById("detalheTitulo").classList.remove("hide")
            document.getElementById("detalheNivel1").classList.add("hide")
            document.getElementById("detalheNivel2").classList.add("hide")
            document.getElementById("detalheNivel3").classList.add("hide")

        }else if(drilldown ==="data"){
            // EXIBE OS NÍVEIS DE DETALHE ADEQUADOS
            document.getElementById("detalheTitulo").classList.add("hide")
            document.getElementById("detalheNivel1").classList.remove("hide")
            document.getElementById("detalheNivel2").classList.remove("hide")
            document.getElementById("detalheNivel3").classList.add("hide")

            // DESTACA O NÍVEL ADEQUADO
            document.getElementById("detalheNivel1").children[0].classList.remove("linkNivelAtivo")
            document.getElementById("detalheNivel2").children[1].classList.add("linkNivelAtivo")
            document.getElementById("detalheNivel3").children[1].classList.remove("linkNivelAtivo")

        }else if(drilldown ==="cliente"){
            // EXIBE OS NÍVEIS DE DETALHE ADEQUADOS
            document.getElementById("detalheTitulo").classList.add("hide")
            document.getElementById("detalheNivel1").classList.remove("hide")
            document.getElementById("detalheNivel2").classList.remove("hide")
            document.getElementById("detalheNivel3").classList.remove("hide")

            // DESTACA O NÍVEL ADEQUADO
            document.getElementById("detalheNivel1").children[0].classList.remove("linkNivelAtivo")
            document.getElementById("detalheNivel2").children[1].classList.remove("linkNivelAtivo")
            document.getElementById("detalheNivel3").children[1].classList.add("linkNivelAtivo")
        }
    })

    // VARIÁVEL DE ESTADO QUE DETERMINA O NÍVEL DO DRILLDOWN
    const drilldown = props.drillDown
    const [dadosDrillDownData, setDadosDrillDownData] = useState(null)
    const [dadosDrillDownDataRef, setDadosDrillDownDataRef] = useState(null)
    const [dataParaDrillDown, setDataParaDrillDown] = useState(null)
    const [dadosDrillDownCliente, setDadosDrillDownCliente] = useState(null)
    const [dadosDrillDownClienteRef, setDadosDrillDownClienteRef] = useState(null)
    const [clienteParaDrillDown, setClienteParaDrillDown] = useState(null)

    // ORGANIZAÇÃO DOS DADOS DE IMPRESSÃO DO GRÁFICO
    const base = props.base
    const baseCompletaPremios = {}
    const dadosGraf = []
    const dadosGrafRef = []
    var baseCliente = {}
    var dadosEstatisticosPremios = []
    var baseDrillDownData = []
    var baseDrillDownDataRef = []
    var baseDrillDownCliente = []
    var baseDrillDownClienteRef = []

    // LOOP NOS USUÁRIOS
    for (let i = 0; i < base.length; i++) {
        
        // DETERMINA A BASE TOTAL DO CLIENTE
        baseCliente = base[i]

        // CRIA UM ARRAY PARA CADA CLIENTE PARA QUE SEJA POSSÍVEL RANKEAR POR DATAS E EXCLUIR A DATA MAIS ANTIGA
        var baseClienteArray = []
        for (let data in baseCliente) {
            if(data!=="chave"){
                baseCliente[data]["data"] = convertTextoToData(data)
                baseClienteArray.push(baseCliente[data])
            }
        }

        baseClienteArray.sort((a, b) => a.data - b.data);
        baseClienteArray.shift()

        // LOOP NAS DATAS
        for (let j = 0; j < baseClienteArray.length; j++) {

            var data = convertDataToText(baseClienteArray[j]["data"])
            if(data !== "chave"){
                // CRIA O OBJETO PARA CADA DATA
                if(baseCompletaPremios[data]===undefined){
                    baseCompletaPremios[data] = {}
                }
                // CAPTURA O NOME DO CLIENTE
                var nomeCliente = baseClienteArray[j]["Cliente"]
                // CAPTURA O PRÊMIO VS NOTIONAL
                var premio =baseClienteArray[j]["Resultado das Opcoes"]
                var nocional = baseClienteArray[j]["Notional (R$)"]
                var premioPercentual = parseFloat(premio/nocional)*100
                // CARREGA NA BASE COMPLETA
                if(premio !== 0 && nocional !==0){
                    baseCompletaPremios[data][nomeCliente] = premioPercentual
                }
            }   
        }

    }

    // LOOP NAS DATAS
    for (let data in baseCompletaPremios) {

        // VARIÁVEL DE CÁLCULO DAS MÉTRICA ESTATÍSTICAS
        dadosEstatisticosPremios = []

        // LOOP NOS CLIENTES
        for (let cliente in baseCompletaPremios[data]){
            // CAPTURA OS PRÊMIOS ALIMENTANDO O ARRAY  DE CÁLCULO
            dadosEstatisticosPremios.push(baseCompletaPremios[data][cliente])
        }

        // CALCULA-SE OS VALORES ESTATÍSTICOS COM BASE NO ARRAY DE CÁLCULO
        var recorder = new Recorder(5)
        dadosEstatisticosPremios.forEach(recorder.push, recorder)

        var minimo =  recorder.Q(0)
        var Q1 = recorder.Q(0.25)
        var media = recorder.Q(0.5)
        var Q3 = recorder.Q(0.75)
        var maximo = recorder.Q(1)

        // ALIMENTA O ARRAY DE IMPRESSÃO
        dadosGraf.push({
            x:convertTextoToData(data),
            y:[minimo,Q1,Q3,maximo,media]
        })

        // ALIMENTA A LINHA DE REFERÊNCIA DE 3%
        dadosGrafRef.push({
            x:convertTextoToData(data),
            y:3
        })
    }

    // RANKEIA DA MENOR DATA PARA MAIOR
    dadosGraf.sort((a, b) => a.x - b.x);
    dadosGrafRef.sort((a, b) => a.x - b.x);

    // FUNÇÃO QUE CAPTURA TOQUES NO GRÁFICO PRINCIPAL PARA O DE DATA
    function ChartDrilldownHandler(e){

        // ESTRUTURA AS BASES DE DADOS
        baseDrillDownData = []
        baseDrillDownDataRef = []
        var dadosClienteData = JSON.parse(JSON.stringify(baseCompletaPremios[convertDataToText(e.dataPoint.x)]))
        for (let cliente in dadosClienteData){
            // SELECIONA SÓ O PRIMEIRO NOME PARA FACILITAR
            var primeiroNomeCliente = cliente.split(' ')[0]

            // PINTA DE COR DIFERENTE OS CLIENTES QUE ESTÃO ABAIXO DE 3%
            var cor = "rgba(102,205,170,.7)"
            if(dadosClienteData[cliente]<3){
                cor = "rgba(255,191,170,0.7)"
            }
            
            // POPULA OS GRÁFICOS DE IMPRESSÃO
            baseDrillDownData.push({
                color: cor,
                nomeTodo: cliente,
                label:primeiroNomeCliente,
                y: dadosClienteData[cliente]
            })
            baseDrillDownDataRef.push({
                nomeTodo: cliente,
                label:primeiroNomeCliente,
                y: 3
            })
        }

        // ALTERA AS VARIÁVEIS DE ESTADO COMO HOOK
        props.mudaDrillDown("data")
        setDataParaDrillDown(convertDataToText(e.dataPoint.x))
        setDadosDrillDownData(baseDrillDownData)
        setDadosDrillDownDataRef(baseDrillDownDataRef)

    }

    // FUNÇÃO QUE CAPTURA TOQUES NO GRÁFICO DE FOCO EM DATA PARA IMPRIMIR O DE CLIENTE NO TEMPO
    function ChartDrillDownHandlerClientes(e){
        
        // CAPTURA O CLIENTE FILTRADO
        var cliente = e.dataPoint.nomeTodo

        // LOOP NA BASE COMPLETA PARA FILTRAR O CLIENTE
        for (let data in baseCompletaPremios){
            // POPULA OS GRÁFICOS DE IMPRESSÃO
            baseDrillDownCliente.push({
                x: convertTextoToData(data),
                y: baseCompletaPremios[data][cliente]
            })
            baseDrillDownClienteRef.push({
                x: convertTextoToData(data),
                y: 3
            })
        }

        // RANKEIA DA MENOR DATA PARA MAIOR
        baseDrillDownCliente.sort((a, b) => a.x - b.x);
        baseDrillDownClienteRef.sort((a, b) => a.x - b.x);

        // ATUALIZA AS VARIÁVEIS DE ESTADO
        props.mudaDrillDown("cliente")
        setClienteParaDrillDown(cliente.split(' ')[0])
        setDadosDrillDownCliente(baseDrillDownCliente)
        setDadosDrillDownClienteRef(baseDrillDownClienteRef)
    }

    // FUNÇÃO DE NAVEGAÇÃO
    function navegacao(e){
        var nav = e.target.dataset.nav
        props.mudaDrillDown(nav)
    }

    if(drilldown==="geral"){

        // OPÇÕES DE IMPRESSÃO DO GRÁFICO
        const estatisticaPremiosOptions = {
            animationEnabled: true,
            backgroundColor: "transparent",
            title:{
                text: "Distribuição de Prêmios",
                padding:20, //20
                fontSize:20, //20
                fontColor: "rgba(102,205,170,1.0)",
                fontFamily: fonte
            },
            toolTip:{
                shared: true,
                fontColor: "gray",
                fontFamily: fonte,
                contentFormatter: function (e) {        
                    var content = "<strong>" + convertDataToText(e.entries[0].dataPoint.x) +"</strong>" + "<table>"
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color:" + e.entries[0].dataSeries.upperBoxColor + "'>" + "Max: </span></th> " + "<th><span style='color:gray'>" + e.entries[0].dataPoint.y[3].toFixed(2) + "%</span></th>";
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color:" + e.entries[0].dataSeries.upperBoxColor + "'>" + "Q3: </span></th> " + "<th><span style='color:gray'>" + e.entries[0].dataPoint.y[2].toFixed(2) + "%</span></th>";
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color: gray'>" + "Med: </span></th> " + "<th><span style='color:gray'>" + e.entries[0].dataPoint.y[4].toFixed(2) + "%</span></th>";
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color:" + e.entries[0].dataSeries.lowerBoxColor + "'>" + "Q1: </span></th> " + "<th><span style='color:gray'>" + e.entries[0].dataPoint.y[1].toFixed(2) + "%</span></th>";
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color:" + e.entries[0].dataSeries.lowerBoxColor + "'>" + "Min: </span></th> " + "<th><span style='color:gray'>" + e.entries[0].dataPoint.y[0].toFixed(2) + "%</span></th>";
                    content += "</table>"
                    return content;
                }
                
            },
            axisY: {
                title: "",
                prefix: "%",
                gridThickness: 1,
                gridColor:"rgb(200,200,200)",
                gridDashType:"dash",
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte
            },
            axisX: {
                title: "",
                gridThickness: 0,
                intervalType: "month",
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte
            },
            data: [{
                type: "boxAndWhisker",
                click: ChartDrilldownHandler,
                cursor: "pointer",
                upperBoxColor: "rgb(102,205,170)",
                lowerBoxColor: "rgb(255,191,170)",
                whiskerThickness: 1,
                whiskerColor:"gray",
                lineThickness: 1,
                stemThickness:1,
                stemColor:"gray",
                color: "white",
                yValueFormatString: "%0",
                dataPoints: dadosGraf
            },{
                type: "line",
                lineColor: "rgb(0,94,94)",
                lineDashType: "dash",
                lineThickness: 1,
                markerSize:0,
                markerColor: "rgb(0,94,94)",
                dataPoints: dadosGrafRef
            }]
        }

        // RETORNA O JX
        return(
            <div className="estatisticaPremioSection">
                <div className="estatisticaPremiosTrach">
                    <div id="detalheTitulo"><em>Clique</em> para entrar no detalhe</div>
                    <div id="detalheNivel1" className="hide">
                        <span className="linkNivel linkNivelAtivo" onClick={navegacao} data-nav="geral">Geral</span>
                    </div>
                    <div id="detalheNivel2" className="hide">
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span> 
                        <span className="linkNivel" onClick={navegacao} data-nav="data">{dataParaDrillDown}</span>
                    </div>
                    <div id="detalheNivel3" className="hide" onClick={navegacao}>
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span>
                        <span className="linkNivel" onClick={navegacao} data-nav="cliente">{clienteParaDrillDown}</span>
                    </div>
                </div>
                <div className="estatisticaPremioBox">
                    <GraficoLimpo id="graf-estatistica-premios" options = {estatisticaPremiosOptions}/>
                </div>
            </div>
        )

    }else if (drilldown==="data"){

        // OPÇÕES DE IMPRESSÃO DO GRÁFICO
        const estatisticaPremioPorClienteOptions = {
            animationEnabled: true,
            backgroundColor: "transparent",
            title:{
                text: "Distribuição de Prêmios",
                padding:20, //20
                fontSize:15, //20
                fontColor: "rgba(102,205,170,1.0)",
                fontFamily: fonte
            },
            toolTip:{
                shared: true,
                fontColor: "gray",
                fontFamily: fonte,
                contentFormatter: function (e) {       
                    var valor = ""
                    if(e.entries[0].dataPoint.y !== undefined){
                        valor = e.entries[0].dataPoint.y.toFixed(2)
                    }
                    var content = "<strong>" + dataParaDrillDown +"</strong>" + "<table>"
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style = 'color:" + e.entries[0].dataPoint.color + "'>" + e.entries[0].dataPoint.nomeTodo + ": </span></th> " + "<th><span style='color:gray'>" + valor + "%</span></th>";
                    content += "</table>"
                    return content;
                }
                
            },
            axisY: {
                title: "",
                prefix: "%",
                gridThickness: 1,
                gridColor:"rgb(200,200,200)",
                gridDashType:"dash",
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte
            },
            axisX: {
                title: "",
                gridThickness: 0,
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte,
                labelFontSize:10
            },
            data: [{
                type: "column",
                click: ChartDrillDownHandlerClientes,
                cursor: "pointer",
                lineThickness: 10,
                color: "rgb(102,205,170)",
                yValueFormatString: "%0",
                dataPoints: dadosDrillDownData
            },{
                type: "line",
                lineColor: "rgb(0,94,94)",
                lineDashType: "dash",
                lineThickness: 1,
                markerSize:0,
                markerColor: "rgb(0,94,94)",
                dataPoints: dadosDrillDownDataRef
            }]
        }

        // RETORNA O JX
        return(
            <div className="estatisticaPremioSection">
                <div className="estatisticaPremiosTrach">
                <div id="detalheTitulo"><em>Clique</em> para entrar no detalhe</div>
                    <div id="detalheNivel1" className="hide">
                        <span className="linkNivel linkNivelAtivo" data-nav="geral" onClick={navegacao}>Geral</span>
                    </div>
                    <div id="detalheNivel2" className="hide">
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span> 
                        <span className="linkNivel" data-nav="data" onClick={navegacao}>{dataParaDrillDown}</span>
                    </div>
                    <div id="detalheNivel3" className="hide">
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span>
                        <span className="linkNivel" data-nav="cliente" onClick={navegacao}>{clienteParaDrillDown}</span>
                    </div>
                </div>
                <div className="estatisticaPremioBox">
                    <GraficoLimpo id="graf-estatistica-premiosPorCiente" options = {estatisticaPremioPorClienteOptions}/>
                </div>
            </div>
        )
        
    }else if (drilldown==="cliente"){

        // OPÇÕES DE IMPRESSÃO DO GRÁFICO
        const estatisticaPremioDetalheClienteOptions = {
            animationEnabled: true,
            backgroundColor: "transparent",
            title:{
                text: "Distribuição de Prêmios",
                padding:20, //20
                fontSize:20, //20
                fontColor: "rgba(102,205,170,1.0)",
                fontFamily: fonte
            },
            toolTip:{
                shared: true,
                fontColor: "gray",
                fontFamily: fonte,
                contentFormatter: function (e) {       
                    var valor = ""
                    var dia = ""
                    if(e.entries[0].dataPoint.y !== undefined){
                        valor = e.entries[0].dataPoint.y.toFixed(2)
                    }
                    if(e.entries[0].dataPoint.x){
                        dia = convertDataToText(e.entries[0].dataPoint.x)
                    }
                    var content = "<strong>" + dia +"</strong>" + "<table>"
                    content += "<tr/>";
                    content += "<th style='padding-right:30px'><span style='color:rgb(102,205,170)'>" + clienteParaDrillDown + ": </span></th> " + "<th><span style='color:gray'>" + valor + "%</span></th>";
                    content += "</table>"
                    return content;
                }
                
            },
            axisY: {
                title: "",
                prefix: "%",
                gridThickness: 1,
                gridColor:"rgb(200,200,200)",
                gridDashType:"dash",
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte
            },
            axisX: {
                title: "",
                gridThickness: 0,
                lineColor: "rgb(200,200,200)",
                tickColor: "rgb(200,200,200)",
                labelFontColor: "gray",
                labelFontFamily: fonte,
                labelFontSize:10
            },
            data: [{
                type: "line",
                lineThickness: 1,
                color: "rgb(102,205,170)",
                yValueFormatString: "%0",
                dataPoints: dadosDrillDownCliente
            },{
                type: "line",
                lineColor: "rgb(0,94,94)",
                lineDashType: "dash",
                lineThickness: 1,
                markerSize:0,
                markerColor: "rgb(0,94,94)",
                dataPoints: dadosDrillDownClienteRef
            }]
        }

        // RETORNA O JX
        return(
            <div className="estatisticaPremioSection">
                <div className="estatisticaPremiosTrach">
                <div id="detalheTitulo"><em>Clique</em> para entrar no detalhe</div>
                    <div id="detalheNivel1" className="hide">
                        <span className="linkNivel linkNivelAtivo" data-nav="geral" onClick={navegacao}>Geral</span>
                    </div>
                    <div id="detalheNivel2" className="hide">
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span> 
                        <span className="linkNivel" data-nav="data" onClick={navegacao}>{dataParaDrillDown}</span>
                    </div>
                    <div id="detalheNivel3" className="hide">
                        <span className="material-symbols-outlined seta">arrow_forward_ios</span>
                        <span className="linkNivel" data-nav="cliente" onClick={navegacao}>{clienteParaDrillDown}</span>
                    </div>
                </div>
                <div className="estatisticaPremioBox">
                    <GraficoLimpo id="graf-estatistica-premiosDetalheCiente" options = {estatisticaPremioDetalheClienteOptions}/>
                </div>
            </div>
        )
        
    }

}

export default EstatisticaPremios