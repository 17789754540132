import '../Home/Home.css'
import '../../index.css'
import './Admin.css'
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import Accordion from 'react-bootstrap/Accordion';
import CampoForm from '../../componentes/CampoForm';
import { useState, useEffect, useRef} from 'react';
import validaItem from '../../componentes/CampoForm/validaForm';
import ModalApp from '../../componentes/Modal';
import { filterData, SearchType } from 'filter-data';
import {convertDataToText, convertTextoToData} from './convertData';
import { sum } from './concatenaDados';
import Carteira from '../../componentes/Carteira';
import GraficoLimpo from '../../componentes/GraficoLimpo';
import EstatisticaPremios from '../../componentes/EstatisticaPremios';
import PerformanceTrack from '../../componentes/PeformanceContratos';
import PainelClientesAdmin from '../../componentes/TabelaAdminClientes';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Admin = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // VARIÁVEL DE CORES PARA OS GRÁFICOS DEVIDOS
    var cores = [
        "rgb(102,205,170)",
        "rgb(0,139,139)",
        "rgb(0,94,94)",
        "#5ec9cc",
        "rgb(100,100,100)",
        "rgb(200,200,200)"
    ]

    // VARIÁVEIS DO MODAL
    const [modalShow, setModalShow] = useState(false)
    const [modalTitulo, setModalTitulo] = useState("")
    const [modalCorpo, setModalCorpo] = useState("")
    const [modalTipo, setModalTipo] = useState("")

    var titulo = ""
    var corpo = ""

    function callModal(props){
        setModalTitulo(titulo);
        setModalCorpo(corpo);
        setModalTipo(props);
        setModalShow(true);
    }

    // VARIÁVEIS PARA FILTRO
    const [baseInteiraData, setBaseInteiraData] = useState(true) // VARIÁVEL QUE INDICA SE A BASE ESTÁ COM TODOS OS DADOS NO FIREBASE OU FILTRADA POR DATA
    
    // VARIÁVEIS PARA CAPTURA DOS VALORES DE INPUT QUE NÃO SÃO VARIÁVEIS DE ESTADO
    const dataInicioRef = useRef();
    const dataFimRef = useRef();
    const patrimonioMinimoRef = useRef();
    const patrimonioMaximoRef = useRef();
    const filtroClienteRef = useRef();

    // VARIÁVEIS DE DRILLDOWN DA ESTATÍSTICA
    const [drillDownEstatistica, setDrillDownEstatistica] = useState("geral")

    // VARIÁVEIS DE IMPRESSÃO DOS GRÁFICOS
    const dadosImpressaoAdmin =[];

    // DETERMINA OS VALORES PADRÕES PARA AS BASES COMO CÓPIAS NÃO CONECTADAS
    var baseUsersFiltradaTemp = props.baseDadosTotalUsers.map(object => ({ ...object }))
    var baseDadosFiltradaTemp = props.baseDadosTotal.map(object => ({ ...object }))
    var baseDadosConcatenadaTemp = []

    // VARIÁVEIS PARA CAPTURA DA ÚLTIMA DATA ADICIONADA NA BASE
    var dataMaisAntiga = null
    var dataMaisAntigaTexto = ""

    // ADICIONA AS PROPRIEDADES NECESSÁRIAS PARA FILTRO DE CLIENTES
    baseUsersFiltradaTemp.forEach((user, index) => {
        var patrimonioUser = 0;
        // CAPTURA A DATA MAIS ANTIGA PARA EXTRAIR AS VARIÁVEIS DA BASE DADOS MAIS ATUALIZADAS
        baseDadosFiltradaTemp.forEach(dados => {
            var dataAnterior = new Date(1500,1,1)
            if(user.chave === dados.chave){
                // LISTA AS DATAS NO ARRAY DADOS E CAPTURA A DATA MAIS ANTIGA E A PRIMEIRA DATA
                const datas = Object.keys(dados)
                datas.forEach(dataTexto => {
                    if(dataTexto === "chave"){}else{

                        var data = convertTextoToData(dataTexto)

                        if(data < dataAnterior){
                            dataMaisAntiga = dataAnterior
                        }else{
                            dataMaisAntiga = data
                        }

                        dataAnterior = dataMaisAntiga

                    }
                })

                // CONVERTE A DATA MAIS ANTIGA PARA TEXTO
                dataMaisAntigaTexto= convertDataToText(dataMaisAntiga)
                
                // CAPTURA O PATRIMÔNIO DA DATA MAIS ANTIGA
                patrimonioUser = dados[dataMaisAntigaTexto]["PatrimonioAtual"]
                baseUsersFiltradaTemp[index]["PatrimonioAtual"] = patrimonioUser

            }
        })

        // ADICIONA A PROPRIEDADE SELECTED
        baseUsersFiltradaTemp[index]["Selected"] = false
    })

    // RANKEIA A BASE USERS EM ORDEM ALFABÉTICA PELO NOME
    
    baseUsersFiltradaTemp.sort((a, b) => a.nome.localeCompare(b.nome));

    // VARIÁVEIS DE ESTADO DAS BASES DE USUÁRIOS E DADOS FILTRADAS
    const [baseUsersFiltrada, setBaseUsersFiltrada] = useState(baseUsersFiltradaTemp)
    const [baseDadosFiltrada, setBaseDadosFiltrada] = useState(baseDadosFiltradaTemp)
    const [baseClientesSelecionados, setBaseClientesSelecionados] = useState(baseUsersFiltradaTemp)

    // LISTENER DE MUDANÇA NA BASE DE USERS TODA VEZ QUE UM NOVO CLIENTE FOR CLICADO
    useEffect(() =>{
        submitFiltro()

    },[baseClientesSelecionados])

    // VARIÁVEL DE PADRÃO DE FILTRO
    var searchConditions = null

    // VARIÁVEL DE ESTADO ARRAY PARA CAPTURAR FILTRO DOS CONSULTORES
    var baseConsultoresCheckbox = []
    props.baseConsultores.forEach(element => {
        element["checked"] = true
        baseConsultoresCheckbox.push(element)
    })

    const [consultorChaves, setConsultorChaves] = useState(baseConsultoresCheckbox);

    // FUNÇÃO DE OCULTAR CLIENTES
    const esconderClientes = (estado) => {

        if(estado.target.getAttribute("data-hide-clientes")){

            const btnViewClientes = document.querySelectorAll("[data-hide-clientes]")
            const listaClientes = document.querySelectorAll("[data-clientes]");

            if(estado.target.getAttribute("data-hide-clientes") === "visivel"){

                for (let contador = 0; contador < btnViewClientes.length; contador++) {
                    btnViewClientes[contador].innerHTML = "visibility_off"
                    btnViewClientes[contador].dataset.hideClientes = "invisivel"
                }

                for (let contador = 0; contador < listaClientes.length; contador++) {
                    listaClientes[contador].classList.add('hide')
                }


            }else if(estado.target.getAttribute("data-hide-clientes") === "invisivel"){

                for (let contador = 0; contador < btnViewClientes.length; contador++) {
                    btnViewClientes[contador].innerHTML = "visibility"
                    btnViewClientes[contador].dataset.hideClientes = "visivel"
                }

                for (let contador = 0; contador < listaClientes.length; contador++) {
                    listaClientes[contador].classList.remove('hide')
                }

            }

        }
    }

    // FUNÇÃO DE FIXAR LISTA DE CLIENTES
    const fixarClientes = (estado) => {

        if(estado.target.getAttribute("data-fix-clientes")){

            const btnFixClientes = document.querySelectorAll("[data-fix-clientes]")
            const listaClientesBox = document.querySelectorAll("[data-lista-clientes]");

            if(estado.target.getAttribute("data-fix-clientes") === "notFixed"){

                btnFixClientes[0].innerHTML = "keep_off"
                btnFixClientes[0].dataset.fixClientes = "fixed"
                listaClientesBox[0].classList.add('lista-box-fixed')

            }else if(estado.target.getAttribute("data-fix-clientes") === "fixed"){

                btnFixClientes[0].innerHTML = "keep"
                btnFixClientes[0].dataset.fixClientes = "notFixed"
                listaClientesBox[0].classList.remove('lista-box-fixed')

            }

        }
    }

    // FUNÇÃO QUE FILTRA
    const submitFiltro = (evento) => {

        // PREVINE A AÇÃO NATURAL DO BOTÃO DE SUBMIT
        if(evento){
            evento.preventDefault()
        }

        // POPULA AS VARIÁVEIS PARA FILTRO
        var dataInicio = dataInicioRef.current.value;
        var dataFim = dataFimRef.current.value;
        var patrimonioMinimo = patrimonioMinimoRef.current.value;
        var patrimonioMaximo = patrimonioMaximoRef.current.value;
        var filtroCliente = filtroClienteRef.current.value

        // CAPTURA O BOTÃO DE INPUT DO FORMULÁRIO
        const form = document.getElementById("form-cadastro-profile")
        var listaInputFormulario = form.querySelectorAll("input")

        // LOOP EM TODOS OS CAMPOS PARA VALIDAÇÃO PADRÃO
        for (let contador = 0; contador < listaInputFormulario.length; contador++) {
            var input = listaInputFormulario[contador]

            // RETORNO DOS CASOS DE CAMPOS NÃO PREENCHIDOS
            if (validaItem(input) === "required false"){
                titulo = "Formulário Incompleto!"
                corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> não foi preenchido, favor revisar</p>
                        <p>Os mesmos estão indicados por um (*)</p>
                    </div>
                callModal("")
                return false

            // RETORNO DOS CASOS DE CAMPOS COM TIPOS DIFERENTES DO DEVIDO
            }else if(validaItem(input) === "type false"){
                titulo = "Valor incoerente!"
                corpo = 
                    <div className="">
                        <p>O campo <strong>{input.dataset.campo}</strong> ossui um padrão de preenchimento que não foi obedecido, favor revisar</p>
                        <p>Ex.: E-mail que precisa conter @</p>
                    </div>
                callModal("")
                return false

            }else{


            }

        }

        // SETA O PADRÃO PARA VARIÁVEL QUE INDICA QUE ELA AINDA NÃO FOI FILTRADA
        setBaseInteiraData(true)

        // VALIDA SE OS CAMPOS DE DATA ESTÃO PREENCHIDOS E APLICA OS FILTROS DE ACORDO NAS BASES DE DADOS E NA DE USUÁRIOS
        if((dataInicio ===null || dataInicio === "" ) && (dataFim === null || dataFim === "")){
        }else if(dataFim > dataInicio || (dataInicio !== "" && dataFim === "") || (dataInicio === "" && dataFim !== "")){

            // FILTRA A BASE DA ACORDO COM A DATA INICIAL
            if(dataInicio === null || dataInicio === "" ){}else{
                baseDadosFiltradaTemp.forEach((dado, index)=>{
                    for (const key in dado) {
                        if(key !== "chave" && convertTextoToData(key) < convertTextoToData(dataInicio)){
                            delete baseDadosFiltradaTemp[index][key]; // EXCLUI DA BASE AS DATAS FORA DO INTERVALO
                            setBaseInteiraData(false) // COMO AO MENOS UMA DATA FOI EXCLUÍDA, É SINAL DE QUE OS DADOS NÃO ESTÃO MAIS COMPLETOS
                        }
                    }
                })
                /*baseUsersFiltradaTemp.forEach((dado,index)=>{
                    if(convertTextoToData(dado.dataCriacao) < convertTextoToData(dataInicio)){
                        delete baseUsersFiltradaTemp[index]
                    }
                })*/
            }

            // CRIA UMA CÓPIA NÃO CONECTADA DA BASE
            baseUsersFiltradaTemp = baseUsersFiltradaTemp.filter(function (el) {
                return el != null;
            })   

            // FILTRA A BASE DA ACORDO COM A DATA FINAL
            if(dataFim === null || dataFim === ""){}else{
                baseDadosFiltradaTemp.forEach((dado, index)=>{
                    for (const key in dado) {
                        if(key !== "chave" && convertTextoToData(key) > convertTextoToData(dataFim)){
                            delete baseDadosFiltradaTemp[index][key];
                        }
                    }
                })
                baseUsersFiltradaTemp.forEach((dado,index)=>{

                    var dataObjcri = dado.dataCriacao.split("-")
                    var anocri = parseInt(dataObjcri[0]) 
                    var mescri = parseInt(dataObjcri[1])

                    var dataObjfim = dataFim.split("-")
                    var anofim = parseInt(dataObjfim[0]) 
                    var mesfim = parseInt(dataObjfim[1])
                
                    var textoDataCriacao = "1" + "-" + mescri + "-" + anocri
                    var textoDataFim = "1" + "-" + mesfim + "-" + anofim
                    if(convertTextoToData(textoDataCriacao) >= convertTextoToData(textoDataFim)){
                        delete baseUsersFiltradaTemp[index]
                    }
                })
            }
            baseUsersFiltradaTemp = baseUsersFiltradaTemp.filter(function (el) {
                return el != null;
            });   

        }else{

            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>O campo <strong>Data Fim</strong></p>
                    <p>Devem ser maior do que os campo <strong>Data Início</strong></p>
                </div>
            callModal("")
            return false

        }

        // ADICIONA AS PROPRIEDADES NECESSÁRIAS PARA FILTRO DE PATRIMÔNIO NA BASE USERS
        baseUsersFiltradaTemp.forEach((user, index) => {
            var patrimonioUser = 0;
            // CAPTURA A DATA MAIS ANTIGA PARA EXTRAIR AS VARIÁVEIS DA BASE DADOS MAIS ATUALIZADAS
            baseDadosFiltradaTemp.forEach(dados => {
                var dataMaisAntiga = null
                var dataMaisAntigaTexto = ""
                var dataAnterior = new Date(1500,1,1)
                if(user.chave === dados.chave){
                    // LISTA AS DATAS NO ARRAY DADOS E CAPTURA A DATA MAIS ANTIGA
                    const datas = Object.keys(dados)
                    datas.forEach(dataTexto => {
                        if(dataTexto === "chave"){}else{
                            var data = convertTextoToData(dataTexto)
                            
                            if(data < dataAnterior){
                                dataMaisAntiga = dataAnterior
                            }else{
                                dataMaisAntiga = data
                            }

                            dataAnterior = dataMaisAntiga
                        }
                    })

                    // CONVERTE A DATA MAIS ANTIGA PARA TEXTO
                    dataMaisAntigaTexto= convertDataToText(dataMaisAntiga)
                    
                    // CAPTURA O PATRIMÔNIO DA DATA MAIS ANTIGA
                    patrimonioUser = dados[dataMaisAntigaTexto]["PatrimonioAtual"]
                    baseUsersFiltradaTemp[index]["PatrimonioAtual"] = patrimonioUser   

                }
            })
        })

        // VERIFICA SE OS CAMPOS DE PATRIMÔNIO ESTÃO COERENTES E APLICA OS FILTROS DE ACORDO
        if((patrimonioMaximo == null || patrimonioMaximo === "") && (patrimonioMinimo == null || patrimonioMinimo === "")){
        }else if((parseFloat(patrimonioMaximo) < parseFloat(patrimonioMinimo))){

            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os campos <strong>Patrimônio Máximo e Data Fim</strong></p>
                    <p>Devem ser maiores do que os campos <strong>Patrimônio Mínimo e Data Início</strong> respectivamente</p>
                </div>
            callModal("")
            return false

        }else{

            if(patrimonioMinimo == null || patrimonioMinimo === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'PatrimonioAtual',
                    value: patrimonioMinimo,
                    type: SearchType.GTE,
                    },
                ];

                baseUsersFiltradaTemp = filterData(baseUsersFiltradaTemp, searchConditions);
            }

            if(patrimonioMaximo == null || patrimonioMaximo === ""){}else{
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'PatrimonioAtual',
                    value: patrimonioMaximo,
                    type: SearchType.LTE,
                    },
                ];

                baseUsersFiltradaTemp = filterData(baseUsersFiltradaTemp, searchConditions);
            }

        }

        // FILTRO DE CONSULTORES NA BASE USERS
        consultorChaves.forEach((user,index) => {

            if(user.checked === false){
                // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
                searchConditions = [
                    {
                    key: 'consultor',
                    value: parseFloat(user.chave),
                    type: SearchType.NE,
                    },
                ];

                baseUsersFiltradaTemp = filterData(baseUsersFiltradaTemp, searchConditions);
            }

        })

        // FILTRO DE CLIENTES
        searchConditions = [
            {
            key: ['nome', 'sobrenome'],
            value: filtroCliente,
            type: SearchType.LK,
            },
        ];

        baseUsersFiltradaTemp = filterData(baseUsersFiltradaTemp, searchConditions);

        // FILTRO NA BASE DE DADOS DE ACORDO COM OS USUÁRIOS RESTANTE NA BASE USERS 
        baseDadosFiltradaTemp.forEach((dado,index)=>{
            var remove = true
            baseUsersFiltradaTemp.forEach((user,index)=>{
                if(dado.chave === user.chave){
                    remove = false
                }
            })
            if(remove === true){
                delete baseDadosFiltradaTemp[index]
            }
        })

        // CRIA UMA CÓPIA
        baseDadosFiltradaTemp = baseDadosFiltradaTemp.filter(function (el) {
            return el != null;
        });

        // FILTRA DE ACORDO COM OS CLIENTES SELECIONADOS
        var novaBase = []
        var algumFiltro = false

        // LOOP NA BASE DE USUÁRIOS
        for(let j = 0; j < baseClientesSelecionados.length; j++){

            if(baseClientesSelecionados[j]["Selected"]==true){

                // LOOP NA BASE USERS PARA ALTERAR AS PROPRIEDADES SELECTED
                for (let i = 0; i < baseUsersFiltradaTemp.length; i++){
                    // SE OS USUÁRIOS FOREM IGUAIS, ALTERA A PROPRIEDADE SELECTED
                    if(baseClientesSelecionados[j]["chave"] == baseUsersFiltradaTemp[i]["chave"]){
                        if(baseClientesSelecionados[j]["Selected"]==true){
                            baseUsersFiltradaTemp[i]["Selected"] = true
                        }else{
                            baseUsersFiltradaTemp[i]["Selected"] = false
                        }
                    }
                }

                // ALTERA A VARIÁVEL QUE INDICA QUE ALGUM FILTRO EXISTE
                algumFiltro = true

                // LOOP NA BASE DE USUÁRIOS E INCLUSÃO NA BASE FILTRADA
                for (let i = 0; i < baseDadosFiltradaTemp.length; i++) {

                    // SE OS USUÁRIOS FOREM IGUAIS, ADICIONA NA NOVA BASE
                    if(baseClientesSelecionados[j]["chave"] == baseDadosFiltradaTemp[i]["chave"]){
                        novaBase.push(baseDadosFiltradaTemp[i])
                    }

                }

            }

        }
          
        // ALTERA AS VARIÁVEIS DE ESTADO
        //console.log(Object.keys(novaBase[0]).length)
        if(baseDadosFiltradaTemp.length === 0 || baseUsersFiltradaTemp.length === 0){
            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>Os filtros estão retornando uma base sem dados</p>
                    <p>Por favor reveja os critérios inseridos e tente novamente</p>
                </div>
            callModal("")
            return false
        }else{

            if(algumFiltro === true){
                // ALTERA A VARIÁVEL DE ESTADO DA BASE FILTRADA
                setBaseDadosFiltrada(novaBase)
            }else{
                setBaseDadosFiltrada(baseDadosFiltradaTemp)
            }
            setBaseUsersFiltrada(baseUsersFiltradaTemp)

            // RETORNA O GRÁFICO DE ESTATÍSTICA DE DRILLDOWN PARA TELA INICIAL
            setDrillDownEstatistica("geral")
        }
        
    }

    // FUNÇÃO RESET
    const resetFiltro = (eventoReset) => {
        if(eventoReset){
            eventoReset.preventDefault()
        }

        dataInicioRef.current.value = null
        dataFimRef.current.value = null
        patrimonioMinimoRef.current.value = null
        patrimonioMaximoRef.current.value = null
        filtroClienteRef.current.value = null

        
        // ALTERA AS VARIÁVEIS DE ESTADO DE ACORDO
        setConsultorChaves(baseConsultoresCheckbox)
        setBaseDadosFiltrada(baseDadosFiltradaTemp)
        setBaseUsersFiltrada(baseUsersFiltradaTemp)
        setBaseClientesSelecionados(baseUsersFiltradaTemp)
        setBaseInteiraData(true)

        // RETORNA O GRÁFICO DE ESTATÍSTICA DE DRILLDOWN PARA TELA INICIAL
        setDrillDownEstatistica("geral")

    }

    // FUNÇÃO QUE FILTRA CLIENTES
    function filtraCliente(cliente){
        // GERA UM ARQUIVO CÓPIA DA BASE USERS FILTRADA
        var baseCopia = JSON.parse(JSON.stringify(baseClientesSelecionados))

        // LOOP QUE VARRE O A BASE PARA DAR MATCH COM CLIENTE CLICADO
        // ALTERA A PROPRIEDADE SELECTED DO ITEM CLICADO
        for (let i = 0; i < baseCopia.length; i++) {
            if(baseCopia[i]["chave"]===cliente){
                if(baseCopia[i]["Selected"]==false){
                    baseCopia[i]["Selected"] = true
                }else{
                    baseCopia[i]["Selected"] = false
                }
            }
        }

        // ALTERA A VARIÁVEL DE ESTADO DE REFRÊNCIA DOQ ESTÁ SELECIONADO OU NÃO
        setBaseClientesSelecionados(baseCopia)

    }

    // LOOP NA BASE USERS PARA CAPTURAR AS INFORMAÇÕES NECESSÁRIAS DE IMPRESSÃO
    var nClientes = baseUsersFiltrada.length
    var patrimonioTotal = 0
    var dadosClientesNoTempoTemp = []
    var dadosClientesNoTempo = []
    var dadosNotionalNoTempo =[]
    var objetoNumeroDeClientesPorData = {}
    baseUsersFiltrada.forEach((user,index)=>{
        // PATRIMONIO MÉDIO
        if(parseFloat(user["PatrimonioAtual"])){
            patrimonioTotal = parseFloat(user["PatrimonioAtual"]) + patrimonioTotal
        }else{

        }

        // DADOS PARA GRÁFICO DE CLIENTES NO TEMPO
        var numeroDeClientesPorData = 0

        // TRAZ OS DADOS PARA REFERÊNCIA MENSAL
        var dataCriacao = convertTextoToData(user.dataCriacao)
        var dataMesCriacao = new Date(dataCriacao.getFullYear(), dataCriacao.getMonth(), 1)

        baseUsersFiltrada.forEach((user2,index2)=>{

            // TRAZ OS DADOS PARA REFERÊNCIA MENSAL
            var dataCriacao2 = convertTextoToData(user2.dataCriacao)
            var dataMesCriacao2 = new Date(dataCriacao2.getFullYear(), dataCriacao2.getMonth(), 1)

            // CAPTURA O NÚMERO DE CLIENTES QUE ENTRARAM EM DETERMINADA DATA
            if(convertDataToText(dataMesCriacao) === convertDataToText(dataMesCriacao2)){
                numeroDeClientesPorData = numeroDeClientesPorData + 1
                objetoNumeroDeClientesPorData[convertDataToText(dataMesCriacao)] = numeroDeClientesPorData
            }

        })
        
    })

    // LOOP NA BASE DE DADOS PARA SOMAR E CONCATENAR OS VALORES
    baseDadosFiltrada.forEach((user, index) => { 

        // SE FOR O PRIMEIRO USER
        if(index === 0){
            baseDadosConcatenadaTemp = JSON.parse(JSON.stringify(user))
        }else{

            // MAPEIA TODAS AS DATAS DA BASE DO USUÁRIO
            for (const date in user){
                if(date === "chave"){}else{
                    baseDadosConcatenadaTemp[date] = JSON.parse(JSON.stringify(sum(baseDadosConcatenadaTemp[date], user[date])))
                }
            }

        }

    })

    delete baseDadosConcatenadaTemp.chave

    // GRÁFICO DE CLIENTES NO TEMPO
    for (const key in objetoNumeroDeClientesPorData) {
        dadosClientesNoTempoTemp.push({
            x: convertTextoToData(key),
            y: objetoNumeroDeClientesPorData[key]
        })
    }
    dadosClientesNoTempoTemp.sort((a, b) => a.x - b.x);
    var clientesAcumulado = 0
    var dataPrimeira = null
    var dataAnterior = new Date(3000,1,1)

    // DETERMINA A PRIMEIRA DATA DA BASE PARA FILTRO DO GRÁFICO CLIENTES
    // CONSTRÓI TAMBÉM A CURVA DE NOTIONAL NO TEMPO
    for(const dados in baseDadosConcatenadaTemp){
        // DETERMINA A PRIMEIRA DATA PARA BASE FILTRO DO GRÁFICO DE CLIENTES
        var data = convertTextoToData(dados)
        if(data > dataAnterior){
            dataPrimeira = dataAnterior
        }else{
            dataPrimeira = data
        }
        dataAnterior = dataPrimeira

        // CONSTRÓI A BASE DE NOTIONAL
        dadosNotionalNoTempo.push({
            x: new Date(data.getFullYear(), data.getMonth()+1, 1),
            y: baseDadosConcatenadaTemp[dados]["Notional (R$)"]
        })
    }

    // RANKEIA A BASE DE NOTIONAL NO TEMPO
    dadosNotionalNoTempo.sort((a, b) => a.x - b.x);

    // CONSTRÓI A BASE PARA O GRÁFICO DE CLIENTES
    for (let i = 0; i < dadosClientesNoTempoTemp.length; i++) {
        clientesAcumulado = clientesAcumulado + dadosClientesNoTempoTemp[i]["y"]
        data = dadosClientesNoTempoTemp[i]["x"]
        if(data > dataPrimeira){
            dadosClientesNoTempo.push({
                x: dadosClientesNoTempoTemp[i]["x"],
                y: clientesAcumulado
            })
        }
    }

    // CÁLCULO E IMPRESSÃO DE PATRIMÔNIO MÉDIO
    var patrimonioMedio = patrimonioTotal/nClientes

    let RSLocale = Intl.NumberFormat('pt-BR');
    dadosImpressaoAdmin.push({
        nClientes: nClientes,
        patrimonioMedio:"R$ " + RSLocale.format(parseInt(patrimonioMedio)),
    })

    // DADOS PARA GRÁFICO DE DISTRIBUIÇÃO DE CLIENTES POR CONSULTOR
    var dadosDistribuicaoCLientes = []
    props.baseConsultores.forEach((consultor,index)=>{
        // CONSTRÓI A BASE DE PARÂMETROS DE FILTRO DE PATRIMÔNIO MÍNIMO
        searchConditions = [
            {
            key: 'consultor',
            value: parseInt(consultor.chave),
            type: SearchType.EQ,
            },
        ];

        var baseClientesConsultor = filterData(baseUsersFiltrada, searchConditions);
        var quantidadeClientes = baseClientesConsultor.length
        dadosDistribuicaoCLientes.push({
            y: quantidadeClientes,
            label: consultor.nome,
            color: cores[(parseInt(consultor.chave)-1)]
        })
    })

    // OPÇÕES PARA O GRÁFICO DE DIVISÃO ENTRE GESTORES
    const optionsGestores = {
        backgroundColor: "transparent",
        animationEnabled: true,
        height: 230,
		title:{
			text: "Distribuição de Clientes",
			padding:10,
			fontSize:15, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
	    toolTip:{
	        enabled: true,
            shared: true,
            fonteFamily: fonte
	      },
		data: [{
			type: "doughnut",
			startAngle: 60,
			radius: "100%",
			innerRadius: "85%",
			indexLabelFontSize: 12,
			indexLabelMinWidth: 20,
            indexLabelFontFamily: fonte,
			indexLabelWrap: true, 
			indexLabel: "{label}  (#percent%)",
			toolTipContent: "<b>{label}:</b>(#percent%)",
			indexLabelFontColor: "rgb(100,100,100)",
			dataPoints: dadosDistribuicaoCLientes
		}]
    }

    // OPÇÕES PARA O GRÁFICO DE NOVOS CLIENTES
    const optionsNovosClientes = {
		animationEnabled: true,
		backgroundColor: "transparent",
        dataPointMaxWidth: 10,
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign:"top",
            fontFamily: fonte
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'>" + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
            
        },
		title:{
			text: "Evolução Clientes",
			padding:10, //20
			fontSize:15, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisY2: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lineThickness: 0.5,
            labelFontSize:10,
            intervalType: "month",
            labelFontFamily: fonte,
            valueFormatString:"MMM YYYY"
		},
		data: [{
                type: "stepLine",
                axisYType:"primary",
                name: "N° Clientes",
                showInLegend: false,
                legendText: "N° Clientes",
                indexLabel: "{y}",
                indexLabelFontColor:"rgba(150,150,150,1.0)",
                indexLabelFontFamily: fonte,
                color: "rgba(0,139,139,.7)",
                markerSize: 10,
                xValueFormatString: "DD/MM/YYYY",
                dataPoints: dadosClientesNoTempo
            },{
                type: "column",
                axisYType: "secondary",
                name: "Notional",
                showInLegend: false,
                legendText: "Notional",
                color: "rgba(102,205,170,.4)",
                xValueFormatString: "DD/MM/YYYY",
                dataPoints: dadosNotionalNoTempo
            },
        ]
    }

    // FUNÇÃO QUE NÃO FAZ NADA
    function naoAltera(E){
        return (E)
    }

    //JSX
    return(
        <main>
            <section className="filtros container-md">
            <ModalApp tipo ={modalTipo} show={modalShow} change={setModalShow} titulo={modalTitulo} corpo ={modalCorpo}/>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Filtros</Accordion.Header>
                        <Accordion.Body>
                            <form id="form-cadastro-profile" data-form="CadastroPerfil">
                                <div className="row">
                                    <CampoForm
                                        ref = {dataInicioRef} 
                                        aoAlterado = {naoAltera}
                                        nomeCampo= "Data Início" 
                                        campoId="data-inicio" 
                                        tipo="date" 
                                        dataCampo="dataInicio" 
                                        placeholder="" 
                                        dataMask="" 
                                        required= {false}  
                                        largura= "6"
                                    />
                                    <CampoForm
                                        ref = {dataFimRef} 
                                        aoAlterado = {naoAltera}
                                        nomeCampo= "Data Fim" 
                                        campoId="data-fim" 
                                        tipo="date" 
                                        dataCampo="dataFim" 
                                        placeholder="" 
                                        dataMask= ""
                                        required= {false}  
                                        largura= "6"
                                    />
                                    <CampoForm 
                                        ref = {patrimonioMinimoRef}
                                        aoAlterado = {naoAltera}
                                        nomeCampo= "Patrimônio Mínimo" 
                                        campoId="patrimonio-minimo" 
                                        tipo="text" 
                                        dataCampo="patrimonioMinimo" 
                                        placeholder="" 
                                        dataMask=""
                                        required= {false}  
                                        largura= "6"
                                    />
                                    <CampoForm 
                                        ref = {patrimonioMaximoRef}
                                        aoAlterado = {naoAltera}
                                        nomeCampo= "Patrimônio Máximo" 
                                        campoId="patrimonio-maximo" 
                                        tipo="text" 
                                        dataCampo="patrimonioMaximo" 
                                        placeholder="" 
                                        dataMask="" 
                                        required= {false}  
                                        largura= "6"
                                    />
                                    <CampoForm 
                                        ref = {filtroClienteRef}
                                        aoAlterado = {naoAltera}
                                        nomeCampo= "Cliente" 
                                        campoId="filtro-cliente" 
                                        tipo="text" 
                                        dataCampo="filtroCliente" 
                                        placeholder="" 
                                        dataMask="" 
                                        required= {false}  
                                        largura= "12"
                                    />
                                    <div className="radio-box">
                                        <div className="radio-title">Consultor</div>
                                        {props.baseConsultores.map((objeto) => (
                                            <CampoForm 
                                                key = {objeto.chave}
                                                checked = {consultorChaves[objeto.chave-1]["checked"]}
                                                valor = {objeto.chave}
                                                aoAlterado = {checked => setConsultorChaves(consultorChaves.map(pr =>{
                                                    if(objeto.chave === pr.chave){
                                                        return {
                                                            chave: pr.chave,
                                                            nome: pr.nome,
                                                            descricao: pr.descricao,
                                                            email: pr.email,
                                                            checked: checked,
                                                            imagem:pr.imagem,
                                                            telefone: pr.telefone
                                                        }
                                                    }else{
                                                        return pr
                                                    }
                                                }))}
                                                nomeCampo= {objeto.nome}
                                                campoId={objeto.nome} 
                                                tipo="checkbox" 
                                                dataCampo={objeto.nome} 
                                                placeholder="" 
                                                dataMask="" 
                                                required= {false}  
                                                largura= "6"
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 btn-cadastro-box">
                                        <button type="reset" className="btn btn-gray btn-reset" id="btn-reset" data-campo="resetForm" onClick={resetFiltro}>Reset</button>
                                        <button type="submit" className="btn btn-primary btn-cadastro margin-left" id="btn-filtro" data-campo="submitForm" onClick={submitFiltro}>Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>
            <section className="minha-conta container-md" ref={props.secaoNossosClientes}>
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">group</span>Nossos Clientes
                        </div>
                    </div>
                </h1>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="listaTitulo box-shadow sub-titulo-group">
                            Clientes
                            <div>
                                <span className="material-symbols-outlined symbol pointer" data-hide-clientes="visivel" onClick={(e) => esconderClientes(e)}>visibility</span>
                                <span className="material-symbols-outlined symbol pointer" data-fix-clientes="notFixed" onClick={(e) => fixarClientes(e)}>keep</span>
                            </div>
                        </div>
                        <div data-lista-clientes className="lista-box box-shadow">
                            <div className="lista-box-scroll" data-clientes>
                                <ul className="lista">
                                    {baseUsersFiltrada.map((objeto) => {
                                        var sobrenomes = objeto.sobrenome.split(" ")
                                        var nome = objeto.nome + " " + sobrenomes[sobrenomes.length-1]
                                        if(objeto.Selected==true){
                                            return(<li key={objeto.chave} data-cliente className="clienteSelected" onClick={() => filtraCliente(objeto.chave)}>{objeto.nome}</li>)
                                        }else{
                                            return(<li key={objeto.chave} data-cliente onClick={() => filtraCliente(objeto.chave)}>{nome}</li>)
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="box-shadow saldo-disponivel ajuste-margem">
                            <h3>N° de Clientes</h3>
                            <p>{dadosImpressaoAdmin[0].nClientes}</p>
                        </div>
                        <div className="box-shadow saldo-em-produtos ajuste-margem">
                            <h3>Patrimônio Médio</h3>
                            <p>{dadosImpressaoAdmin[0].patrimonioMedio}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                        <div className="box-shadow box-grafico-gestores">
                            <div id="grafico-gestores" className="grafico-padding">
                                <GraficoLimpo id="graf-proj-gestores" options = {optionsGestores}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="box-shadow box-grafico-clientes box-grafico-transparente">
                            <GraficoLimpo id="graf-proj-novosClientes" options = {optionsNovosClientes}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PainelClientesAdmin baseAdmin={baseDadosFiltrada}/>
                    </div>
                </div>
            </section>
            <Carteira 
                acesso="admin" 
                baseDados={baseDadosConcatenadaTemp} 
                baseInteiraData={baseInteiraData} 
                baseHistoricoAcoes={props.baseHistoricoAcoes}
                secaoPatrimonio = {props.secaoPatrimonio}
                secaoRentabilidade = {props.secaoRentabilidade}
                secaoResultadosMes = {props.secaoResultadosMes}
                secaoIndices = {props.secaoIndices}
                secaoConciliacao = {props.secaoConciliacao}
                secaoCarteira = {props.secaoCarteira}
                secaoMeusPremios = {props.secaoMeusPremios}
            />
            <div className="container-md">
                <EstatisticaPremios base={baseDadosFiltrada} drillDown={drillDownEstatistica} mudaDrillDown={setDrillDownEstatistica}/>
            </div>
            <section className="minha-conta container-md" ref={props.secaoGestaoPerformance}>
                <h1>
                    <div className="sub-titulo-group">
                        <div>
                            <span className="material-symbols-outlined icon-title">bar_chart_4_bars</span>Gestão de Performance
                        </div>
                    </div>
                </h1>
                <PerformanceTrack base={baseDadosFiltrada}/>
            </section>
        </main>
    )
    
}

export default Admin