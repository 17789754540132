import './Home.css'
import '../../index.css'
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import { useState, useRef, useEffect} from 'react';
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../config/firebase';
import Carteira from '../../componentes/Carteira';
import CampoForm from '../../componentes/CampoForm';
import { Accordion } from 'react-bootstrap';
import ModalApp from '../../componentes/Modal';
import { convertDataToText, convertTextoToData } from '../Admin/convertData';
import CardImagem from '../../componentes/CardImagem';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// MÓDULO DO DASHBOARD
const Home = (props) => {

    const dadosConsultor = {}

    // DADOS MESTRES
    const user = props.user;
    const nomeUsuario = props.user.nome;
    const consultor = props.user.consultor

    // VARIÁVEL DATA DE ATUALIZAÇÃ
    var dataAtualizacao = null

    // VARIÁVEIS DE REFERÊNCIA
    const dataInicioRef = useRef();
    const dataFimRef = useRef();
    
    // PUXA OS DADOS DO CONSULTOR DEVIDO
    props.baseConsultores.forEach(element => {
        if(parseInt(element.chave) === consultor){
            dadosConsultor["nome"] = element.nome
            dadosConsultor["email"] = element.email
            dadosConsultor["telefone"] = element.telefone
            dadosConsultor["whatsapp"] = element.telefone
            dadosConsultor["descricao"] = element.descricao
            dadosConsultor["imgBackground"] = element.imagem
        }
    });

    // VARIÁVEIS DO MODAL
    const [modalShow, setModalShow] = useState(false)
    const [modalTitulo, setModalTitulo] = useState("")
    const [modalCorpo, setModalCorpo] = useState("")
    const [modalTipo, setModalTipo] = useState("")

    var titulo = ""
    var corpo = ""

    // VARIÁVEL DE ESTADO
    const [ulrImg, setUlrImg] = useState(null);
    const [baseDados, setBaseDados] = useState(props.baseDados)
    const [dadosFiltrados, setDadosFiltrados] = useState(props.baseDados)
    const [baseInteiraData, setBaseInteiraData] = useState(true) // VARIÁVEL QUE INDICA SE A BASE ESTÁ COM TODOS OS DADOS NO FIREBASE OU FILTRADA POR DATA

    // FUNÇÕES QUE ATRIBUEM AÇÕES A ITENS NO HTML APÓS A PÁGINA SER CARREGADA
    useEffect(() => {

        // BUSCA A IMAGEM NO STORAGE REFERENTE AO CONSULTOR DEVIDO
        // TEVE QUE SER AQUI DENTRO POIS O FIREBASE PRECISA SER INICIADO PRIMEIRO PARA DEPOIS SER POSSÍVEL IR BUSCAR A IMAGEM
        const storage = getStorage(app);
        const pathReference = ref(storage, 'imagens/' + dadosConsultor["imgBackground"]);
        getDownloadURL(ref(storage, pathReference)).then((url) => {
            setUlrImg(url)
        })

        // 👇️ use document.querySelector()
        // should only be used when you can't set a ref prop on the element
        // (you don't have access to the element)

        // CONSTANTES PARA VISUALIZAÇÃO DE OCULTAR DADOS NA TELA
        const btnViewValor = document.querySelectorAll("[data-hide-valor]");
        const listaValores = document.querySelectorAll("[data-valor]");
        
        for (let contador = 0; contador < btnViewValor.length; contador++) {

            const botao = btnViewValor[contador];

            if(botao){

                botao.onclick = function(){
                    esconderValores(botao.dataset.hideValor)
                }

            }

        }

        const esconderValores = (estado) => {
            if(estado){

                if(estado === "visivel"){

                    for (let contador = 0; contador < btnViewValor.length; contador++) {
                        btnViewValor[contador].innerHTML = "visibility_off"
                        btnViewValor[contador].dataset.hideValor = "invisivel"
                    }

                    for (let contador = 0; contador < listaValores.length; contador++) {
                        listaValores[contador].classList.add('hide')
                    }


                }else{

                    for (let contador = 0; contador < btnViewValor.length; contador++) {
                        btnViewValor[contador].innerHTML = "visibility"
                        btnViewValor[contador].dataset.hideValor = "visivel"
                    }

                    for (let contador = 0; contador < listaValores.length; contador++) {
                        listaValores[contador].classList.remove('hide')
                    }

                }

            }
        }
        
    }, []);
    
    // NO CASO DO USUÁRIO AINDA NÃO ESTAR CADASTRADO NA BASE DE DADOS FINCANCEIROS
    if(baseDados===null || Object.keys(baseDados).length < 2){
        return (
            <main>
                <section className="minha-conta container-md">
                    <div className="cadastro-bemvindo">
                        <p><strong>Olá {nomeUsuario}</strong></p>
                        <p>Seja bem vindo a TechUm!</p>
                        <p>Seus dados financeiros ainda não foram adicionados em nossa base de dados.</p>
                        <p>Em breve suas informações estarão disponíveis para monitoramento.</p>
                        <p>Em caso de dúvidas, por favor entre em contato com seu assessor:</p>
                        <p><em>{dadosConsultor.nome}: </em>{dadosConsultor.telefone}</p>
                    </div>
                </section>
            </main>
        )
    }

    // FUNÇÃO QUE VERIFICA S EUM OBJETO É VAZIO
    function objetoVazio(obj) {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop)) return false;
        }
        return true;
    }

    // FUNÇÃO QUE NÃO FAZ NADA
    function naoAltera(E){
        return (E)
    }

    // FUNÇÃO DE CHAMADA DO MODAL
    function callModal(props){
        setModalTitulo(titulo);
        setModalCorpo(corpo);
        setModalTipo(props);
        setModalShow(true);
    }

    // FUNÇÃO RESET
    const resetFiltro = (eventoReset) => {

        if(eventoReset){
            eventoReset.preventDefault()
        }

        dataInicioRef.current.value = null
        dataFimRef.current.value = null
        
        // ALTERA AS VARIÁVEIS DE ESTADO DE ACORDO
        submitFiltro()
    }

    // FUNÇÃO DE SUBMIT DO FILTRO
    const submitFiltro = (evento) => {

        // RESETA A BASE DE DADOS TEMPORÁRIA IGUAL A BASE COMPLETA
        var baseDadosTemp = []
        setBaseInteiraData(true)

        // PREVINE A AÇÃO NATURAL DO BOTÃO DE SUBMIT
        if(evento){
            evento.preventDefault()
        }

        // POPULA AS VARIÁVEIS PARA FILTRO
        var dataInicio = dataInicioRef.current.value;
        var dataFim = dataFimRef.current.value;
        var dataInicioFormatoData = convertTextoToData(dataInicio)
        dataInicioFormatoData = new Date(dataInicioFormatoData.setMonth(dataInicioFormatoData.getMonth()-1))

        // VALIDA SE OS CAMPOS DE DATA ESTÃO PREENCHIDOS E APLICA OS FILTROS DE ACORDO NAS BASES DE DADOS E NA DE USUÁRIOS
        if((dataInicio ===null || dataInicio === "" ) && (dataFim === null || dataFim === "")){
            setDadosFiltrados(baseDados)
        }else if(dataFim > dataInicio || (dataInicio !== "" && dataFim === "") || (dataInicio === "" && dataFim !== "")){
            
            // FILTRA A BASE DA ACORDO COM AS DATAS
            // SE A DATA INICIAL FOR NULA
            if(dataInicio === null || dataInicio === "" ){
                // FILTRA A BASE SE A DATA FINAL NÃO FOR NULA
                if(dataFim === null || dataFim === ""){}else{
                    for (const key in baseDados) {
                        if(key !== "chave"){
                            if(convertTextoToData(key) < convertTextoToData(dataFim)){
                                baseDadosTemp[key] = JSON.parse(JSON.stringify(baseDados[key]))
                            }
                        }
                    }
                }
            // SE A DATA INICIAL NÃO FOR NULA
            }else{
                // SE A DATA FINAL FOR NULA, FILTRA SOMENTE DE ACORDO COM A DATA INICIAL
                if(dataFim === null || dataFim === ""){
                    for (const key in baseDados) {
                        if(key !== "chave"){
                            if(convertTextoToData(key) > dataInicioFormatoData){
                                baseDadosTemp[key] = JSON.parse(JSON.stringify(baseDados[key]))
                            }else{
                                setBaseInteiraData(false) // COMO AO MENOS UMA DATA FOI EXCLUÍDA, É SINAL DE QUE OS DADOS NÃO ESTÃO MAIS COMPLETOS
                            }
                        }
                    }
                // SE A DATA FINAL NÃO FOR NULA, FILTRA DE ACORDO COM AS DUAS DATAS
                }else{
                    for (const key in baseDados) {
                        if(key !== "chave"){
                            if(convertTextoToData(key) > dataInicioFormatoData){
                                if(convertTextoToData(key) < convertTextoToData(dataFim)){
                                    baseDadosTemp[key] = JSON.parse(JSON.stringify(baseDados[key]))
                                }
                            }else{
                                setBaseInteiraData(false) // COMO AO MENOS UMA DATA FOI EXCLUÍDA, É SINAL DE QUE OS DADOS NÃO ESTÃO MAIS COMPLETOS
                            }
                        }
                    }
                }
            } 

            if(objetoVazio(baseDadosTemp) || Object.keys(baseDadosTemp).length < 2){
                titulo = "Dados inconsistentes!"
                corpo = 
                    <div className="">
                        <p>Os filtros estão retornando uma base nula</p>
                    </div>
                callModal("")
                return false
            }else{
                // ALTERA A VARIÁVEL DE ESTADO
                setDadosFiltrados(baseDadosTemp)
            }

        }else{

            titulo = "Dados inconsistentes!"
            corpo = 
                <div className="">
                    <p>O campo <strong>Data Fim</strong></p>
                    <p>Devem ser maior do que os campo <strong>Data Início</strong></p>
                </div>
            callModal("")
            return false

        }


    }

    // JX DE IMPRESSÃO
    return(
        <main>
            <ModalApp tipo ={modalTipo} show={modalShow} change={setModalShow} titulo={modalTitulo} corpo ={modalCorpo}/>
            <section className="minha-conta container-md">
                <div className="cadastro-bemvindo">
                    <CardImagem imagemSrc="imagens/ImagemOla.png">
                        <p>Olá <strong><em>{nomeUsuario}</em></strong>, seja bem vindo(a) ao nosso app.</p>
                        <span className="textoOlaExtendido">Aqui você encontra detalhes sobre sua evolução patrimonial, rentabilidade, prêmios e muito mais.</span>
                        <span className="textoOlaExtendido">Em caso de dúvidas, não existe em nos contatar, seu contato se encontra ao fim da página.</span>
                    </CardImagem>
                </div>
            </section>
            <section className="filtroDataHome container-md">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Filtros</Accordion.Header>
                            <Accordion.Body>
                                <form id="formFiltroHome">
                                    <div className="row">
                                        <CampoForm
                                            ref = {dataInicioRef} 
                                            aoAlterado = {naoAltera}
                                            nomeCampo= "Data Início" 
                                            campoId="data-inicio" 
                                            tipo="date" 
                                            dataCampo="dataInicio" 
                                            placeholder="" 
                                            dataMask="" 
                                            required= {false}  
                                            largura= "6"
                                        />
                                        <CampoForm
                                            ref = {dataFimRef} 
                                            aoAlterado = {naoAltera}
                                            nomeCampo= "Data Fim" 
                                            campoId="data-fim" 
                                            tipo="date" 
                                            dataCampo="dataFim" 
                                            placeholder="" 
                                            dataMask= ""
                                            required= {false}  
                                            largura= "6"
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 btn-cadastro-box">
                                            <button type="reset" className="btn btn-gray btn-reset" id="btn-reset" data-campo="resetForm" onClick={resetFiltro}>Reset</button>
                                            <button type="submit" className="btn btn-primary btn-cadastro margin-left" id="btn-filtro" data-campo="submitForm" onClick={submitFiltro}>Enviar</button>
                                        </div>
                                    </div>
                                </form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            </section>
            <Carteira 
                acesso="cliente" 
                baseDados={dadosFiltrados} 
                baseInteiraData={baseInteiraData} 
                baseHistoricoAcoes={props.baseHistoricoAcoes}
                secaoPatrimonio = {props.secaoPatrimonio}
                secaoRentabilidade = {props.secaoRentabilidade}
                secaoResultadosMes = {props.secaoResultadosMes}
                secaoIndices = {props.secaoIndices}
                secaoConciliacao = {props.secaoConciliacao}
                secaoCarteira = {props.secaoCarteira}
                secaoMeusPremios = {props.secaoMeusPremios}
            />
            <section className="meu-consultor container-md" ref={props.secaoMeuContato}>
                <h1><span className="material-symbols-outlined icon-title">group</span>Meu Contato</h1>
                <div className="row conteudo-meu-consultor">
                    <div className="col-12 col-md-4 box-consultor">
                        <h4>{dadosConsultor.nome}</h4>
                        <p>{dadosConsultor.descricao}</p>
                    </div>
                    <div className="col-12 col-md-4 box-consultor imagem-box-consultor">
                        <img className="imagem-consultor" src={ulrImg} />
                    </div>
                    <div className="col-12 col-md-4 box-consultor">
                        <p><em>E-mail: </em>{dadosConsultor.email}</p>
                        <p><em>Telefone: </em>{dadosConsultor.telefone}</p>
                        <p><em>Whatsapp: </em>{dadosConsultor.whatsapp}</p>
                    </div>
                </div>
            </section>
        </main>
    )

}

export default Home