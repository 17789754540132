import './Footer.css'
import HeaderLink from '../HeaderLink'
import Nav from 'react-bootstrap/Nav';
import { auth } from '../../config/firebase';

const Footer = (props) => {

    // LÓGICA PARA DETERMINAÇÃO DAS VARIÁVEIS DE EXIBIÇÃO
	var exibeLogado = ""
    var exibeAdmin = ""
	var exibeTermoEPolitica = ""

	if (props.user) {
		exibeLogado = "sim"
		if(props.user.termoEPolitica === false){
			exibeTermoEPolitica = "não"
		}else{
			exibeTermoEPolitica = "sim"
			if (props.user.email === "techumltda@gmail.com") {
				exibeAdmin = "sim"
			}else{
				exibeAdmin = "não"
			}
		}
	}else{
		exibeLogado = "não"
		exibeTermoEPolitica = "sim"
	}

    // JSX
    return(
        <div>
            {props.user && exibeTermoEPolitica === "sim"? (
                <footer className="footerLogado">
                    <div className="container footerCellStyle">
                        <Nav className="">
                            <div className="box-indice-footer"><HeaderLink to="/perfil" exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="não"><span className="material-symbols-outlined ">account_circle</span></HeaderLink></div>
                            <div className="box-indice-footer"><HeaderLink to="/" exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="não"><span className="material-symbols-outlined ">monitoring</span></HeaderLink></div>
                            {exibeAdmin === "sim" ? (
                                <div className="box-indice-footer"><HeaderLink to="/admin" exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="sim"><span className="material-symbols-outlined ">dashboard</span></HeaderLink></div>
                            ): ""
                            } 
                            {exibeAdmin === "sim" ? (
                                <div className="box-indice-footer"><HeaderLink to="/gestaoRisco" exibeLogado={exibeLogado} conteudoProtegido="sim" exibeAdmin={exibeAdmin} conteudoAdmin="sim"><span className="material-symbols-outlined ">shield</span></HeaderLink></div>
                            ): ""
                            } 
                        </Nav>
                    </div>
                </footer>
            ) : (
                <footer className="footerLogin">
                    <div className="container ">
                    </div>
                </footer>
            )}
        </div>
    )

}

export default Footer