import styles from './GestaoRisco.module.css'
import PainelRisco from '../../componentes/PainelRisco';
import Calendar from 'react-calendar';
import PreLoader from '../../componentes/PreLoader';
import MapaOpPorCliente from '../../componentes/MapaOpPorCliente';
import { useState, useEffect, useRef } from 'react';
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { filterData, SearchType } from 'filter-data';
import { app } from '../../config/firebase';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'
import { convertDataToText, convertTextoToData } from '../Admin/convertData';
import { Carousel } from 'react-bootstrap';
import CardTickers65 from '../../componentes/CardsTickers65';
import GraficoLimpo from '../../componentes/GraficoLimpo';

// MÓDULO DE GESTÃO DE RISCO
const GestaoRisco = ({baseDadosTotal}) => {

    // VARIÁVEIS DE REFERÊNCIA
    const precoBova = useRef();

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"

    // VARIÁVEIS DE ESTADO
    const [dataValue, setDataValue] = useState(null); // VARIÁVEL DE ESTADO QUE ARMAZENA A DATA DO CALENDÁRIO
    const [loading, setLoading] = useState(true); // VARIÁVEL DE ESTADO DE LOADING
    const [baseCompletaOpcoes, setBaseCompletaOpcoes] = useState([]) // BASE COMPLETA DE OPÇÕES
    const [baseOpcoesAtivas, setBaseOpcoesAtivas] = useState(null) // BASE COM OPÇÕES ATIVAS
    const [baseExtratoOp, setBaseExtratoOp] = useState([]) // BASE PARA CÁLCULO DE RISCO DOS CLIENTES
    const [baseOpcoesAtivasEInativas, setBaseOpcoesAtivasEInativas] = useState([])
    const [baseKPIRiscoDetalhe, setBaseKPIRiscoDetalhe] = useState([])
    const [baseAcoesCallCoberta, setBaseAcoesCallCoberta] = useState([]) // BASE DE DADOS QUE SERVIRÁ PARA IMPRIMIR O TOTAL DE AÇÕES EM CARTEIRA DE CADA CLIENTE E QUANTO JÁ FOI COMPROMETIDO COM CALLS
    const [baseVarPatrClientes, setBaseVarPatrClientes] = useState([]) // BASE DE IMPRESSÃO DOS RETORNOS ESPERADOS

    // VARIÁVEIS
    var baseOpcoesArrayTemp = []
    var baseExtratoComplementadaTemp = []
    var baseExtratoComplementadaAtivoInativoTemp = []
    var dadosVarPatrimonioClientes = []

    // AO CARREGAR A PÁGINA PUXA OS DADOS E GERA A BASE PRINCIPAL
    useEffect(()=>{

        // CAPTURA A BASE DO FIRESTORE COM OS DETALHES MAIS RECENTES DE CADA TICKER DE OPÇÃO
        var cont = 1

        // BAIXA BASE JSON COM HISTÓRICO DE OPÇÕES
        const storage = getStorage(app);
        const pathReference = ref(storage, 'opcoesOp/ListaOpcoesStorage.json');
        const pathReferenceExtrato = ref(storage, 'opcoesOp/ExtratoDeOpcoes.json')

        // BAIXA A BASE COMPLETA DE OPÇÕES
        getDownloadURL(ref(storage, pathReference)).then((url) => {
            makeRequest('GET', url)
            .then(function (datums) {

                // BAIXA A BASE COMPLETA DO EXTRATO
                getDownloadURL(ref(storage, pathReferenceExtrato)).then((urlExtrato) => {
                    makeRequest('GET', urlExtrato)
                    .then(function (dataExtrato) {

                        var baseExtrato = JSON.parse(dataExtrato)
                        var baseOpcoes = JSON.parse(datums)

                        baseOpcoesArrayTemp = []
                        baseExtratoComplementadaTemp = []
                        baseExtratoComplementadaAtivoInativoTemp = []

                        // CRIA A BASE DE IMPRESSÃO
                        for (let ticker in baseOpcoes) {

                            // AJUSTA A DATA DE VENCIMENTO
                            var vencimento = convertTextoToData(baseOpcoes[ticker]["Vencimento"])
                            var ranking = String(baseOpcoes[ticker]["Ranking de escolhas"])
                            
                            // ADICIONA OS DADOS NO ARRAY DE IMPRESSÃO
                            baseOpcoesArrayTemp.push({
                                DataDeRegistro: baseOpcoes[ticker]["DataDeRegistro"],
                                ticker: ticker,
                                strike: baseOpcoes[ticker]["Strike"],
                                ultimo: baseOpcoes[ticker]["Último"],
                                acao: baseOpcoes[ticker]["Nome"],
                                bandaInferior: baseOpcoes[ticker]["Banda Inferior"],
                                bandaSuperior: baseOpcoes[ticker]["Banda Superior"],
                                precoAcao: baseOpcoes[ticker]["Preço da Ação"],
                                precoAlvo: baseOpcoes[ticker]["Preço-alvo"],
                                mediaMovel20: baseOpcoes[ticker]["Média Móvel de 20"],
                                tendencia: baseOpcoes[ticker]["Tendencia"],
                                maiorVenc: baseOpcoes[ticker]["Maior Preço no vencimento"],
                                menorVenc: baseOpcoes[ticker]["Menor Preço no vencimento"],
                                vencimento: vencimento,
                                tipo: baseOpcoes[ticker]["tipo"],
                                volImplicita: baseOpcoes[ticker]["Vol. Impl. (%)"],
                                ivRank:baseOpcoes[ticker]["IVRank Geral"],
                                ivRankCalls: baseOpcoes[ticker]["IVRank Calls"],
                                ivRankPuts: baseOpcoes[ticker]["IVRank Puts"],
                                ivRankMedioCalls: baseOpcoes[ticker]["IVRank Médio Calls"],
                                ivRankMedioPuts:baseOpcoes[ticker]["IVRank Médio Puts"],
                                ivRankCallsPercentil: baseOpcoes[ticker]["IV Percentil Calls"],
                                ivRankPutsPercentil: baseOpcoes[ticker]["IV Percentil Puts"],
                                retornoMensal: baseOpcoes[ticker]["Retorno Eq. Mensal (%)"],
                                delta:baseOpcoes[ticker]["Delta"],
                                beta:baseOpcoes[ticker]["Beta"],
                                gama:baseOpcoes[ticker]["Gamma"],
                                tetaPercentual:baseOpcoes[ticker]["Theta (%)"],
                                tetaValor: baseOpcoes[ticker]["Theta ($)"],
                                ranking: ranking,
                                ifr: baseOpcoes[ticker]["IFR"]
                            })
                        }   

                        setBaseCompletaOpcoes(baseOpcoesArrayTemp) 

                        // CRUZA AS DUAS BASES A ADICIONA NA BASE PRINCIPAL AS INFORMAÇÕES RELEVANTES
                        for (let operacao in baseExtrato){
                            var chave = baseExtrato[operacao]["Chave"]
                            var opCompleta = baseExtrato[operacao]
                            var status = baseExtrato[operacao]["Status para Uso"]
                            var tickerOp = baseExtrato[operacao]["Ativo"]
                            var data = convertTextoToData(baseExtrato[operacao]["Criacao"])

                            // ADICIONA TODAS AS INFOS QUE JÁ VEM DO PROFIT
                            var input = JSON.parse(JSON.stringify(opCompleta)); 

                            // ADICIONA A CHAVE DO CLIENTE
                            input["chaveCliente"] = chave

                            // ADICIONA A DATA DA OPERAÇÃO
                            input["dataOperacao"] = data

                            // ACRESCENTA INFOS NEUTRAS ADICIONAIS NO JSON
                            for(const campo in baseOpcoesArrayTemp[0]){
                                if(campo === "vencimento"){
                                    input[campo] = new Date ("01-01-1900")
                                }else if(campo === "acao"){
                                    input[campo] = "DIVERSOS"
                                }else if(campo === "strike"){
                                    input[campo] = 1
                                }else{
                                    input[campo] = 0
                                }
                            }

                            // VARRE TODAS OS TICKERS NA BASE DE OPÇÕES COMPLETAS
                            for(let j=0; j < baseOpcoesArrayTemp.length; j++){

                                var ticker = baseOpcoesArrayTemp[j]["ticker"]

                                // SE OS TICKERS FOREM IGUAIS
                                if(ticker === tickerOp){

                                    // ACRESCENTA INFOS ADICIONAIS DA BASE JSON
                                    for(const campo in baseOpcoesArrayTemp[j]){
                                        input[campo] = baseOpcoesArrayTemp[j][campo]
                                    }
                                    
                                }

                                // SE O AÇÃO FOR BOVA11 PEGA SEU VALOR PARA CÁLCULO DO DELTA BETA
                                if(baseOpcoesArrayTemp[j]["acao"] === "BOVA11"){

                                    if(baseOpcoesArrayTemp[j]["precoAcao"]===0){

                                    }else{
                                        precoBova.current = (baseOpcoesArrayTemp[j]["precoAcao"])
                                    }
                                }

                            }

                            // SE FOR ATIVO ADICIONA NA BASE DE FOTO
                            if(status==="Ativo"){
                                // ADICIONA NA BASE DE FOTO DO DIA
                                baseExtratoComplementadaTemp.push(input)
                            }

                            // INDEPENDENTE DE SER ATIVO, ADICIONA NA BASE COMPLETA
                            baseExtratoComplementadaAtivoInativoTemp.push(input)
                            
                        }

                        // À PARTIR DAQUI A BASE SERÁ CRUZADA COM A CARTEIRA PARA ENTENDER QUAIS OPÇÕES ESTÃO COBERTAS
                        // CRIA UM CONTROLE DE SALDO DE AÇÕES
                        var dadosAcoesCompleto = []
                        for (let user = 0; user < baseDadosTotal.length; user++){
                            var baseUsuario = baseDadosTotal[user]
                            var userChave = baseUsuario["chave"]
                            var dataMaisAtual = "01-01-1900"

                            // LOOP NO OBJETO PARA CAPTURA DA DATA MAIS ANTIGA
                            for (let data in baseUsuario){
                                if(data==="chave"){}else{
                                    if(convertTextoToData(data) > convertTextoToData(dataMaisAtual)){
                                        dataMaisAtual = data
                                    }
                                }
                            }

                            // CAPTURA A FOTO DA DATA MAIS ATUAL
                            var dadoMaisAtual = baseUsuario[dataMaisAtual]
                            var dadosAcoesUser = dadoMaisAtual["Ações"]

                            // VARRE TODAS AS AÇÕES DENTRO DO OBJETO E ADICIONA A PROPRIEDADE SALDO CALL COBERTAS E TOTAL CALLSVENDIDAS
                            for (let acao in dadosAcoesUser){
                                if(dadosAcoesCompleto[acao]===undefined){
                                    dadosAcoesCompleto[acao] = []
                                    dadosAcoesCompleto[acao][userChave] = dadosAcoesUser[acao]
                                }else{
                                    dadosAcoesCompleto[acao][userChave] = dadosAcoesUser[acao]
                                }

                                dadosAcoesCompleto[acao][userChave]["SaldoCallCoberta"] = dadosAcoesCompleto[acao][userChave]["Quantidade"] 
                                dadosAcoesCompleto[acao][userChave]["TotalVendidoCalls"] = 0
                            }

                        }
                        
                        // RANKEIA DO MENOR PRO MAIOR STRIKE
                        baseExtratoComplementadaTemp.sort((a,b)=>a.strike - b.strike)

                        // RANKEIA DO VENCIMENTO MAIS PRÓXIMO PRO MAIS ANTIGO
                        baseExtratoComplementadaTemp.sort((a,b)=>a.vencimento - b.vencimento)

                        // CRUZA COM A BASE DE AÇÕES PARA MARCAR AS CALLS COBERTAS OU NÃO
                        for (let opcao = 0;opcao < baseExtratoComplementadaTemp.length; opcao++){

                            // COMPARA A QUANTIDADE TOTAL VENDIDA COM SALDO DE AÇÕES
                            var usuario = baseExtratoComplementadaTemp[opcao]["Chave"]
                            var quantidadeOpcao = baseExtratoComplementadaTemp[opcao]["Qtd Executada"]
                            var acaoOpcao = baseExtratoComplementadaTemp[opcao]["acao"]
                            var saldoAcoes = 0

                            if(dadosAcoesCompleto[acaoOpcao] != undefined){
                                if(dadosAcoesCompleto[acaoOpcao][usuario] != undefined){
                                    saldoAcoes = dadosAcoesCompleto[acaoOpcao][usuario]["SaldoCallCoberta"]
                                }else{
                                    saldoAcoes = 0
                                }
                            }else{
                                saldoAcoes = 0
                            }

                            // VERIFICA SE FOR CALL SECA, NÃO PODE SER TRAVA
                            if(baseExtratoComplementadaTemp[opcao]["Estrategia de Opcoes"] != "Trava" && baseExtratoComplementadaTemp[opcao]["Lado"] === "V"){
                                if(baseExtratoComplementadaTemp[opcao]["tipo"]==="CALL"){
                                    if(quantidadeOpcao > 0){
                                        
                                        // SOMA NA QUANTIDADE TOTAL VENDIDA
                                        // VERIFICA PRIMEIRO SE ESSES CAMPOS JÁ EXISTEM NA BASE
                                        if(dadosAcoesCompleto[acaoOpcao] != undefined){
                                            if(dadosAcoesCompleto[acaoOpcao][usuario] != undefined){
                                                dadosAcoesCompleto[acaoOpcao][usuario]["TotalVendidoCalls"] = dadosAcoesCompleto[acaoOpcao][usuario]["TotalVendidoCalls"] + quantidadeOpcao
                                            }else{
                                                dadosAcoesCompleto[acaoOpcao][usuario] = []
                                                dadosAcoesCompleto[acaoOpcao][usuario]["Quantidade"] = 0
                                                dadosAcoesCompleto[acaoOpcao][usuario]["SaldoCallCoberta"] = 0
                                                dadosAcoesCompleto[acaoOpcao][usuario]["TotalVendidoCalls"] = quantidadeOpcao
                                            }
                                        }else{
                                            dadosAcoesCompleto[acaoOpcao] = []
                                            dadosAcoesCompleto[acaoOpcao][usuario] = []
                                            dadosAcoesCompleto[acaoOpcao][usuario]["Quantidade"] = 0
                                            dadosAcoesCompleto[acaoOpcao][usuario]["SaldoCallCoberta"] = 0
                                            dadosAcoesCompleto[acaoOpcao][usuario]["TotalVendidoCalls"] = quantidadeOpcao
                                        }

                                        // SE O SALDO FOR MAIOR QUE A QTD, DESCONTA DO SALDO E CLASSIFICA A OPÇÃO COMO COBERTA
                                        if(quantidadeOpcao <= saldoAcoes){
                                            // AJUSTA O SALDO REMANESCENTE PARA COBRIR AS PRÓXIMAS CALLS
                                            dadosAcoesCompleto[acaoOpcao][usuario]["SaldoCallCoberta"] = dadosAcoesCompleto[acaoOpcao][usuario]["SaldoCallCoberta"] - quantidadeOpcao
                                            
                                            // ADICIONA A CLASSIFICAÇÃO COBERTA NA OPÇÃO
                                            baseExtratoComplementadaTemp[opcao]["callCoberta"] = true

                                        }else{
                                            // ADICIONA A CLASSIFICAÇÃO COBERTA NA OPÇÃO
                                            baseExtratoComplementadaTemp[opcao]["callCoberta"] = false
                                        }
                                    }else{
                                        // ADICIONA A CLASSIFICAÇÃO COBERTA NA OPÇÃO
                                        baseExtratoComplementadaTemp[opcao]["callCoberta"] = false  
                                    }
                                }else{
                                    // ADICIONA A CLASSIFICAÇÃO COBERTA NA OPÇÃO
                                    baseExtratoComplementadaTemp[opcao]["callCoberta"] = false
                                }

                            }

                            // VERIFICAÇÃO
                            //if(usuario === "oUjsnhmsbReJbeWdhiRgSlDzqal1" && acaoOpcao ==="VALE3" && baseExtratoComplementadaTemp[opcao]["tipo"]==="CALL"){
                                //console.log(baseExtratoComplementadaTemp[opcao])
                            //}   
                        
                        }

                        // ATUALIZA AS VARIÁVEIS DE ESTADO
                        setBaseAcoesCallCoberta(dadosAcoesCompleto)
                        setBaseOpcoesAtivas(baseExtratoComplementadaTemp)
                        setBaseOpcoesAtivasEInativas(baseExtratoComplementadaAtivoInativoTemp)

                        // CAPTURA A DATA EM QUE OS DADOS FORAM ATUALIZADOS
                        // ESSA FUNÇÃO É PROVISÕRIA ENQUANTO AINDA NÃO TEMOS O HISTÓRICO ADICIONADO NA PÁGINA
                        if(baseExtratoComplementadaTemp.length != 0){
                            var dataAtualizacao = convertTextoToData(baseExtratoComplementadaTemp[0]["DataAtualizacao"])
                            // SELECIONA NO CALENDÁRIO A DATA EM QUE OS DADOS FORMA ATUALIZADOS
                            setDataValue(dataAtualizacao)
                        }

                        setLoading(false)

                    })

                })

            })
            .catch(function (err) {
                console.error('Erro:', err.statusText);
            });
        })
        
    },[])

    // FUNÇÃO QUE CAPTURA AS ALTERAÇÕES NO CALENDÁRIO
    useEffect(()=>{

        if(dataValue != null){
            console.log("rodou renderização da data")

            // BASE DE IMPRESSÃO DOS CLIENTES
            var baseRiscoClientes = []

            // BASE INCLUINDO OS KPI'S DE RISCO CALCULADOS POR OPERAÇÃO
            var baseComKPIsRisco = []

            if(baseOpcoesAtivas !=null){

                // ARRAY COM CHAVES DE CLIENTES CONTIDAS NA BASE
                const arrayVencimentos = [...new Set(baseOpcoesAtivas.map((valor) => convertDataToText(valor.vencimento)))]

                // ARRAY COM CHAVES DE CLIENTES CONTIDAS NA BASE
                const arrayClientes = [...new Set(baseOpcoesAtivas.map((valor) => valor.chaveCliente))]

                // LOOP NA BASE DE CLIENTES PARA CONSTRUÇÃO DOS ARRAYS DOS CLIENTES
                for(let clientesIndex=0; clientesIndex < arrayClientes.length; clientesIndex++){
                    var chaveCliente = arrayClientes[clientesIndex]
                    var notionalCliente = 0
                    var nomeCliente = ""
                    var provisaoN1 = 0
                    var patrimonioN1 = 0
                    var modelo = ""

                    // CAPTURA O NOTIONAL DO CLIENTE DEVIDO
                    for(let baseTotalIndex=0; baseTotalIndex < baseDadosTotal.length; baseTotalIndex++){
                        // IDENTIFICA A BASE TOTAL DO CLIENTE
                        if(chaveCliente === baseDadosTotal[baseTotalIndex]["chave"]){
                            // VARRE TODAS AS DATAS NA BASE
                            var dataPrecendete = new Date("1900-01-01")
                            for (let data in baseDadosTotal[baseTotalIndex]) {
                                if(data != "chave"){
                                    var dataBaseTotal = convertTextoToData(data)
                                    // CAPTURA A ÚLTIMA INFO DE NOTIONAL PRECEDENTE A DATA DE FILTRO
                                    if(dataBaseTotal < dataValue){  
                                        if(dataBaseTotal > dataPrecendete){
                                            dataPrecendete = dataBaseTotal
                                        }
                                    }
                                }
                            }

                            // CAPTURA O NOTIONAL 
                            var dataPrecedenteTexto = convertDataToText(dataPrecendete)
                            notionalCliente = baseDadosTotal[baseTotalIndex][dataPrecedenteTexto]["Notional do mês subsequente"]

                            // CAPTURA O NOME DO CLIENTE
                            var nomeClienteArray = baseDadosTotal[baseTotalIndex][dataPrecedenteTexto]["Cliente"].split(' ')
                            var sobreNomeArray = nomeClienteArray[nomeClienteArray.length-1].split('')
                            nomeCliente = nomeClienteArray[0] + " " + sobreNomeArray[0] + "."

                            // CAPTURA A PROVISÃO N-1
                            provisaoN1 = baseDadosTotal[baseTotalIndex][dataPrecedenteTexto]["Opcoes (R$)"]

                            // CAPTURA O PATRIMÔNIO DO CLIENTE
                            patrimonioN1 = baseDadosTotal[baseTotalIndex][dataPrecedenteTexto]["Saldo Visivel na XP"]

                            // CAPTURA O MODELO DO CLIENTE
                            modelo = baseDadosTotal[baseTotalIndex][dataPrecedenteTexto]["Modelo de Contrato"]

                        }
                    }

                    // VARIÁVEL DE IMPRESSÃO DA TABELA DE RISCO
                    var input = {
                        chaveCliente:chaveCliente,
                        nome: nomeCliente,
                        rangeInferior:0,
                        rangeSuperior:0,
                        margem:0,
                        margemPercentual:0,
                        margemPercentualWarning:0,
                        tetaValor: 0,
                        tetaWarning:0,
                        deltaBetaValor:0,
                        deltaBetaPercentual:0,
                        deltaBetaPercentualWarning:0,
                        premios:0,
                        premiosPercentual:0,
                        premiosPercentualWarning:0,
                        premiosSempre:0,
                        notionalUsado:0,
                        valorEmTrava:0,
                        notionalTotal:notionalCliente,
                        notionalUsadoPercentual:0,
                        notionalUsadoPercentualWarning:0,
                        valorEmTravaPercentual:0,
                        valorEmTravaPercentualWarning:0,
                        riscoGama:0,
                        riscoGamaWarning:0,
                        provisaoN1:provisaoN1,
                        provisaoHoje:0,
                        provisaoHojeExtr:0,
                        provisaoHojeIntr:0,
                        provisaoHojeWarning: "",
                        varPatrimonio:0,
                        varPatrimonioPercentual:0,
                        patrimonioN1: patrimonioN1,
                        varPatrimonioWarning:"",
                        varPatrimonioPercentualWarning:"",
                        modeloDeContrato:modelo
                    }

                    // FILTRA A BASE ATIVA DE ACORDO COM CLIENTE
                    var searchConditions = [
                        {
                        key: 'Chave',
                        value: chaveCliente,
                        type: SearchType.EQ,
                        },
                    ];
                    var baseFiltradaCliente = filterData(baseOpcoesAtivas, searchConditions);

                    // FILTRA A BASE TOTAL DE ACORDO COM CLIENTE
                    var searchConditions = [
                        {
                        key: 'Chave',
                        value: chaveCliente,
                        type: SearchType.EQ,
                        },
                    ];
                    var baseFiltradaAtivaEInativaCliente = filterData(baseOpcoesAtivasEInativas, searchConditions);

                    // LOOP NA BASE ATIVA E INATIVA PRA CÁLCULO DOS PRÊMIOS
                    for(let ativaEInativaIndex=0; ativaEInativaIndex<baseFiltradaAtivaEInativaCliente.length; ativaEInativaIndex++){

                        var operacao = baseFiltradaAtivaEInativaCliente[ativaEInativaIndex]

                        // PRÊMIOS DA VIDA INTEIRA
                        if(operacao.Lado === "V"){
                            input.premiosSempre = input.premiosSempre + (operacao["Preco Medio"] * operacao["Qtd Executada"]) 
                        }else{
                            input.premiosSempre = input.premiosSempre - (operacao["Preco Medio"] * operacao["Qtd Executada"])
                        }
                        
                        // PRÊMIOS NO MÊS
                        var dataoperacao = convertTextoToData(operacao.Criacao)
                        if(dataoperacao != undefined){
                            if(dataValue.getMonth() == dataoperacao.getMonth()){
                                if(dataValue.getFullYear() == dataoperacao.getFullYear()){
                                    if(operacao.Lado === "V"){
                                        input.premios = input.premios + (operacao["Preco Medio"] * operacao["Qtd Executada"]) 
                                    }else{
                                        input.premios = input.premios - (operacao["Preco Medio"] * operacao["Qtd Executada"])
                                    }
                                }
                            }
                        }else{
                        }
                    }

                    // LOOP NA BASE ATIVA PARA CÁLCULO DOS CAMPOS CALCULADOS
                    for(let ativaIndex=0; ativaIndex < baseFiltradaCliente.length; ativaIndex++){
                        var operacao = baseFiltradaCliente[ativaIndex]

                        // DELTA BETA
                        if(operacao.Lado === "V"){
                            input.deltaBetaValor = input.deltaBetaValor + (-1)*((operacao.beta * operacao.precoAcao * operacao.delta) / precoBova.current)*(operacao["Qtd Executada"])
                        }else{
                            input.deltaBetaValor = input.deltaBetaValor + ((operacao.beta * operacao.precoAcao * operacao.delta) / precoBova.current)*(operacao["Qtd Executada"])
                        }

                        // DELTA BETA PERCENTUAL
                        input.deltaBetaPercentual = input.deltaBetaValor / input.notionalTotal

                        // DELTA BETA WARNING
                        if(input.deltaBetaPercentual > -0.003 && input.deltaBetaPercentual < 0.003){
                            input.deltaBetaPercentualWarning = "itemOk"
                        }else{
                            input.deltaBetaPercentualWarning = "itemBad"
                        }

                        // THETA
                        var tetaOperacao = (operacao.tetaValor * operacao["Qtd Executada"])
                        input.tetaValor = tetaOperacao + input.tetaValor

                        // RISCO GAMA
                        input.riscoGama = input.tetaValor *(-1) / input.notionalTotal

                        // RISCO GAMA WARNING
                        if(input.riscoGama < 0.001){
                            input.riscoGamaWarning = "itemWarning"
                        }else if(input.riscoGama < 0.002){
                            input.riscoGamaWarning = "itemOk"
                        }else{
                            input.riscoGamaWarning = "itemBad"
                        }

                        // NOTIONAL UTILIZADO E MARGEM
                        var notionalDaOperacao = 0
                        if(operacao["Estrategia de Opcoes"] === "Strangle"){

                        // NOTIONAL USADO
                        }else if(operacao["Estrategia de Opcoes"] === "Trava"){
                            if(operacao.Lado === "V"){
                                notionalDaOperacao = (operacao.strike * operacao["Qtd Executada"]) 
                                input.valorEmTrava = input.valorEmTrava + (operacao.strike * operacao["Qtd Executada"])
                                input.margem = input.margem + (operacao.strike * operacao["Qtd Executada"])
                            }else{
                                notionalDaOperacao = (-1)*(operacao.strike * operacao["Qtd Executada"])
                                input.valorEmTrava = input.valorEmTrava - (operacao.strike * operacao["Qtd Executada"])
                                input.margem = input.margem - (operacao.strike * operacao["Qtd Executada"])
                            }
                        
                        // OPERAÇÕES A SECO
                        }else{
                            if(operacao.Lado === "V" && operacao.tipo ==="PUT"){
                                notionalDaOperacao = (operacao.strike * operacao["Qtd Executada"]) 
                                var margemconsumida = ((operacao.precoAcao*0.2) + (operacao.strike-operacao.precoAcao) + operacao["Preco Medio"]) * operacao["Qtd Executada"]
                                input.margem = input.margem + margemconsumida
                            }else if (operacao.Lado === "V" && operacao.tipo ==="CALL"){
                                var margemconsumida = ((operacao.precoAcao*0.2) - (operacao.strike-operacao.precoAcao) + operacao["Preco Medio"]) * operacao["Qtd Executada"]
                                input.margem = input.margem + margemconsumida
                            }
                        }

                        // PONDERA O NOTIONAL DE ACORDO COM O VENCIMENTO
                        var difDias = (operacao["vencimento"] - dataValue)/86400000
                        var vencimentoTexto = convertDataToText(operacao["vencimento"])

                        if(difDias <= 40){ // PROX. PRÓXIMO VENCIMENTO QUE SEMPRE VAI SER ENTRE 20-40 DIAS NO FUTURO
                            // SE FOR O VENC MAIS PRÓXIMO É 0
                            if(vencimentoTexto == arrayVencimentos[0]){
                                notionalDaOperacao = notionalDaOperacao * 0
                            // SE O 2 VENCIMENTO TIVER MENOR QUE 40 DIAS, AJUSTA DE ACORDO E NÃO 0
                            }else{
                                notionalDaOperacao = notionalDaOperacao * 0.8
                            }
                        }else if (difDias < 70){ // VENCIMENTO SEGUINTE VAI SER SEMPRE ENTRE 50 E 90 DIAS
                            // SE FOR O 2 VENCIMENTO AJUSTA DE ACORDO
                            if(vencimentoTexto == arrayVencimentos[1]){
                                notionalDaOperacao = notionalDaOperacao * 0.8
                            
                            // CASO NÃO EXISTA NADA NO PRIMEIRO VENCIMENTO E O PRIMEIRO VALOR DO ARRAY JÁ SEJA O SEGUNDO VENCIMENTO
                            }else if(vencimentoTexto == arrayVencimentos[0]){
                                notionalDaOperacao = notionalDaOperacao * 0.8

                            // SE O 3 VENCIMENTO TIVER EM MENOS DE 70 DIAS, AJUSTA DE ACORDO
                            }else{
                                notionalDaOperacao = notionalDaOperacao * 0.2
                            }
                        }else if (difDias < 90){ 
                            // SE FOR O 3 VENCIMENTO AJUSTA DE ACORDO QUE PODE ESTAR EM QUALQUER UMA DAS 3 POSIÇÕES
                            if(vencimentoTexto == arrayVencimentos[2] || vencimentoTexto == arrayVencimentos[1] || vencimentoTexto == arrayVencimentos[0]){
                                notionalDaOperacao = notionalDaOperacao * 0.2
                            // SE O 4 VENCIMENTO TIVER EM MENOS DE 90 DIAS, AJUSTA DE ACORDO
                            }else{
                                notionalDaOperacao = notionalDaOperacao * 0
                            }
                        }else if (difDias < 180){ 
                            notionalDaOperacao = notionalDaOperacao * 0.0
                        }else{
                            notionalDaOperacao = notionalDaOperacao * 0.0
                        }

                        // NOTIONAL FINAL
                        input.notionalUsado = input.notionalUsado +  notionalDaOperacao

                        // NOTIONAL %
                        input.notionalUsadoPercentual = input.notionalUsado / input.notionalTotal

                        if(input.notionalUsadoPercentual < 0.7){
                            input.notionalUsadoPercentualWarning = "itemWarning"
                        }else if(input.notionalUsadoPercentual < 0.9){
                            input.notionalUsadoPercentualWarning = "itemOk"
                        }else{
                            input.notionalUsadoPercentualWarning = "itemBad"
                        }

                        // TRAVAS %
                        input.valorEmTravaPercentual = input.valorEmTrava / input.premiosSempre

                        // MARGEM %
                        input.margemPercentual = input.margem / input.notionalTotal

                        // TRAVAS WARNING
                        if(input.valorEmTravaPercentual > 0.3 && input.valorEmTravaPercentual < 0.5){
                            input.valorEmTravaPercentualWarning = "itemOk"
                        }else if(input.valorEmTravaPercentual < 0.3){
                            input.valorEmTravaPercentualWarning = "itemWarning"
                        }else{
                            input.valorEmTravaPercentualWarning = "itemBad"
                        }

                        // MARGEM WARNING
                        if(input.margemPercentual < 0.5){
                            input.margemPercentualWarning = "itemOk"
                        }else if(input.margemPercentual < 0.7){
                            input.margemPercentualWarning = "itemWarning"
                        }else{
                            input.margemPercentualWarning = "itemBad"
                        }

                        // PREMIOS %
                        input.premiosPercentual = input.premios / input.notionalTotal

                        // PREMIOS WARNING
                        if(input.modeloDeContrato === "Antigo"){
                            if(input.premiosPercentual < 0.02){
                                input.premiosPercentualWarning = "itemWarning"
                            }else if (input.premiosPercentual < 0.03){
                                input.premiosPercentualWarning = "itemWarning"
                            }else{
                                input.premiosPercentualWarning = "itemOk"
                            }
                        }else{
                            input.premiosPercentualWarning = "itemOk"
                        }

                        // RANGE DE THETA
                        input.rangeInferior = input.notionalTotal * 0.0010
                        input.rangeSuperior = input.notionalTotal * 0.0017

                        // THETA WARNING
                        if(input.tetaValor*(-1) < input.rangeInferior * 0.7){
                            input.tetaWarning = "itemBad"
                        }else if(input.tetaValor*(-1) < input.rangeInferior){
                            input.tetaWarning = "itemWarning"
                        }else if(input.tetaValor*(-1) < input.rangeSuperior){
                            input.tetaWarning = "itemOk"
                        }else if(input.tetaValor*(-1) < input.rangeSuperior * 1.3){
                            input.tetaWarning = "itemWarning"
                        }else{
                            input.tetaWarning = "itemBad"
                        }

                        // PROVISÃO HOJE, EXTRÍNSICO E INTRÍNSICO
                        if(operacao.Lado === "V"){
                            // CALCULA A PROVISÃO TOTAL
                            input.provisaoHoje = input.provisaoHoje + (operacao["ultimo"] * (operacao["Qtd Executada"]))
                            
                            // CALCULA O VALOR INTRÍNSICO
                            if(operacao.tipo === "PUT"){
                                if(operacao.precoAcao < operacao.strike){
                                    input.provisaoHojeIntr = input.provisaoHojeIntr + ((operacao.strike - operacao.precoAcao)*operacao["Qtd Executada"])
                                }
                            }else{
                                if(operacao.precoAcao > operacao.strike){
                                    input.provisaoHojeIntr = input.provisaoHojeIntr + ((operacao.precoAcao - operacao.strike)*operacao["Qtd Executada"])
                                }
                            }
                        
                        }else{
                            // CALCULA A PROVISÃO TOTAL
                            input.provisaoHoje = input.provisaoHoje + ((-1) * operacao["ultimo"] * (operacao["Qtd Executada"]))

                            // CALCULA O VALOR INTRÍNSICO
                            if(operacao.tipo === "PUT"){
                                if(operacao.precoAcao < operacao.strike){
                                    input.provisaoHojeIntr = input.provisaoHojeIntr - ((operacao.strike - operacao.precoAcao)*operacao["Qtd Executada"])
                                }
                            }else{
                                if(operacao.precoAcao > operacao.strike){
                                    input.provisaoHojeIntr = input.provisaoHojeIntr - ((operacao.precoAcao - operacao.strike)*operacao["Qtd Executada"])
                                }
                            }
                        }

                        // CALCULA O VALOR EXTRÍNSICO
                        input.provisaoHojeExtr = input.provisaoHoje - input.provisaoHojeIntr

                        // CALCULA O WARNING DA PROVISÃO
                        if(input.provisaoHoje > input.provisaoN1 * 1.1){
                            input.provisaoHojeWarning = "itemBad"
                        }else if (input.provisaoHoje > input.provisaoN1){
                            input.provisaoHojeWarning = "itemWarning"
                        }else if (input.provisaoHoje > input.provisaoN1*0.9){
                            input.provisaoHojeWarning = "itemWarning"
                        }else{
                            input.provisaoHojeWarning = "itemOk"
                        }

                        // CALCULA A ESTIMATIVA DE VARIAÇÃO NO PATRIMÔNIO
                        input.varPatrimonio = input.premios - (input.provisaoHoje - input.provisaoN1)

                        // CALCULA O WARNING DA VARIAÇÃO DE PATRIMÔNIO
                        if(input.varPatrimonio >= 0){
                            input.varPatrimonioWarning = "itemOk"
                        }else{
                            input.varPatrimonioWarning = "itemBad"
                        }

                        // CALCULA A VARIAÇÃO NO PATRIMÔNIO PERCENTUAL
                        input.varPatrimonioPercentual = input.varPatrimonio / input.patrimonioN1

                        // CALCULA O WARNING DO PATRIMÔNIO PERCENTUAL
                        if(input.varPatrimonioPercentual >= 0.01){
                            input.varPatrimonioPercentualWarning = "itemOk"
                        }else if (input.varPatrimonioPercentual >= 0){
                            input.varPatrimonioPercentualWarning = "itemWarning"
                        }else{
                            input.varPatrimonioPercentualWarning = "itemBad"
                        }
                        
                    }

                    // ADICIONA O INPUT NA BASE FINAL DE IMPRESSÃO
                    baseRiscoClientes.push(input)

                    // RANKEIA A BASE POR ORDEM ALFABÉTICA
                    baseRiscoClientes.sort((a, b) => a.nome.localeCompare(b.nome));

                    // CRIA A BASE PARA O GRÁFICO DE VARIAÇÃO DO PATRIMÔNIO
                    dadosVarPatrimonioClientes.push({
                        indexLabel: input.nome,
                        y: input.varPatrimonioPercentual*100
                    })

                }

                // LOOP NA BASE DE OPÇÕES ATIVAS PARA COMPLEMENTAÇÃO DA MESMA COM OS KPI'S DE RISCO
                for(let operacaoAtivaIndex=0; operacaoAtivaIndex < baseOpcoesAtivas.length; operacaoAtivaIndex++){

                    var operacao = baseOpcoesAtivas[operacaoAtivaIndex]
                    var inputKPIRisco = JSON.parse(JSON.stringify(operacao))

                    // CRIA O CAMPO COM AÇÃO DESCONTADA EM CENÁRIO DE QUEDA
                    inputKPIRisco.precoAcaoQueda = inputKPIRisco.precoAcao * 0.7

                    // CÁLCULO DAS PROVISÕES SE TIVÉSSEMOS QUE COMPRAR TUDO HOJE
                    // CONSIDERANDO SOMENTE PUT'S SECAS
                    var provisao = 0
                    var provisaoQueda = 0
                    if(inputKPIRisco.tipo==="PUT"){
                        if(inputKPIRisco.Lado==="V"){
                            if(inputKPIRisco["Estrategia de Opcoes"]!="Trava"){
                                if(inputKPIRisco["precoAcao"] < inputKPIRisco["strike"]){
                                    provisao = (inputKPIRisco["strike"] - inputKPIRisco["precoAcao"])*inputKPIRisco["Qtd Executada"]
                                }
                                if(inputKPIRisco["precoAcaoQueda"] < inputKPIRisco["strike"]){
                                    provisaoQueda = (inputKPIRisco["strike"] - inputKPIRisco["precoAcaoQueda"])*inputKPIRisco["Qtd Executada"]
                                }
                            }
                        }
                    }

                    inputKPIRisco.provisao = provisao
                    inputKPIRisco.provisaoQueda = provisaoQueda

                    // AJEITA A DATA QUE FOI DEFORMADA NA CÓPIA JSON PARSE
                    if(inputKPIRisco.vencimento!=0){
                        inputKPIRisco.vencimento = convertTextoToData(inputKPIRisco.vencimento)
                    }

                    // CALCULA O TETA DA OPERAÇÃO
                    var tetaOperacao = (operacao.tetaValor * operacao["Qtd Executada"])
                    inputKPIRisco.tetaOperacao = tetaOperacao

                    var notionalDaOperacao = 0
                    if(operacao["Estrategia de Opcoes"] === "Strangle"){

                    // NOTIONAL USADO
                    }else if(operacao["Estrategia de Opcoes"] === "Trava"){
                        if(operacao.Lado === "V"){
                            notionalDaOperacao = (operacao.strike * operacao["Qtd Executada"]) 
                        }else{
                            notionalDaOperacao = (-1)*(operacao.strike * operacao["Qtd Executada"])
                        }
                    
                    // OPERAÇÕES A SECO
                    }else{
                        if(operacao.Lado === "V" && operacao.tipo ==="PUT"){
                            notionalDaOperacao = (operacao.strike * operacao["Qtd Executada"]) 
                        }else if (operacao.Lado === "V" && operacao.tipo ==="CALL"){

                        }
                    }

                    // ZERA OPERAÇÕES LONGAS
                    var difDias = (operacao["vencimento"] - dataValue)/86400000
                    if (difDias > 180){ 
                        inputKPIRisco["Estrategia de Opcoes"] = "Longa"
                    }

                    // VARIÁVEL DE INPUT PRA BASE DE NOTIONAL
                    // ANTES QUE O NOTIONAL SEJA PONDERADO POR TEMPO
                    inputKPIRisco.notional = notionalDaOperacao*1

                    // ADICIONA O INPUT NA BASE DE NOTIONAL DETALHADA
                    baseComKPIsRisco.push(inputKPIRisco)

                }

            }

            // ATUALIZA AS VARIÁVEIS DE ESTADO
            setBaseExtratoOp(baseRiscoClientes)
            setBaseKPIRiscoDetalhe(baseComKPIsRisco)
            setBaseVarPatrClientes(dadosVarPatrimonioClientes)
            
        }
    
    },[dataValue])

    // OPÇÕES DE IMPRESSÃO DO GRÁFICO DE VARIAÇÃO ESPERADA NO PATRIMÔNIO DOS CLIENTES
    const optionsVarPatrimonioClientes = {
		animationEnabled: true,
		backgroundColor: "transparent",
        dataPointMaxWidth: 10,
        height:210,
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign:"top",
            fontFamily: fonte
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.indexLabel +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><b><span style = 'color:" + e.entries[i].dataSeries.color + "'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'>" + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
            
        },
		title:{
			text: "Variação % Patrimônio",
			padding:0, //20
			fontSize:0, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
        axisY: {
            title: "",
            prefix: "%",
            gridThickness: 1,
            gridColor:"rgb(200,200,200)",
            gridDashType:"dash",
            lineColor: "rgb(200,200,200)",
            tickColor: "rgb(200,200,200)",
            labelFontColor: "gray",
            labelFontFamily: fonte,
            minimum:-10,
            maximum:15,
            interval:5
        },
        axisX: {
            title: "",
            gridThickness: 0,
            lineColor: "rgb(200,200,200)",
            tickColor: "rgb(200,200,200)",
            labelFontColor: "gray",
            labelFontSize:0,
            labelFontFamily: fonte,
            tickThickness:0
        },
		data: [{
                type: "column",
                axisYType: "primary",
                indexLabelFontSize:0,
                name: "Δ Patrimônio %",
                showInLegend: false,
                legendText: "Patrimônio %",
                color: "rgba(102,205,170,.4)",
                dataPoints: baseVarPatrClientes
            },
        ]
    }

    // FUNÇÃO QUE FAZ REQUESTO DO JSON 
    function makeRequest (method, url) {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.open(method, url);
          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else {
              reject({
                status: xhr.status,
                statusText: xhr.statusText
              });
            }
          };
          xhr.onerror = function () {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
          };
          xhr.send();
        });
    }

    return(
        <main className={`${styles.main}`}>
            {loading ? (<PreLoader/>) : (
                <div  className={`container-lg`}>
                    <div className={`${styles.summary}`}>
                        <Calendar activeStartDate={dataValue} minDate={dataValue} maxDate={dataValue} className={`${styles.calendar}`} onChange={setDataValue} value={dataValue} />
                        <div className={`${styles.indicators}`}>
                            <Carousel>
                                <Carousel.Item>
                                    <div className={`${styles.carouselBody}`}>
                                        <div className={`${styles.carouselBodyGraph}`}>
                                            <GraficoLimpo id="graf-patrimonio-clientes" options = {optionsVarPatrimonioClientes} />
                                        </div>
                                    </div>
                                    <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                        <h4>Variação de Patrimônio no mês corrente</h4>
                                        <p>Saldo de variação no patrimônio no mês corrente considerando apenas prêmios e oscilação da provisão.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className={`${styles.carouselBody}`}>
                                        <CardTickers65 baseAtiva={baseOpcoesAtivas}/>
                                    </div>
                                    <Carousel.Caption className={`${styles.carouselCaptionFormat}`}>
                                        <h4>Operações Para Desmontar</h4>
                                        <p>Operações que consideram o preço médio dos clientes x preço atual do ativo e já retornam + de 60% de lucro</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <PainelRisco baseRisco={baseExtratoOp} baseKPIRiscoDetalhe={baseKPIRiscoDetalhe}/>
                    <MapaOpPorCliente baseOpcoesAtivas={baseOpcoesAtivas} baseAcoesCallCoberta={baseAcoesCallCoberta}/>
                </div>
            )}
        </main>
    )
}

export default GestaoRisco;