import './login.css'
import '../../index.css'
import CampoForm from '../../componentes/CampoForm'
import { useState, useRef, useEffect} from 'react'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { LinkContainer } from 'react-router-bootstrap';
import { auth } from '../../config/firebase';
import ModalApp from '../../componentes/Modal';
import PreLoader from '../../componentes/PreLoader';

// MÓDULO DE LOGIN
const LogIn = (props) => {

    // VARIÁVEIS DE CADASTRO
    const [email, setEmail] = useState ('')
    const [senha, setSenha] = useState ('')

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:""
    })

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    const [loading, setLoading] = useState(false);

    var titulo = ""
    var corpo = ""

    // FUNÇÕES QUE ATRIBUEM AÇÕES A ITENS NO HTML APÓS A PÁGINA SER CARREGADA MAS APENAS UMA VEZ
    useEffect(() => {
    },[])

    // ADICIONA AO BOTÃO DO FORMULÁRIO A FUNÇÃO DE LOGIN NO FIREBASE
    const submitLogIn = (evento) => {
        evento.preventDefault();
        setLoading(true);
        signInWithEmailAndPassword(auth, email, senha)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            //setLoading(false) não é necessário, pois ele sumirá quando a próxima página carregar normalmente

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            titulo = "Erro de autenticação!"
            corpo = 
                <div className="">
                    <p>Ocorreu um erro durante sua autenticação.</p>
                    <p>Verifique seu e-mail e senha e tente novamente.</p>
                </div>
            setLoading(false);
            callModal("");
            setEmail('');
            setSenha('');
        });
    }

    // ADICIONA AO RESETAR SENHA A FUNÇÃO DE ENVIO DE RESET SENHA NO FIREBASE
    const submitResetSenha = (evento) => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            titulo = "E-mail enviado!"
            corpo = 
                <div className="">
                    <p>Um e-mail para redefinição da sua senha foi enviado.</p>
                    <p>Verifique sua caixa de e-mail e siga as intruções encaminhadas.</p>
                </div>

            callModal("");
            setEmail('');
            setSenha('')

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            titulo = "Erro no processo."
            corpo = 
                <div className="">
                    <p>{errorMessage}</p>
                </div>

            callModal("");
            setEmail('');
            setSenha('')

        });
    }

    return(
            <div className="telaLogin">
                {loading ? (<PreLoader/>) : (
                    <section className="container-md secaoLogin">
                        <ModalApp tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
                        <div className="row login">
                            <div className ="col-12 col-md-6 login-box">
                                <div>
                                    <h1 className="titulo-login">Bem vindo(a)!</h1>
                                </div>
                                <form data-form="LogIn">
                                    <div className="row mb-3">
                                        <CampoForm
                                            valor = {email}
                                            aoAlterado = {valor => setEmail(valor)} 
                                            nomeCampo= "E-mail *" 
                                            campoId="cadastro-email" 
                                            tipo="email" 
                                            dataCampo="email" 
                                            placeholder="exemplo@exemplo.com" 
                                            dataMask="" 
                                            required= {true} 
                                            largura= "12" 
                                        />
                                        <div id="emailHelp" className="form-text">
                                            Não possui cadastro? 
                                            <LinkContainer to ="/cadastro">
                                                <span className="esqueceu-senha-clique"> Cadastre-se Aqui!</span>
                                            </LinkContainer>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <CampoForm
                                            valor = {senha}
                                            aoAlterado = {valor => setSenha(valor)} 
                                            nomeCampo= "Senha *" 
                                            campoId="cadastro-senha" 
                                            tipo="password" 
                                            dataCampo="senha"
                                            placeholder="Senha" 
                                            dataMask="" 
                                            required= {true} 
                                            largura="12"
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-login" data-campo="submitForm" onClick={submitLogIn}>Entrar</button>
                                    <div className="login-esqueceu-senha">
                                        <p>Esqueceu sua senha? <span onClick={submitResetSenha} className="esqueceu-senha-clique">Clique Aqui!</span></p>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-md-6 login-box">
                                <img src="imagens/login.jpg" className="img-fluid hide-cell" alt="produto 1" />
                            </div>
                        </div>
                    </section>
                )}
            </div>
    )

}

export default LogIn