import CanvasJSReact from "../../canvasjs/canvasjs.react";
import './IndiceDetalhe.css';
import {convertTextoToData, convertDataToText } from "../../paginas/Admin/convertData";
import { useEffect, useState, useRef} from "react";
import GraficoLimpo from "../GraficoLimpo";
import GraficoBranco from "../../animacoes/GraficoBranco.json"
import ModalApp from "../Modal";
import desvioPadrao from "../Carteira/desvioPadrao";
import Lottie from "lottie-react";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const IndiceDetalhe = (props) => {

    // VARIÁVEL PARA CÁLCULO DA TIR
    const { irr } = require('node-irr')

    // VARIÁVEL COM A FONTE PARA IMPRESSÃO DOS GRÁFICOS
    const fonte = "sans-serif"

    // VARIÁVEL BASE DE DADOS
    var baseDados = JSON.parse(JSON.stringify(props.baseIndices));

    // VARIÁVEL DE DADOS PARA IMRESSÃO DOS GRÁFICOS
    var dadosCurva = []
    var dadosMesAMes = []
    var dadosVol = []

    // VARIÁVEIS DE CORPO DO MODAL
    var titulo = "Gráfico do índice no tempo"
    var corpo = "Aqui estará o gráfico"

    // VARIÁVEIS DE MODAL
    const [modalObj, setModalObj] = useState({
        show:false,
        titulo:"",
        corpo:"",
        tipo:""
    })

    // FUNÇÃO PARA CHAMADA DE MODAL
    function callModal(props){
        setModalObj((prev)=>({
            ...prev,
            show:true,
            titulo:titulo,
            corpo:corpo,
            tipo:props
        }))
    }

    // FUNÇÃO DE OCULTAR E EXIBIR MODAL
    function exibirOcultarModal(statusModal){
        setModalObj((prev)=>({
            ...prev,
            show:statusModal
        }))
    }

    // VARIÁVEL ÍNDICE
    var indice = props.indice

    // VARIÁVEIS DE LAYOUT
    if(indice === "CDI"){
        var border = "1px solid rgba(255,255,0,.4)"
        var background = "linear-gradient(to top right, rgba(255,255,0,.1) , rgba(255,255,0,.0) )";
        var backgroundDark = "linear-gradient(to top right, rgba(218,165,32,.5) , rgba(218,165,32,.7) )";
        var cor = "rgb(204, 204, 0)"
        var corClara= "rgba(204,204,0,.4)"
        var campo = "rendimentoCDI"
    }else if (indice === "BOVA"){
        var border = "1px solid rgba(255,127,80,.4)"
        var background = "linear-gradient(to top right, rgba(255,127,80,.1) , rgba(255,127,80,.0) )";
        var backgroundDark = "linear-gradient(to top right, rgba(255,127,80,.5) , rgba(255,127,80,.7) )";
        var cor = "rgb(212, 149, 51)"
        var corClara = "rgb(212, 149, 51,0.4)"
        var campo = "rendimentoBOVA"
    }else if (indice === "IPCA"){
        var border = "1px solid rgba(65,105,225,.4)"
        var background = "linear-gradient(to top right, rgba(65,105,225,.1) , rgba(65,105,225,.0) )";
        var backgroundDark = "linear-gradient(to top right, rgba(65,105,225,.5) , rgba(65,105,225,.7) )";
        var cor = "rgb(124, 172, 227)"
        var corClara = "rgba(124,171,227,.4)"
        var campo = "rendimentoIPCA"
    }else{
        var border = "1px solid rgba(0,139,139,.4)"
        var background = "linear-gradient(to top right, rgba(0,139,139,.1) , rgba(0,139,139,.0) )";
        var backgroundDark = "linear-gradient(to top right, rgba(0,139,139,.5) , rgba(0,139,139,.7) )";
        var cor = "rgb(0, 139, 139)"
        var corClara = "rgba(0,139,139,.4)"
    }
    
    // VARIÁVEIS DE IMPRESSÃO
    var acumuladoText = 0
    var mensalText = 0
    var volatilidadeText = 0
    var mesesPositivos = 0
    var mesesNegativos = 0
    var menorRentabilidade = 0
    var maiorRentabilidade = 0
    var mesesAcimaCDI = 0
    var mesesAbaixoCDI = 0
    var mesesAcimaBOVA = 0
    var mesesAbaixoBOVA = 0
    var rentabilidadeAnterior = 0
    var cota = 0
    var cotaInicial = 1
    var cotaCarteiraAnterior = 1
    var rendimentoCarteira = 0

    // RECEBE A DATABASE HISTÓRICA COMPLETA PARA TODOS OS INDICADORES
    // LOOP NA BASE
    for (let i = 0; i < baseDados.length; i++) {

        // BASEADO NO ÍNDICE
        if(indice==="Carteira"){

            // O PONTO DE PARTIDA É A REFERÊNCIA
            if(i===0){
                cota = 1
                cotaInicial = 1
                cotaCarteiraAnterior = 1

                // CAPTURA AS REFERÊNCIAS INICIAIS PARA MÁXIMO, MÍNIMO E CURVA DE RENTABILIDADE
                menorRentabilidade = 100000
                maiorRentabilidade = -100000

                // POPULA OS DADOS PRO GRÁFICO DE LINHA
                dadosCurva.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: 0
                })

                // POPULA OS DADOS PRO GRÁFICO DE BARRAS
                dadosMesAMes.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: 0
                })

                // NÃO POPULA OS DADOS PRO GRÁFICO DE VOLATILIDADE
                // dadosVol.push(0)

            }else{
                // CALCULA O RENDIMENTO ATRAVÉS DA COTA
                rendimentoCarteira = props.baseRendimentosCarteira[i-1]
                
                // CALCULA A COTA PAREMETRIZADA DE ACORDO COM INÍCIO
                cota = cotaCarteiraAnterior * (1+rendimentoCarteira)
                cotaCarteiraAnterior = cota*1

                // CAPTURA O VALOR MÁXIMO
                if(rendimentoCarteira > maiorRentabilidade){
                    maiorRentabilidade = rendimentoCarteira*1
                } 

                // CAPTURA O VALOR MÍNIMO
                if(rendimentoCarteira < menorRentabilidade) {
                    menorRentabilidade = rendimentoCarteira*1
                }

                // POPULA OS DADOS PRO GRÁFICO DE LINHA
                dadosCurva.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: (cota-1)*100
                })

                // POPULA OS DADOS PRO GRÁFICO DE BARRAS
                dadosMesAMes.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: (rendimentoCarteira*100)
                })

                // POPULA OS DADOS PRO GRÁFICO DE VOLATILIDADE
                dadosVol.push(rendimentoCarteira)

                // VERIFICA SE O MÊS É ABAIXO DE 0 OU NÃO
                if(rendimentoCarteira<0){
                    mesesNegativos = mesesNegativos + 1
                }else{
                    mesesPositivos = mesesPositivos + 1
                }

                // VERIFICA SE O MÊS É MENOR QUE O CDI OU NÃO
                if(rendimentoCarteira<baseDados[i].rendimentoCDI){
                    mesesAbaixoCDI = mesesAbaixoCDI + 1
                }else{
                    mesesAcimaCDI = mesesAcimaCDI + 1
                }

                // VERIFICA SE O MÊS É MENOR QUE BOVA OU NÃO
                if(rendimentoCarteira<baseDados[i].rendimentoBOVA){
                    mesesAbaixoBOVA = mesesAbaixoBOVA + 1
                }else{
                    mesesAcimaBOVA = mesesAcimaBOVA + 1
                }

            }

            // DETERMINA A RENTABILIDADE ACUMULADA
            acumuladoText = props.dadosResumo.acumuladoInicio
            mensalText = props.dadosResumo.mediaMensal

        }else{
    
            // PONTO DE PARTIDA É A REFERÊNCIA
            if(i===0){
                // CAPTURA AS REFERÊNCIAS INICIAIS PARA MÁXIMO, MÍNIMO E CURVA DE RENTABILIDADE
                // CAPTURA AS REFERÊNCIAS INICIAIS PARA MÁXIMO, MÍNIMO E CURVA DE RENTABILIDADE
                menorRentabilidade = 100000
                maiorRentabilidade = -100000
                rentabilidadeAnterior = 1

                // POPULA OS DADOS PRO GRÁFICO DE LINHA
                dadosCurva.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: 0
                })

                // POPULA OS DADOS PRO GRÁFICO DE BARRAS
                dadosMesAMes.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: 0
                })

                // NÃO POPULA O ARRAY PARA CÁLCULO DA VOLATILIDADE
                //dadosVol.push(0)

            }else{

                // CAPTURA O VALOR MÁXIMO
                if(baseDados[i][campo] > maiorRentabilidade){
                    maiorRentabilidade = baseDados[i][campo]*1
                } 

                // CAPTURA O VALOR MÍNIMO
                if(baseDados[i][campo] < menorRentabilidade) {
                    menorRentabilidade = baseDados[i][campo]*1
                }

                // CALCULA O VALOR DA COTA PARA O GRÁFICO DE LINHA
                cota = rentabilidadeAnterior * (1+baseDados[i][campo])
                rentabilidadeAnterior = cota

                // POPULA OS DADOS PRO GRÁFICO DE LINHA
                dadosCurva.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: (cota-1)*100
                })

                // POPULA OS DADOS PRO GRÁFICO DE BARRAS
                dadosMesAMes.push({
                    x: new Date(baseDados[i]["dataResultado"]),
                    y: (baseDados[i][campo]*100)
                })

                // POPULA O ARRAY PARA CÁLCULO DA VOLATILIDADE
                dadosVol.push(baseDados[i][campo])

                // VERIFICA SE O MÊS É ABAIXO DE 0 OU NÃO
                if(baseDados[i][campo]<0){
                    mesesNegativos = mesesNegativos + 1
                }else{
                    mesesPositivos = mesesPositivos + 1
                }

                // VERIFICA SE O MÊS É MENOR QUE O CDI OU NÃO
                if(baseDados[i][campo]<baseDados[i].rendimentoCDI){
                    mesesAbaixoCDI = mesesAbaixoCDI + 1
                }else{
                    mesesAcimaCDI = mesesAcimaCDI + 1
                }

                // VERIFICA SE O MÊS É MENOR QUE BOVA OU NÃO
                if(baseDados[i][campo]<baseDados[i].rendimentoBOVA){
                    mesesAbaixoBOVA = mesesAbaixoBOVA + 1
                }else{
                    mesesAcimaBOVA = mesesAcimaBOVA + 1
                }

            }

            // DETERMINA A RENTABILIDADE ACUMULADA
            acumuladoText = ((cota - 1)*100).toFixed(0)
            mensalText = ((cota**(1/i)-1)*100).toFixed(1)
        }

    }

    // AJUSTA DE TEXTO
    menorRentabilidade = (menorRentabilidade*100).toFixed(1)
    maiorRentabilidade = (maiorRentabilidade*100).toFixed(1)

    // CALCULA A VOLATILIDADE DA SÉRIE HISTÓRICA
    if(indice === "Carteira"){
        volatilidadeText = props.dadosResumo.volatilidade
    }else{
        volatilidadeText = (desvioPadrao(dadosVol)*100).toFixed(1)
    }

    // VARIÁVEIS DE CONSTRUÇÃO DO GRÁFICO
    var grafOptions = {
        height:300,
		animationEnabled: true,
		backgroundColor: "transparent",
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign:"top",
            fontFamily: fonte,
            fontWeight: "normal"
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
                    content += "<tr/>"; 
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[0].dataSeries.color + "'>" + e.entries[0].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'>" + RSLocale.format(parseFloat(e.entries[0].dataPoint.y).toFixed(2)) + "%</span></th>";
					content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:" + e.entries[1].dataSeries.color + "'>" + e.entries[1].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'>" + RSLocale.format(parseFloat(e.entries[1].dataPoint.y).toFixed(2)) + "%</span></th>";
					content += "<tr/>";
                    content += "</table>"
				return content;
            }
            
        },
		title:{
			text: "",
			padding:0, //20
			fontSize:0, //20
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
        axisY2:{
			gridThickness: 1,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
        },
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
			labelFontColor: "rgba(150,150,150,1.0)",
			lineColor: "rgba(150,150,150,1.0)",
			lintThickness: 0.5,
            intervalType: "month",
            labelFontFamily: fonte,
            labelFontSize:10,
            valueFormatString:"MMM YYYY"
		},
		data: [{
			type: "column",
            axisYIndex: 1,
            name:"Período",
			showInLegend: true,
			legendText: indice,
			color: corClara,
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			dataPoints: dadosMesAMes
		},{
			type: "line",
            axisYIndex: 2,
            name: "Evolução",
			showInLegend: true,
			legendText: "Evolução",
			color: cor,
			markerSize: 5,
			xValueFormatString: "MM/YYYY",
			dataPoints: dadosCurva
		}]
    }

    // FUNÇÃO DE IMPRESSÃO DO GRÁFICO DE DETALHE
    function criaGrafico(options){
        return(
            <div style={{height:300+"px"}}>
                <GraficoLimpo id="indiceGrafico" options={options}/>
            </div>
        )
    }

    function graficoDetalhe(){
        titulo = "Índice " + indice + " no tempo"
        corpo  = criaGrafico(grafOptions)
        callModal()
    }

    // JSX
    return(
        <div className="col-12 col-sm-6 col-lg-6">
            <ModalApp tipo ={modalObj.tipo} show={modalObj.show} change={exibirOcultarModal} titulo={modalObj.titulo} corpo ={modalObj.corpo}/>
            <div className="indiceBox box-shadow" style={{ backgroundImage: background, border: border}}>
                <div className="tituloIndiceBox" style={{color:"white", backgroundImage: backgroundDark}}>
                    <div className="indexTitulo">{indice}</div>
                    <div className="indexGrafico" onClick={graficoDetalhe}><Lottie animationData={GraficoBranco}/></div>
                </div>
                <div className="corpoIndiceBox">
                    <div className="topInfos">
                        <div className="topInfo">
                            <div className="topInfoTitulo">Acumulado</div>
                            <div className="topInfoValor" style={{color:cor}}>{acumuladoText}%</div>
                        </div>
                        <div className="topInfo">
                            <div className="topInfoTitulo">Mensal</div>
                            <div className="topInfoValor" style={{color:cor}}>{mensalText}%</div>
                        </div>
                        <div className="topInfo ultimaLinha">
                            <div className="topInfoTitulo">Volatilidade</div>
                            <div className="topInfoValor" style={{color:cor}}>{volatilidadeText}%</div>
                        </div>
                    </div>
                    <div className="infos" style={{ border: border}}>
                        <div className="infoLine">
                            <div className="infoLineDesc">N° meses positivos / negativos:</div>
                            <div className="infoLineValor" style={{color:cor}}>{mesesPositivos} / {mesesNegativos}</div>
                        </div>
                        <div className="infoLine">
                            <div className="infoLineDesc">Maior / menor rentabilidade mensal:</div>
                            <div className="infoLineValor" style={{color:cor}}>{maiorRentabilidade}% / {menorRentabilidade}%</div>
                        </div>
                        <div className="infoLine">
                            <div className="infoLineDesc">N° meses acima / abaixo do CDI</div>
                            <div className="infoLineValor" style={{color:cor}}>{mesesAcimaCDI} / {mesesAbaixoCDI}</div>
                        </div>
                        <div className="infoLine ultimaLinha">
                            <div className="infoLineDesc">N° meses acima / abaixo de BOVA</div>
                            <div className="infoLineValor" style={{color:cor}}>{mesesAcimaBOVA} / {mesesAbaixoBOVA}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndiceDetalhe