import { useEffect, useState } from "react"
import styles from "./ResumoMesAnterior.module.css"
import GraficoLimpo from "../GraficoLimpo"

// MÓDULO DE GESTÃO DE RISCO
const ResumoMesAnterior = (props) => {

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"
    let RSLocale = Intl.NumberFormat('pt-BR');  

    // VARIÁVEL DE IMPRESSÃO
    var dadosResumoMes = []

    // TRABALHA A DATA TRAZIDA
    var data = new Date(props.data)
    var dataTexto = data.getMonth() + "/" + data.getFullYear()

    // CALCULA A DIFERENÇA ENTRE OS 2 PARÂMETROS
    if(props.dadosConciliacaoPenultimo == []){
        

    }else{

        // LOOP NO ÚLTIMO DADO
        for(let i=0; i < props.dadosConciliacaoUltimo.length; i++){
            // CAPTURA O PATRIMÔNIO FINAL DO MÊS ANTERIOR
           /* if(props.dadosConciliacaoUltimo[i]["label"] == "Saldo XP"){
                dadosResumoMes[0] = {
                    label: "Patrimônio Inicial",
                    nome: "Patrimônio Inicial",
                    y: 0
                }
            }*/

            // CAPTURA O DELTA DOS PRÊMIOS
            if(props.dadosConciliacaoUltimo[i]["label"] == "Prêmios"){
                var deltaPremios = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[0] = {
                    label: "Prêmios",
                    nome: "Prêmios",
                    y: deltaPremios
                }
            }

            // CAPTURA O DELTA DA PROVISÃO
            if(props.dadosConciliacaoUltimo[i]["label"] == "Operações com Opções"){
                var deltaProvisao = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[1] = {
                    label: "Provisão",
                    nome: "Provisão",
                    y: deltaProvisao
                }
            }

            // CAPTURA O DELTA DA FLUTUAÇÃO DE PATRIMÔNIO
            if(props.dadosConciliacaoUltimo[i]["label"] == "Flutuação Carteira"){
                var deltaFlutuacao = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[2] = {
                    label: "Flutuação Carteira",
                    nome: "Flutuação Carteira",
                    y: deltaFlutuacao
                }
            }

            // SOMA INTERMEDIÁRIA
            dadosResumoMes[3] = {label: "Resultado", nome:"Resultado", isCumulativeSum: true, color: "rgba(77,164,240,0.5)" }

            // CAPTURA O DELTA DE COMISSÕES
            if(props.dadosConciliacaoUltimo[i]["label"] == "Comissões"){
                var deltaComissoes = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[4] = {
                    label: "Retirada de Comissão",
                    nome: "Retirada de Comissão",
                    y: deltaComissoes
                }
            }

            // CAPTURA O DELTA DE TAXAS E MULTAS
            if(props.dadosConciliacaoUltimo[i]["label"] == "Taxas e Multas"){
                var deltaComissoes = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[5] = {
                    label: "Taxas",
                    nome: "Taxas",
                    y: deltaComissoes
                }
            }

            // CAPTURA O DELTA DE ENTRADAS E SAÍDAS
            if(props.dadosConciliacaoUltimo[i]["label"] == "Entradas Líquidas"){
                var deltaEntradasESaidas = props.dadosConciliacaoUltimo[i]["y"] - props.dadosConciliacaoPenultimo[i]["y"]

                dadosResumoMes[6] = {
                    label: "Entradas Líquidas",
                    nome: "Entradas Líquidas",
                    y: deltaEntradasESaidas
                }
            }

            // SOMA FINAL
            dadosResumoMes[7] = {label: "Patrimônio", nome:"Patrimônio", isCumulativeSum: true, color: "rgba(0,139,139,0.7)" }

        }

    }

    // OPÇÃO DE IMPRESSÃO
    const optionsResultadoDoMes = {
        animationEnabled: true,
        height:290,
        backgroundColor: "transparent",
        legend:{
            fontColor: "rgb(150,150,150)",
            fontFamily: fonte,
        },
        title:{
            text: "Variações em " + dataTexto,
            padding:20,
            fontSize:15,
            fontColor: "rgba(102,205,170,1.0)",
            fontFamily: fonte
        },
        toolTip:{
            fontFamily: fonte,
            contentFormatter: function (e) {
                let RSLocale = Intl.NumberFormat('pt-BR');
                var content = "<table/>"  ;              
				content += "<th style='padding-right:10px'><span style='color: gray '>" + e.entries[0].dataPoint.label + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[0].dataPoint.y)) + "</span></th>";
				content += "<tr/>";
                content += "</table>"
				return content;
            }
        },
        axisY: {
            title: "",
            gridThickness: 0,
            labelFontSize:0.0000000000001,
            lineThickness:0,
            tickThickness: 0,
            labelFontFamily: fonte,
            stripLines:[
                {
                    value:0,
                    lineDashType: "dot",
                    opacity: 1,
                    thickness:1,                
                    color:"red",
                }
                ]
        },
        axisX:{
            gridThickness: 0,
            margin: 10,
            tickThickness: 0,
            labelFontColor: "rgba(150,150,150,1.0)",
            lineColor: "rgba(150,150,150,0.7)",
            lineThickness: 1,
            labelFontSize:10,
            labelMaxWidth: 80,  
            labelWrap: true,
            interval:1,
            labelFontFamily: fonte,
			stripLines:[
                {                
                    startValue:2.5,
                    endValue:3.5,
                    color:"rgba(200,200,200,0.3)"
                },
                {                
                    startValue:6.5,
                    endValue:7.5,
                    color:"rgba(200,200,200,0.3)"
                }, 
                ]
        },
        data: [{
            //click: ConciliacaoChartDrilldownHandler,
            //cursor: "pointer",
            type: "waterfall",
            color: "rgba(200,200,200,0,7)",
            risingColor: "rgba(102,205,170,.7)",
            fallingColor: "rgba(255,127,80,.4)",
            lineThickness:1,
            lineColor:"rgba(200,200,200,0)",
            indexLabel: "{y}",
            indexLabelFontSize: 8,
            indexLabelPlacement: "outside",
            indexLabelFontColor: "rgb(150,150,150)",
            indexLabelFontFamily: fonte,
            dataPoints: dadosResumoMes,
            indexLabelFormatter:function (e) {               
                    var content = "R$" + RSLocale.format(parseInt(e.dataPoint.y));
                    return content;
                }            
        }]
    }

    return(
        <div className={`${styles.resumoRepository} row`}>
            <div className={`${styles.indexes} col-sm-12 col-md-4 col-lg-4`}>
                <div className={`${styles.box} ${styles.indexesAcoes} box-shadow`}>
                    <div className={`${styles.acoes}`}>
                        <div className={`${styles.linhaAcaoTitulo}`}>
                            <div className={`${styles.linhaAcaoBox}`}>Ticker</div>
                            <div className={`${styles.linhaAcaoBox} `}>Variação {dataTexto}</div>
                            <div className={`${styles.linhaAcaoBox} ${styles.linhaAcaoPercentualTitulo}`}>%</div>
                        </div>
                        {props.dadosAcoes ?
                            <div className={`${styles.listaAcoes} `}>
                                {props.dadosAcoes.map((acao) => {
                                    // VARIAÇÃO DO INÍCIO
                                    var variacaoInicio = (acao.pa - acao.panterior)*acao.unidadesMesAnterior
                                    
                                    // VARIAÇÃO DAS AÇÕES COMPRADAS
                                    var variacaoCompradas = (acao.pa - acao.pmcompra)*acao.unidadesCompradas

                                    // VARIAÇÃO DAS AÇÕES VENDIDAS
                                    var variacaoVendidas = -1*(acao.pa - acao.pmvenda)*acao.unidadesVendidas
                                    
                                    // RESUMO
                                    var variacao = variacaoInicio + variacaoCompradas + variacaoVendidas
                                    var pmentrada = ((acao.panterior*acao.unidadesMesAnterior)+(acao.pmcompra*acao.unidadesCompradas))/(acao.unidadesCompradas+acao.unidadesMesAnterior)
                                    var pmsaida = ((acao.pa*acao.unidades)+(acao.pmvenda*acao.unidadesVendidas))/(acao.unidades+acao.unidadesVendidas)
                                    var variacaoPercentual = (pmsaida-pmentrada)/pmentrada
                                    var classeAcao = "linhaAcaoSubida"
                                    var arrow = "\u{02191}";
                                    if(variacao < 0){ 
                                        classeAcao = "linhaAcaoQueda" 
                                        arrow = "\u{02193}";
                                    }
                                        return(
                                            <div key={acao.ticker + "linhaAcaoVariacao"} className={`${styles.linhaAcao} ${styles[classeAcao]}`}>
                                                <div className={`${styles.linhaAcaoBox}`}>{acao.ticker}</div>
                                                <div className={`${styles.linhaAcaoBox} ${styles.linhaAcaoValor}`}><strong className={`${styles.seta}`}>{arrow}</strong> {"R$ " + RSLocale.format(variacao.toFixed(0))}</div>
                                                <div className={`${styles.linhaAcaoBox} ${styles.linhaAcaoPercentual}`}>{(variacaoPercentual*100).toFixed(2) + "%"}</div>
                                            </div>
                                        )
                                })}  
                            </div> 
                        :
                            (<div></div>)
                        }
                    </div>
                </div>
            </div>
            <div className={`${styles.bridge} col-sm-12 col-md-8 col-lg-8`}>
                <div className={`${styles.bridgebox} ${styles.box} box-shadow`}>
                    <GraficoLimpo id="graf-resumo-mes" options={optionsResultadoDoMes}/>
                </div>
            </div>
        </div>

    )
}

export default ResumoMesAnterior